import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OutputData } from '@editorjs/editorjs';
import edjsHTML from 'editorjs-html';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';

@Component({
  selector: 'app-mobile-abonent-services-detail-preview',
  standalone: true,
  imports: [MobilePreviewComponent, NgIf, CommonModule],
  templateUrl: './mobile-abonent-services-detail-preview.component.html',
  styleUrl: './mobile-abonent-services-detail-preview.component.css',
})
export class MobileAbonentServicesDetailPreviewComponent implements OnChanges {
  @Input() public title: string = '';
  @Input() public image?: Blob | null;
  @Input() public description?: OutputData | null;
  descTemplate!: string;
  imageBase64: string = '';

  processImageToBase64(imageBlob: Blob) {
    if (this.image) {
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onloadend = () => {
        this.imageBase64 = reader.result as string;
      };
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['description']) {
      if (this.description) {
        const edjsParser = edjsHTML();
        this.descTemplate = edjsParser.parse(this.description).join('');
      }
    }

    if (changes['image']) {
      if (this.image) {
        this.processImageToBase64(this.image);
      }
    }
  }
}
