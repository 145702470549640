import { Component, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { ServiceExtension, ServiceExtTypeEnum } from '../../../domain/entities/service-extension';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AddButtonComponent } from '../../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../../components/common/buttons/btn-list/btn-list.component';
import { RouterLink } from '@angular/router';
import { LoadableWrapperComponent } from '../../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../../components/common/spinner-img/spinner-img.component';
import { PaginationResponse } from '../../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationViewComponent } from '../../../../../components/pagination-view/pagination-view.component';
import { ISuccessModalInitParams } from '../../../../../components/common/modal-service/modal-component/success-modal-component/isuccess-modal-init-params';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-service-ext-list',
  standalone: true,
  templateUrl: './service-ext-list-page.component.html',
  styleUrl: './service-ext-list-page.component.css',
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    AddButtonComponent,
    BtnListComponent,
    RouterLink,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    PaginationViewComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtListPageComponent implements OnInit {
  public pageServiceExtensions: Loadable<PaginationResponse<ServiceExtension>> = new Loadable<
    PaginationResponse<ServiceExtension>
  >();
  public countServiceExtensions: Loadable<PaginationResponse<ServiceExtension>> = new Loadable<
    PaginationResponse<ServiceExtension>
  >();
  pageTitle: string = 'Список расширений услуг';
  pageSubtitle: string = 'Тарифы и услуги';
  serviceExtType = ServiceExtTypeEnum;
  countDict: { [id: string]: number } = {};
  activeType: string = 'all';
  public keys = Object.keys;
  pageNumber: number = 1;
  perPage: number = 5;
  currentPage: number = 1;

  constructor(
    private serviceExtensionsRepo: ServicesExtRepository,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    const isCreated = history.state.serviceExtCreated;
    if (isCreated) {
      const succesInitParams = new ISuccessModalInitParams(
        'Расширение услуги создано',
        () => {},
        () => {},
        'Успешно',
      );
      this.modalService.createSuccessModal(succesInitParams);
    }
    const isUpdated = history.state.serviceExtUpdated;
    if (isUpdated) {
      const succesInitParams = new ISuccessModalInitParams(
        'Расширение услуги обновлено',
        () => {},
        () => {},
        'Успешно',
      );
      this.modalService.createSuccessModal(succesInitParams);
    }
    this.getAllServiceExtensions(this.perPage, this.pageNumber).finally();
    this.getServiceExtCountByType().finally();
  }

  async getAllServiceExtensions(
    perPage: number,
    pageNumber: number,
    activeType?: string,
  ): Promise<void> {
    if (this.pageServiceExtensions.status != UIStateType.Loading) {
      let body: { [id: string]: any } = {
        'filters': [
          {
            'column': 'deleted_at',
            'operator': 'eq',
            'value': null,
          },
        ],
      };

      if (!activeType) {
        await this.serviceExtensionsRepo.findAllPost(body, pageNumber, perPage).then((data) => {
          this.pageServiceExtensions = Loadable.getFromDataStatus(data);
        });
      } else {
        body['filters'].push({
          'column': 'service_type',
          'operator': 'eq',
          'value': activeType,
        });

        await this.serviceExtensionsRepo.findAllPost(body, pageNumber, perPage).then((data) => {
          this.pageServiceExtensions = Loadable.getFromDataStatus(data);
        });
      }
    }
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.currentPage = pageNumber;
    if (this.activeType && this.activeType == 'all') {
      this.getAllServiceExtensions(this.perPage, this.pageNumber).finally();
    } else {
      this.getAllServiceExtensions(this.perPage, this.pageNumber, this.activeType).finally();
    }
  }

  getServiceExtType(type: string): string {
    return ServiceExtTypeEnum[type as keyof typeof ServiceExtTypeEnum];
  }

  async getServiceExtCountByType(): Promise<void> {
    let body: { [id: string]: any } = {
      'filters': [
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
    };

    for (let type of Object.keys(this.serviceExtType)) {
      let body: { [id: string]: any } = {
        'filters': [
          {
            'column': 'deleted_at',
            'operator': 'eq',
            'value': null,
          },
          {
            'column': 'service_type',
            'operator': 'eq',
            'value': type,
          },
        ],
      };

      await this.serviceExtensionsRepo.findAllPost(body, 1, 1).then((data) => {
        this.countServiceExtensions = Loadable.getFromDataStatus(data);
      });

      this.countDict[type] = this.countServiceExtensions.data.count;
    }

    await this.serviceExtensionsRepo.findAllPost(body, 1, 1).then((data) => {
      this.countServiceExtensions = Loadable.getFromDataStatus(data);
    });

    this.countDict['all'] = this.countServiceExtensions.data.count;
  }

  async showServiceExtByType(type?: string): Promise<void> {
    if (type) {
      this.activeType = type;
    } else {
      this.activeType = 'all';
    }

    this.pageNumber = 1;
    this.currentPage = 1;

    this.getAllServiceExtensions(this.perPage, this.pageNumber, type).finally();
  }

  async deleteServiceExt(serviceId: number) {
    await this.serviceExtensionsRepo.delete(serviceId);
    this.getAllServiceExtensions(this.perPage, this.pageNumber);
  }

  async searchServiceExt($event: any) {
    let searchName = $event.target.value;

    if (searchName !== '') {
      await this.serviceExtensionsRepo
        .findAllByLikeSearchCriteria('title', searchName, 1, this.perPage)
        .then((data) => {
          this.pageServiceExtensions = Loadable.getFromDataStatus(data);
        });
    } else {
      await this.getAllServiceExtensions(this.perPage, this.pageNumber);
    }
  }
}
