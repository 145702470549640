<label class="form-label">Подзаголовок в описании</label>
<input
  formControlName="title"
  class="mb-3 form-control"
  [value]="title"
  (input)="changeAddFullDescTitle($event)"
  placeholder="Введите подзаголовок в описании"
/>
<label class="form-label">Дополнительный пункт описания</label>
<div class="card mb-3">
  <div class="card-body">
    <div [id]="editorId" (input)="changeAddFullDescText()"></div>
  </div>
</div>
