import { Injectable } from '@angular/core';
import { Attachment, AttachmentContentType } from '../../domain/attachment';
import { AttachmentApiDto } from '../attachment-api-dto';
import { AttachmentContentMapper } from './attachment-contnent-mapper';

@Injectable({ providedIn: 'root' })
export class AttachmentMapper {
  constructor(private attachmentContentMapper: AttachmentContentMapper) {}
  public toDomain(value: AttachmentApiDto): Attachment {
    let type = AttachmentContentType.fromString(value.content.type);
    return new Attachment(
      value.id,
      value.name,
      value.media_type,
      value.format,
      this.attachmentContentMapper.toDomain(value.content),
    );
  }
}
