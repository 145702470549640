import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { BehaviorSubject } from 'rxjs';
import { Task } from '../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { OrionOfficeBusinessHoursRepository } from '../../data/repositories/orion-office-business-hours-repository';
import { OrionOfficeRepository } from '../../data/repositories/orion-office-repository';
import { OrionOfficesContactsRepository } from '../../data/repositories/orion-offices-contacts-repository';
import { OrionOffice } from '../../domain/orion-office';
import { OrionOfficeBusinessHoursCreate } from '../../domain/orion-office-business-hours-create';
import { OrionOfficeContactCreateRequest } from '../../domain/orion-office-contact-create-request';
import { OrionOfficeCreateRequest } from '../../domain/orion-office-create-request';
import { OrionBusinessHoursFormValue } from '../components/orion-offices-form/components/orion-business-hours-field/orion-business-hours-field.component';
import { OrionOfficeContactFormValue } from '../components/orion-offices-form/components/orion-office-contacts-field/orion-office-contacts-field.component';
import {
  OrionOfficesCreateForm,
  OrionOfficesFormComponent,
  OrionOfficesFormDelegate,
} from '../components/orion-offices-form/orion-offices-form.component';

@Component({
  selector: 'app-orion-offices-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    ReactiveFormsModule,
    RouterModule,
    AngularYandexMapsModule,
    OrionOfficesFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './orion-offices-update.component.html',
  styleUrl: './orion-offices-update.component.css',
})
export class OrionOfficesUpdateComponent implements OnInit, OrionOfficesFormDelegate {
  pageTitle: string = 'Редактирование офиса';
  pageSubtitle: string = 'Офисы';

  updateStatus: BehaviorSubject<Loadable<OrionOffice>> = new BehaviorSubject(
    Loadable.notRequested(),
  );
  officeId!: number;
  editableOffice: Loadable<OrionOffice> = Loadable.notRequested();

  constructor(
    private activeRoute: ActivatedRoute,
    private orionOfficeRepo: OrionOfficeRepository,
    private officeContactsRepo: OrionOfficesContactsRepository,
    private officeBusinessHoursRepo: OrionOfficeBusinessHoursRepository,
    public router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.officeId = Number(this.activeRoute.snapshot.paramMap.get('id'));

    this.updateStatus.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          new Task(async () => {
            await this.router.navigate(['/orion-offices']);
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: 'Офис успешно изменен.',
            });
          });
          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
      }
    });
  }

  didCreateForm(form: FormGroup<OrionOfficesCreateForm>): void {
    new Task(async () => {
      let office = await this.orionOfficeRepo.getOfficeById(this.officeId);
      this.editableOffice = Loadable.getFromDataStatus(office);

      if (this.editableOffice.status == UIStateType.Success) {
        form.patchValue({
          title: this.editableOffice.data.title,
          address: this.editableOffice.data.address,
          longitude: this.editableOffice.data.longitude,
          latitude: this.editableOffice.data.latitude,
          city: this.editableOffice.data.city,
          contacts: this.editableOffice.data.contacts.map((t) => {
            return new OrionOfficeContactFormValue(t.phone);
          }),
          businessDays: this.editableOffice.data.businessDays.map((t) => {
            return new OrionBusinessHoursFormValue(
              t.id,
              t.weekday,
              t.openTime ?? '',
              t.closeTime ?? '',
              t.isDayOff,
            );
          }),
        });
      }
    });
  }

  didSubmitForm(form: FormGroup<OrionOfficesCreateForm>): void {
    if (form.valid && this.updateStatus.value.status != UIStateType.Loading) {
      new Task(async () => {
        this.updateStatus.next(Loadable.loading());

        let request = this.bindFormValue(form.getRawValue());

        let response = Loadable.getFromDataStatus(
          await this.orionOfficeRepo.update(request, this.officeId),
        );

        if (response.status == UIStateType.Success) {
          let promises: Promise<any>[] = [];

          form.getRawValue().businessDays.forEach((t) => {
            let hoursRequest = new OrionOfficeBusinessHoursCreate(
              response.data.id!,
              t.weekDay,
              t.isDayOff ? null : t.openTime,
              t.isDayOff ? null : t.closeTime,
              t.isDayOff,
            );

            if (t.id) {
              promises.push(this.officeBusinessHoursRepo.update(hoursRequest, t.id));
            }
          });

          this.editableOffice.data.contacts.forEach((t) => {
            promises.push(this.officeContactsRepo.delete(t.id));
          });

          form.getRawValue().contacts.forEach((t) => {
            let contactRequest = new OrionOfficeContactCreateRequest(response.data.id!, t.phone);

            promises.push(this.officeContactsRepo.create(contactRequest));
          });

          await Promise.all(promises);
        }

        this.updateStatus.next(response);
      });
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля',
      });
    }
  }

  private bindFormValue(
    value: ɵTypedOrUntyped<OrionOfficesCreateForm, ɵFormGroupRawValue<OrionOfficesCreateForm>, any>,
  ): OrionOfficeCreateRequest {
    return new OrionOfficeCreateRequest(
      value.title,
      value.address,
      value.latitude!,
      value.longitude!,
      value.city,
    );
  }
}
