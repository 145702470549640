import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { NgIf } from '@angular/common';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';

@Component({
  selector: 'app-service-ext-smotreshka-packet-form-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    FormComponentComponent,
  ],
  templateUrl: './service-ext-smotreshka-packet-form-component.component.html',
  styleUrl: './service-ext-smotreshka-packet-form-component.component.css',
})
export class ServiceExtSmotreshkaPacketFormComponentComponent {
  iconChangedEvent: Event | null = null;
  croppedIcon?: Blob | null;
  iconPreview!: string;

  @Input() form!: FormGroup;

  @Output() title: EventEmitter<string> = new EventEmitter();
  @Output() desc: EventEmitter<string> = new EventEmitter();
  @Output() outputCroppedIcon: EventEmitter<Blob> = new EventEmitter();
  @Output() outputIconPreview: EventEmitter<string> = new EventEmitter();

  onTitleChanged($event: any) {
    let title = $event.target.value;
    this.title.emit(title);
  }

  onDescChanged($event: any) {
    let desc = $event.target.value;
    this.desc.emit(desc);
  }

  iconFileChangeEvent($event: any): void {
    this.iconChangedEvent = $event;
  }

  iconCropped(event: ImageCroppedEvent): void {
    this.croppedIcon = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.iconPreview = e.target.result;
      this.outputIconPreview.emit(this.iconPreview);
    };

    if (this.croppedIcon) {
      reader.readAsDataURL(this.croppedIcon);
      this.outputCroppedIcon.emit(this.croppedIcon);
    }
  }

  removeIcon(): void {
    this.iconChangedEvent = null;
  }
}
