import { Injectable } from '@angular/core';
import { TariffExt } from '../../domain/tariff-ext';
import { TariffExtDto } from '../dto/tariff-ext-dto';

@Injectable({ providedIn: 'root' })
export class TariffExtMapper {
  toDomain(dto: TariffExtDto): TariffExt {
    return new TariffExt(dto.id, dto.tariff_id, dto.name, dto.description);
  }
}
