import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Task } from '../../../../../core/utils/task';
import { Loadable } from '../../../../../core/utils/wrappers/loadable';
import { ActionButtonsRepository } from '../../../../../entity/action-button/data/repo/action-buttons-repository';
import { ActionButton } from '../../../../../entity/action-button/domain/action-button';
import { ActionButtonCreateRequest } from '../../../../../entity/action-button/domain/action-button-create-request';
import { AppLinkRepository } from '../../../../../entity/app-link/data/repo/app-link-repository';
import { AppLinkCreate } from '../../../../../entity/app-link/domain/app-link-create';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { AttachmentType } from '../../../../../entity/attachments/domain/attachment';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  BannerForm,
  BannerFormComponent,
  BannerFormDelegate,
} from '../../components/forms/banner-form/banner-form';
import { BannerRepository } from '../../data/repositories/banner-repository';
import { Banner } from '../../domain/banner';
import { BannerCreateRequest } from '../../domain/banner-create-request';

@Component({
  selector: 'app-update-banner',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    ReactiveFormsModule,
    BannerFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners-update.component.html',
  styleUrl: './banners-update.component.css',
})
export class BannerUpdateComponent implements OnInit, BannerFormDelegate {
  private bannerId!: number;
  private oldBanner: Banner | null = null;

  public bannersUpdateState: BehaviorSubject<Loadable<Banner>> = new BehaviorSubject<
    Loadable<Banner>
  >(Loadable.notRequested());
  pageTitle: string = 'Редактирование баннера';
  pageSubtitle: string = 'Баннеры';

  constructor(
    private bannersRepo: BannerRepository,
    private actionButtonRepository: ActionButtonsRepository,
    private appLinkRepository: AppLinkRepository,
    private attachmentRepo: AttachmentRepository,
    public router: Router,
    public activeRoute: ActivatedRoute,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.bannerId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.bannersUpdateState.subscribe();
  }

  didCreateForm(form: FormGroup<BannerForm>) {
    new Task(async () => {
      let banner = Loadable.getFromDataStatus(await this.bannersRepo.getById(this.bannerId));
      this.oldBanner = banner.safeData;

      if (banner.safeData) {
        form.patchValue({
          title: banner.safeData.title,
          description: banner.safeData.description,
          buttonTitle: banner.safeData.actionButton ? banner.safeData.actionButton.body : null,
          appLink: !banner.safeData.appLink
            ? null
            : {
                appLinkType: banner.safeData.appLink.type,
                httpLink: banner.safeData.appLink.httpLink,
                deepLink: banner.safeData.appLink.deeplink,
              },
          image: { initialUrl: banner.safeData.image?.content.attributes.contentPath },
        });
      }
    });
  }

  didSubmitForm(form: FormGroup<BannerForm>) {
    if (form.valid) {
      new Task(async () => {
        this.bannersUpdateState.next(Loadable.loading());
        try {
          let request = await this.mapToRequest(form.getRawValue());
          let response = await this.bannersRepo.create(request);

          this.bannersUpdateState.next(Loadable.getFromDataStatus(response));
        } catch (error) {
          this.bannersUpdateState.next(Loadable.notRequested());
          if (error instanceof Error) {
            this.modalService.createModal({
              type: ModalType.DANGER,
              message: error.message,
            });
          }
        }
      });
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля.',
      });
    }
  }

  async mapToRequest(
    form: ɵTypedOrUntyped<BannerForm, ɵFormGroupRawValue<BannerForm>, any>,
  ): Promise<BannerCreateRequest> {
    let updateAppLink = Loadable.getFromDataStatus(
      await this.appLinkRepository.update(
        new AppLinkCreate(
          form.appLink!.appLinkType,
          form.appLink!.deepLink,
          form.appLink!.httpLink,
        ),
        this.oldBanner!.appLink!.id,
      ),
    ).throwErrorResult();

    let createAttachment = Loadable.getFromDataStatus(
      await this.attachmentRepo.create(form.image!.croppedData!, AttachmentType.BANNER),
    ).throwErrorResult();

    let createActionButton: Loadable<ActionButton> | null = null;

    if (form.buttonTitle) {
      createActionButton = Loadable.getFromDataStatus(
        await this.actionButtonRepository.create(
          new ActionButtonCreateRequest(form.buttonTitle!, updateAppLink.data.id),
        ),
      ).throwErrorResult();
    }

    return new BannerCreateRequest(
      form.title,
      form.description,
      createAttachment.data.id,
      updateAppLink.data.id,
      createActionButton ? createActionButton.data.id : null,
    );
  }
}
