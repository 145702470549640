import { NgForOf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../components/pagination-view/pagination-view.component';

import { BannerRepository } from '../../data/repositories/banner-repository';
import { Banner } from '../../domain/banner';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    RouterModule,
    LoadableWrapperComponent,
    AddButtonComponent,
    PaginationViewComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css',
})
export class BannerComponent implements OnInit, PaginationViewDelegate {
  public activeBanners: Loadable<PaginationResponse<Banner>> = Loadable.notRequested();
  public hiddenBanners: Loadable<PaginationResponse<Banner>> = Loadable.notRequested();

  pageTitle: string = 'Список баннеров';
  pageSubtitle: string = 'Баннеры';

  public perPage: number = 1;

  public activeBannersCurrentPageNumber: number = 1;
  public hiddenBannersCurrentPageNumber: number = 1;

  constructor(private bannerRepository: BannerRepository) {}

  ngOnInit(): void {
    this.getAllBanners().finally();
  }

  async getAllActiveBanners(per_page: number, page_number: number): Promise<void> {
    if (this.activeBanners.status != UIStateType.Loading) {
      this.activeBanners = Loadable.loading();

      let response = await this.bannerRepository.findAll(per_page, page_number, true);
      this.activeBanners = Loadable.getFromDataStatus(response);
    }
  }

  async getAllHiddenBanners(per_page: number, page_number: number): Promise<void> {
    if (this.hiddenBanners.status != UIStateType.Loading) {
      this.hiddenBanners = Loadable.loading();

      let response = await this.bannerRepository.findAll(per_page, page_number, false);
      this.hiddenBanners = Loadable.getFromDataStatus(response);
    }
  }

  async getAllBanners(): Promise<void> {
    await Promise.all([
      this.getAllActiveBanners(this.perPage, this.activeBannersCurrentPageNumber),
      this.getAllHiddenBanners(this.perPage, this.hiddenBannersCurrentPageNumber),
    ]).finally();
  }

  async onDelete(id: number): Promise<void> {
    await this.bannerRepository.delete(id);
    await this.getAllBanners();
  }

  public didChangePage(newPage: number): void {
    this.activeBannersCurrentPageNumber = newPage;
    this.getAllBanners().then();
  }
}
