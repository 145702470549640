import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-preview-with-button',
  standalone: true,
  imports: [NgIf],
  templateUrl: './image-preview-with-button.component.html',
  styleUrl: './image-preview-with-button.component.css',
})
export class ImagePreviewWithButtonComponent {
  @Input() preview: string = '';
  @Input() fakeBtnText: string = '';
}
