import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { AvailableService } from '../../domain/available-service';
import { AvailableServiceCreateRequest } from '../../domain/available-service-create-request';
import { AvailableServiceApiDto } from '../dto/available-service-api-dto';
import { AvailableServiceCreateRequestMapper } from '../mappers/available-service-create-request-mapper';
import { AvailableServiceMapper } from '../mappers/available-service-mapper';

@Injectable({ providedIn: 'root' })
export class AvailableServiceRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private availableServiceMapper: AvailableServiceMapper,
    private availableServiceCreateRequestMapper: AvailableServiceCreateRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<
    AvailableServiceApiDto,
    AvailableService
  > = new PaginationResponseMapper(this.availableServiceMapper);

  async findAllPost(
    per_page: number,
    page_number: number,
    name: string | null = null,
  ): Promise<DataStatus<PaginationResponse<AvailableService>>> {
    var payload: { [key: string]: any } = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [{ 'column': 'deleted_at', 'operator': 'eq', 'value': null }],
    };

    if (name) {
      payload['filters'].push({ 'column': 'name', 'operator': 'ilike', 'value': `%${name}%` });
    }

    let response: DataStatus<PaginationResponseApiDto<AvailableServiceApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services`,
        payload,
      );

    return response.map((data) => {
      return this.paginationResponseMapper.toDomain(data);
    });
  }

  async create(request: AvailableServiceCreateRequest): Promise<DataStatus<AvailableService>> {
    let response: DataStatus<AvailableServiceApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services`,
      this.availableServiceCreateRequestMapper.toData(request),
    );
    return response.map((data) => {
      return this.availableServiceMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services/${id}`,
    );
  }

  async update(
    request: AvailableServiceCreateRequest,
    id: number,
  ): Promise<DataStatus<AvailableService>> {
    let response: DataStatus<AvailableServiceApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services/${id}`,
      this.availableServiceCreateRequestMapper.toData(request),
    );

    return response.map((data) => {
      return this.availableServiceMapper.toDomain(data);
    });
  }
}
