import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { StatsByNewUsersResponseDto } from '../data/stats-by-new-users-response-dto';
import { StatsBySentPushResponseDto } from '../data/stats-by-sent-push-response-dto';
import { StatsByNewUsersResponse } from '../domain/stats-by-new-users-response';
import { StatsBySentPushResponse } from '../domain/stats-by-sent-push-response';
import { StatsBySentSmsResponse } from '../domain/stats-by-sent-sms-response';
import { StatsByNewUsersResponseMapper } from '../mappers/stats-by-new-users-response-mapper';
import { StatsBySentPushResponseMapper } from '../mappers/stats-by-sent-push-response-mapper';
import { StatsBySentSmsResponseMapper } from '../mappers/stats-by-sent-sms-response-mapper';

@Injectable({ providedIn: 'root' })
export class StatisticsRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private newUsersStatsMapper: StatsByNewUsersResponseMapper,
    private statsBySentPushResponseMapper: StatsBySentPushResponseMapper,
    private statsBySentSmsResponseMapper: StatsBySentSmsResponseMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async getStatsByNewUsers(
    dateFrom: Date,
    dateUntil: Date,
  ): Promise<DataStatus<StatsByNewUsersResponse>> {
    dateFrom.setUTCHours(0, 0, 0, 0);
    dateUntil.setUTCHours(23, 59, 59, 999);

    let response: DataStatus<StatsByNewUsersResponseDto> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/statistics/by_new_users`,
      {
        'date_from': dateFrom.getTime(),
        'date_until': dateUntil.getTime(),
      },
    );

    return response.map((data) => {
      return this.newUsersStatsMapper.toDomain(data);
    });
  }

  async getStatsBySentPush(
    dateFrom: Date,
    dateUntil: Date,
  ): Promise<DataStatus<StatsBySentPushResponse>> {
    dateFrom.setUTCHours(0, 0, 0, 0);
    dateUntil.setUTCHours(23, 59, 59, 999);

    let result: DataStatus<StatsBySentPushResponseDto> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/statistics/by_sent_push`,
      {
        'date_from': dateFrom.getTime(),
        'date_until': dateUntil.getTime(),
      },
    );

    return result.map((data) => {
      return this.statsBySentPushResponseMapper.toDomain(data);
    });
  }

  async getStatusBySentSms(
    dateFrom: Date,
    dateUntil: Date,
  ): Promise<DataStatus<StatsBySentSmsResponse>> {
    dateFrom.setUTCHours(0, 0, 0, 0);
    dateUntil.setUTCHours(23, 59, 59, 999);

    let result: DataStatus<StatsBySentPushResponseDto> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/statistics/by_sent_sms`,
      {
        'date_from': dateFrom.getTime(),
        'date_until': dateUntil.getTime(),
      },
    );

    return result.map((data) => {
      return this.statsBySentSmsResponseMapper.toDomain(data);
    });
  }
}
