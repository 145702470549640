import { Injectable } from '@angular/core';
import { OfficeCityType } from '../../domain/orion-office';
import { OrionOfficeCreateRequest } from '../../domain/orion-office-create-request';
import { OrionOfficeCreateRequestDto } from '../dto/orion-office-create-request-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficeCreateRequestMapper {
  toData(entity: OrionOfficeCreateRequest): OrionOfficeCreateRequestDto {
    return new OrionOfficeCreateRequestDto(
      entity.title,
      entity.address,
      entity.latitude,
      entity.longitude,
      this.mapCityToEnum(entity.city),
    );
  }

  private mapCityToEnum(city: OfficeCityType): string {
    for (let enumMember in OfficeCityType) {
      if (OfficeCityType[enumMember as keyof typeof OfficeCityType] == city) {
        return enumMember;
      }
    }
    return city.valueOf();
  }
}
