<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить услугу'"
    data-bs-toggle="modal"
    data-bs-target="#modal-create-service"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <div class="row mb-3">
    <div class="col-12">
      <div class="input-icon">
        <input
          type="text"
          value=""
          class="form-control"
          placeholder="Поиск по доступным услугам..."
          (input)="searchAvailableService($event).finally()"
        />
        <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
      </div>
    </div>
  </div>

  <app-loadable-wrapper [loadable]="pageAvailableServices" loadingTitle="Загрузка данных">
    <app-pagination-view
      [data]="pageAvailableServices"
      [perPage]="perPage"
      [pageNumber]="pageNumber"
    >
      <table class="table table-vcenter card-table">
        <thead>
          <tr>
            <th class="text-center">ID услуги</th>
            <th class="text-center">Тип услуги</th>
            <th class="text-center">Название</th>
            <th class="text-center">Доступно отключение</th>
            <th class="text-center">Расширение услуги</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pageAvailableServices.safeData?.data">
            <td class="text-center" style="color: #6c7a91">{{ item.serviceId }}</td>
            <td class="text-center">{{ getServiceType(item.serviceType) }}</td>
            <td class="text-center">{{ item.name }}</td>
            <td class="block text-center">{{ item.manualDeactivation ? 'Да' : 'Нет' }}</td>

            <td class="text-center">{{ item.serviceExtId ? 'Есть' : 'Нету' }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-link"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ti ti-dots-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-demo" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onDelete(item.id).finally()"
                    [disabled]="!serviceForm.valid"
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </app-pagination-view>
  </app-loadable-wrapper>
</app-page-wrapper>

<div
  class="modal modal-blur fade"
  id="modal-create-service"
  tabindex="-1"
  style="display: none"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">Добавление доступной услуги в список</div>
      <div class="modal-body" [formGroup]="serviceForm">
        <app-service-select-field
          label="Выберите услугу"
          formControlName="service"
          [isRequired]="true"
        >
        </app-service-select-field>

        <label class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="manualDeactivation" />
          <span class="form-check-label">Доступно самостоятельное отключение</span>
        </label>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link link-secondary me-auto"
          data-bs-dismiss="modal"
          #dismissButton
        >
          Отменить
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="createService()"
          [disabled]="!serviceForm.valid"
        >
          <span
            *ngIf="this.createServiceState.status == UIStateType.Loading"
            class="spinner-border spinner-border-sm me-2"
            role="status"
          ></span>
          Добавить
        </button>
      </div>
    </div>
  </div>
</div>
