import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, SkipSelf, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Task } from '../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BillingTariffRepository } from '../../../../../entity/billing-tariff/data/repositories/BillingTariffRepository';
import { BillingTariff } from '../../../../../entity/billing-tariff/domain/billing-tariff';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { TariffSelectFieldComponent } from '../../../../components/common/form-fields/tariff-select-field/tariff-select-field.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../components/pagination-view/pagination-view.component';
import { OfficeCityType, OfficeCityTypeMapper } from '../../../orion-offices/domain/orion-office';
import { AvailableTariffRepository } from '../../data/repositories/available-tariff-repository';
import { AvailableTariff, AvailableTariffTypeEnum } from '../../domain/available-tariff';
import { AvailableTariffCreate } from '../../domain/available-tariff-create';

@Component({
  selector: 'app-available-tariffs',
  standalone: true,
  imports: [
    NgForOf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    LoadableWrapperComponent,
    AddButtonComponent,
    RouterModule,
    PaginationViewComponent,
    TariffSelectFieldComponent,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './available-tariffs.component.html',
  styleUrl: './available-tariffs.component.css',
})
export class AvailableTariffComponent implements OnInit, PaginationViewDelegate {
  @ViewChild('modalCloseButton') modal!: ElementRef;

  public pageAvailableTariffs: Loadable<PaginationResponse<AvailableTariff>> =
    Loadable.notRequested();
  public createAvailableTariff: Loadable<AvailableTariff> = Loadable.notRequested();

  pageTitle: string = 'Список доступных тарифов';
  pageSubtitle: string = 'Тарифы';

  availableFormGroup!: FormGroup<AvailableTariffCreateForm>;
  searchQuery: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public perPage: number = 30;
  public currentPageNumber: number = 1;

  constructor(
    private availableTariffRepository: AvailableTariffRepository,
    private tariffInfoRepository: BillingTariffRepository,
    private formBuilder: NonNullableFormBuilder,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.availableFormGroup = this.formBuilder.group({
      billingTariff: [null, Validators.required],
      isForPrivateHousing: [false, Validators.required],
    }) as FormGroup<AvailableTariffCreateForm>;

    this.searchQuery.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value.length >= 0) {
        this.getAllAvailableTariffsPost(this.perPage, 1, value).finally();
      } else {
        this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
      }
    });
  }

  setQuery(event: any) {
    this.searchQuery.next(event.target.value);
  }

  didClickCreate() {
    if (this.availableFormGroup.valid) {
      new Task(async (context) => {
        let request = new AvailableTariffCreate(
          this.availableFormGroup.value.billingTariff!.tariffInfo.tariffId,
          this.availableFormGroup.value.isForPrivateHousing ?? false,
        );
        this.createAvailableTariff = Loadable.loading();

        let response = Loadable.getFromDataStatus(
          await this.availableTariffRepository.create(request),
        );

        this.createAvailableTariff = response;

        if (response.isError) {
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: response.message!,
          });
        }

        if (response.isSuccess) {
          this.availableFormGroup.reset({
            billingTariff: null,
            isForPrivateHousing: false,
          });
          this.modal.nativeElement.click();
          this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
        }
      });
    }
  }

  async getAllAvailableTariffsPost(
    perPage: number,
    pageNumber: number,
    searchQuery: string | null = null,
  ): Promise<void> {
    if (this.pageAvailableTariffs.status != UIStateType.Loading) {
      this.pageAvailableTariffs = Loadable.loading();
      let response = await this.availableTariffRepository.findAllPost(
        perPage,
        pageNumber,
        searchQuery,
      );
      this.pageAvailableTariffs = Loadable.getFromDataStatus(response);
    }
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.availableTariffRepository.delete(id);
      this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  cityString(city: string): string {
    let cityEnum: OfficeCityType = OfficeCityTypeMapper.fromString(city);

    switch (cityEnum) {
      case OfficeCityType.abakan:
        return 'Абакан';
      case OfficeCityType.bratsk:
        return 'Братск';
      case OfficeCityType.chernogorsk:
        return 'Черногорск';
      case OfficeCityType.irkutsk:
        return 'Иркутск';
      case OfficeCityType.kansk:
        return 'Канск';
      case OfficeCityType.kiselevsk:
        return 'Киселевск';
      case OfficeCityType.krasnoyarsk:
        return 'Красноярск';
      case OfficeCityType.minusinsk:
        return 'Минусинск';
      case OfficeCityType.novokuznetsk:
        return 'Новокузнецк';
      case OfficeCityType.prokopyevsk:
        return 'Прокопьевск';
      case OfficeCityType.sayanogorsk:
        return 'Саяногорск';
      case OfficeCityType.zelenogorsk:
        return 'Зеленогорск';
      case OfficeCityType.zheleznogorsk:
        return 'Железногорск';
    }
  }

  parseIncludedServices(services: string[]) {
    let result: string[] = [];

    for (let service of services) {
      if (service == '24htv') {
        result.push('24часаТВ');
      } else {
        result.push(AvailableTariffTypeEnum[service as keyof typeof AvailableTariffTypeEnum]);
      }
    }

    return result.join(', ');
  }

  protected readonly UIStateType = UIStateType;

  public didChangePage(newPage: number): void {
    if (newPage != this.currentPageNumber) {
      this.currentPageNumber = newPage;
      this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
    }
  }
}

export interface AvailableTariffCreateForm {
  billingTariff: FormControl<BillingTariff | null>;
  isForPrivateHousing: FormControl<boolean>;
}
