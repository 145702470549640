import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-dropdown-item',
  standalone: true,
  imports: [NgIf, RouterLink, RouterLinkActive],
  templateUrl: './dropdown-item.component.html',
  styleUrl: './dropdown-item.component.css',
})
export class DropdownItemComponent {
  @Input() dropdownTitle: string = '';
  @Input() isNew: boolean = false;
  @Input() routerLink?: string | any[];
}
