import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { AttachmentValidator } from '../../../../../../core/validators/AttachmentValidator';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { MobileStoryPreviewPreviewComponent } from '../mobile-preview-preview/mobile-story-preview-preview.component';
import { MobileStoriesPreviewComponent } from '../mobile-stories-preview/mobile-stories-preview.component';
import {
  StorySlideFormComponent,
  StorySlideFormOutput,
} from '../story-slide-form/story-slide-form.component';

@Component({
  selector: 'app-story-create-form',
  standalone: true,
  imports: [
    TextFieldComponent,
    StorySlideFormComponent,
    NgForOf,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    AttachmentCropperFieldComponent,
    TextareaFieldComponent,
    MobileStoriesPreviewComponent,
    MobileStoryPreviewPreviewComponent,
  ],
  templateUrl: './story-create-form.component.html',
  styleUrl: './story-create-form.component.css',
})
export class StoryCreateFormComponent implements OnInit {
  @Input() formState: UIStateType = UIStateType.NotRequested;
  @Input() delegate?: StoryCreateFormDelegate;

  activeSlideIndex: number = 0;

  protected form!: FormGroup<StoryCreateForm>;

  constructor(
    protected router: Router,
    private formBuilder: NonNullableFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: new FormControl('', [Validators.required]),
      preview: new FormControl({}, [Validators.required, AttachmentValidator()]),
      annotation: new FormControl(null),
      slides: this.formBuilder.array<FormControl<StorySlideFormOutput | null>>([
        new FormControl<StorySlideFormOutput>(
          StorySlideFormOutput.defaultValue,
          Validators.required,
        ),
      ]),
    }) as FormGroup<StoryCreateForm>;

    if (this.delegate?.didCreate) {
      this.delegate?.didCreate(this.form);
    }
  }

  addSlide() {
    let newItem = new FormControl<StorySlideFormOutput>(
      StorySlideFormOutput.defaultValue,
      Validators.required,
    );
    this.form.controls.slides.push(newItem);

    this.activeSlideIndex = this.form.controls.slides.length - 1;
  }

  removeSlideAtIndex(index: number) {
    this.form.controls.slides.removeAt(index);

    if (this.activeSlideIndex != 0) {
      this.activeSlideIndex -= 1;
    }
  }

  changeSelectedIndex(newIndex: number) {
    this.activeSlideIndex = newIndex;
  }

  backgroundImageForSlide(index: number) {
    return this.form.controls.slides.controls[index].value?.attachment?.getBackgroundImage();
  }

  didClickSubmit() {
    this.delegate?.didSubmit(this.form);
  }

  protected readonly UIStateType = UIStateType;
}

export interface StoryCreateFormDelegate {
  didCreate?: (form: FormGroup<StoryCreateForm>) => void;
  didSubmit: (form: FormGroup<StoryCreateForm>) => void;
}

export interface StoryCreateForm {
  title: FormControl<string>;
  preview: FormControl<AttachmentCropperData>;
  annotation: FormControl<string | null>;
  slides: FormArray<FormControl<StorySlideFormOutput | null>>;
}
