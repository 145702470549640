import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  AbonentServiceForm,
  AbonentServiceFormComponent,
  AbonentServiceFormComponentDelegate,
} from '../../components/abonent-service-form/abonent-service-form.component';
import { AbonentServiceRepository } from '../../data/repositories/abonent-service-repository';
import { AbonentService, AbonentServiceType } from '../../domain/abonent-service';
import { AbonentServiceCreateRequest } from '../../domain/abonent-service-create-request';

@Component({
  selector: 'app-abonent-services-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    FormsModule,
    ReactiveFormsModule,
    AbonentServiceFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './abonent-services-create.component.html',
  styleUrl: './abonent-services-create.component.css',
})
export class AbonentServicesCreateComponent implements OnInit {
  public abonentServiceChangeStatus$: BehaviorSubject<Loadable<AbonentService>> =
    new BehaviorSubject(new Loadable<AbonentService>());

  pageTitle: string = 'Добавление сервиса';
  pageSubtitle: string = 'Сервисы';

  sendFormDelegate: AbonentServiceFormComponentDelegate = {
    didSubmitForm: (form) => {
      this.onSubmit(form).finally();
    },
  };

  constructor(
    @SkipSelf() private modalService: ModalService,
    private abonentsServiceRepo: AbonentServiceRepository,
    private attachmentRepo: AttachmentRepository,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.abonentServiceChangeStatus$.subscribe((data) => {
      this.handleLoadableState(data);
    });
  }

  private handleLoadableState(data: Loadable<AbonentService>) {
    switch (data.status) {
      case UIStateType.Success:
        this.router.navigate(['abonent-services']).then(async () => {
          this.modalService.createModal({
            type: ModalType.SUCCESS,
            message: 'Сервис успешно создан.',
            acceptButtonAction: () => {},
          });
        });

        break;

      case UIStateType.Error:
        this.modalService.createModal({
          type: ModalType.DANGER,
          message: data.message!,
          acceptButtonAction: () => {},
        });
        break;
    }
  }

  async onSubmit(form: FormGroup<AbonentServiceForm>) {
    if (form.valid) {
      this.abonentServiceChangeStatus$.next(Loadable.loading());

      let result = await this.tryAssembleResult(form.getRawValue()).then((result) => {
        return result;
      });

      if (result) {
        try {
          let response = await this.abonentsServiceRepo.create(result);
          this.abonentServiceChangeStatus$.next(Loadable.getFromDataStatus(response));
        } catch (e) {
          this.abonentServiceChangeStatus$.next(Loadable.notRequested());
          if (e instanceof Error) {
            this.modalService.createModal({
              type: ModalType.DANGER,
              message: e.message,
            });
          }
        }
      } else {
        this.abonentServiceChangeStatus$.next(
          new Loadable<AbonentService>({
            kind: 'Error',
            message: 'Не удалось загрузить изображения на сервер',
          }),
        );
      }
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля',
      });
    }
  }
  private async tryAssembleResult(
    formOutput: ɵTypedOrUntyped<AbonentServiceForm, ɵFormGroupRawValue<AbonentServiceForm>, any>,
  ): Promise<AbonentServiceCreateRequest | null> {
    if (formOutput.icon?.croppedData && formOutput.image?.croppedData) {
      let attachments: { icon: Loadable<Attachment>; image: Loadable<Attachment> } =
        await Promise.all([
          this.attachmentRepo.create(formOutput.icon.croppedData),
          this.attachmentRepo.create(formOutput.image.croppedData),
        ]).then(([icon, image]) => {
          return {
            icon: Loadable.getFromDataStatus(icon).throwErrorResult(),
            image: Loadable.getFromDataStatus(image).throwErrorResult(),
          };
        });

      return new AbonentServiceCreateRequest(
        formOutput.type!,
        formOutput.title!,
        formOutput.short_description!,
        formOutput.full_description!,
        formOutput.type == AbonentServiceType.web_app ? formOutput.service_link_web! : undefined,
        formOutput.type == AbonentServiceType.mobile_app
          ? formOutput.service_link_android!
          : undefined,
        formOutput.type == AbonentServiceType.mobile_app ? formOutput.service_link_ios! : undefined,
        attachments.icon.data.id,
        attachments.image.data.id,
      );
    }

    return null;
  }

  protected readonly UIStateType = UIStateType;
}
