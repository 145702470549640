import { AdminUser } from '../../domain/admin-user';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { AdminUserMapper } from '../mappers/admin-user-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../core/network/data.status';
import { AdminUserApiDto } from '../admin-user-api-dto';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';

@Injectable({ providedIn: 'root' })
export class AdminUserRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private adminUserMapper: AdminUserMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async get(): Promise<DataStatus<AdminUser>> {
    let response: DataStatus<AdminUserApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/profile`,
    );

    return response.map((data) => {
      return this.adminUserMapper.toDomain(data);
    });
  }
}
