import { Deeplink } from '../../../../entity/deeplinks/domain/entities/deeplink';
import { Attachment } from '../../../../entity/attachments/domain/attachment';
import { ActionButton } from '../../stories/domain/story';

export class Banner {
  constructor(
    public id: number,
    public title: string,
    public description: string | null,
    public imageId: number | null,
    public deeplinkId: number | null,
    public actionButtonId: number | null,
    public image: Attachment | null,
    public deeplink: Deeplink | null,
    public actionButton: ActionButton | null,
  ) {}

  public getBackgroundImage(): string {
    return 'background-image: url(' + this.image?.content.attributes.content_path + ')';
  }
}
