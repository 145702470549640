<div
  class="modal modal-blur fade"
  [class.show]="showAnimation"
  tabindex="-1"
  [style.display]="isShow ? 'block' : 'none'"
  aria-modal="true"
  [attr.role]="isShow ? 'dialog' : null"
>
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      <div class="modal-status bg-danger"></div>
      <div class="modal-body text-center py-4">
        <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon mb-2 text-danger icon-lg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 9v4"></path>
          <path
            d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"
          ></path>
          <path d="M12 16h.01"></path>
        </svg>
        <h3>{{ header }}</h3>
        <div class="text-secondary">{{ message }}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link link-secondary me-auto" (click)="close()">
          {{ closeButtonText }}
        </button>
        <button type="button" class="btn btn-primary" (click)="accept()">
          {{ acceptButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-backdrop fade"
  [class.show]="showAnimation"
  tabindex="-1"
  [style.display]="isShow ? 'block' : 'none'"
></div>
