import { OutputData } from '@editorjs/editorjs';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { ServiceExtensionType } from './service-type';

export class ServiceExtension {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public serviceId: number,
    public serviceType: ServiceExtensionType,
    public attributes: ServiceExtensionAttributes,
  ) {}
}

export class ServiceExtensionCreateRequest {
  constructor(
    public title: string,
    public description: string,
    public serviceId: number,
    public serviceType: ServiceExtensionType,
    public attributes: ServiceExtensionAttributes,
  ) {}
}

export interface ServiceExtensionAttributes {}

export class CATVPacketAttributes implements ServiceExtensionAttributes {
  constructor(public numberOfChannels: number) {}
}

export class SmotreshkaCinemaAttributes implements ServiceExtensionAttributes {
  constructor(
    public iconId: number,
    public bannerId: number,
    public banner: Attachment,
    public icon: Attachment,
  ) {}
}

export class SmotreshkaPacketAttributes implements ServiceExtensionAttributes {
  constructor(
    public coverId: number | null,
    public cover: Attachment,
  ) {}
}

export class GeneralAttributes implements ServiceExtensionAttributes {
  constructor(
    public banner: Attachment,
    public text: OutputData | null,
  ) {}
}
