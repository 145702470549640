import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AttachmentContent } from '../../entity/attachments/domain/attachment';
import { AttachmentCropperData } from '../../features/components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';

export function AttachmentValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value as AttachmentCropperData | null | undefined;

    if (value && value.croppedData) {
      return null;
    }

    return { croppedDataIsEmpty: true };
  };
}
