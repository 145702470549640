import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';

@Component({
  selector: 'app-mobile-abonent-services-detail-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent, NgIf, CommonModule],
  templateUrl: './mobile-abonent-services-detail-preview.component.html',
  styleUrl: './mobile-abonent-services-detail-preview.component.css',
})
export class MobileAbonentServicesDetailPreviewComponent implements OnChanges {
  @Input() public title: string = '             ';
  @Input() public image: string = '';
  @Input() public description: string[] = [];
  descTemplate!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['description']) {
      this.descTemplate = this.description.join(' ');
    }
  }
}
