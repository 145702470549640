import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { BillingTariff } from '../../../../../../entity/billing-tariff/domain/billing-tariff';
import { TariffSelectFieldComponent } from '../../../../../components/common/form-fields/tariff-select-field/tariff-select-field.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';

@Component({
  selector: 'app-tariff-ext-form',
  standalone: true,
  imports: [
    TariffSelectFieldComponent,
    TextFieldComponent,
    TextareaFieldComponent,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './tariff-ext-form.component.html',
  styleUrl: './tariff-ext-form.component.css',
})
export class TariffExtFormComponent implements OnInit {
  @Input() sendingState: UIStateType = UIStateType.NotRequested;
  @Input() delegate?: TariffExtFormDelegate;

  formGroup!: FormGroup<TariffExtForm>;

  constructor(
    protected router: Router,
    private formBuilder: NonNullableFormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      tariff: [null, Validators.required],
      name: ['', Validators.required],
      description: [''],
    }) as FormGroup<TariffExtForm>;

    if (this.delegate?.didCreateForm) {
      this.delegate?.didCreateForm(this.formGroup);
    }
  }

  protected didSubmitForm() {
    if (this.delegate?.didSubmitForm) {
      this.delegate?.didSubmitForm(this.formGroup);
    }
  }

  protected readonly UIStateType = UIStateType;
}

export interface TariffExtFormDelegate {
  didCreateForm?: (form: FormGroup<TariffExtForm>) => void;
  didSubmitForm?: (form: FormGroup<TariffExtForm>) => void;
}

export interface TariffExtForm {
  tariff: FormControl<BillingTariff | null>;
  name: FormControl<string>;
  description: FormControl<string>;
}
