import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AttachmentValidator } from '../../../../../../../core/validators/AttachmentValidator';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import {
  ServiceExtensionAttributesDelegate,
  SmotreshkaPocketFormOutput,
} from '../service-extension-attributes.component';

@Component({
  selector: 'app-smotreshka-pocket-ext-form',
  standalone: true,
  imports: [AttachmentCropperFieldComponent, ReactiveFormsModule],
  templateUrl: './smotreshka-pocket-ext-form.component.html',
  styleUrl: './smotreshka-pocket-ext-form.component.css',
})
export class SmotreshkaPocketExtFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionAttributesDelegate;

  attributedForm!: FormGroup<SmotreshkaPocketForm>;

  constructor(public formBuilder: NonNullableFormBuilder) {}

  ngOnInit() {
    this.attributedForm = this.formBuilder.group({
      cover: new FormControl({}, [Validators.required, AttachmentValidator()]),
    }) as FormGroup<SmotreshkaPocketForm>;

    this.attributedForm.valueChanges.subscribe((newValue) => {
      if (this.attributedForm.valid) {
        const attr = new SmotreshkaPocketFormOutput(newValue.cover!);
        this.delegate?.onChangeValue(attr);
      }
    });
  }
}

interface SmotreshkaPocketForm {
  cover: FormControl<AttachmentCropperData>;
}
