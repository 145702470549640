import { Component, Input } from '@angular/core';
import { ServiceDef } from '../../../../../dashboard/services/domain/entities/service-def';

@Component({
  selector: 'app-available-service-def-list-item',
  standalone: true,
  imports: [],
  templateUrl: './service-def-list-item.component.html',
  styleUrl: './service-def-list-item.component.css',
})
export class ServiceDefListItemComponent {
  @Input() entity!: ServiceDef;
}
