import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-imodal-component',
  standalone: true,
  imports: [],
  template: '',
})
export class IModalComponentComponent implements OnInit {
  @Input() message: string = 'Сообщение';
  @Input() header: string = 'Заголовок';
  @Input() acceptButtonText: string = 'ОК';
  @Input() closeButtonText: string = 'Закрыть';

  @Output() closeAction: EventEmitter<void> = new EventEmitter();
  @Output() acceptAction: EventEmitter<void> = new EventEmitter();

  _isShow = new BehaviorSubject(false);
  isShow: any = this._isShow.asObservable();

  showAnimation: boolean = false;
  element: any;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  close() {
    this.closeAction.emit();
  }

  accept() {
    this.acceptAction.emit();
  }

  ngOnInit(): void {
    this.element.addEventListener('click', (el: any) => {
      if (el.target.className.startsWith('modal') && el.target.className.includes('show')) {
        this.close();
      }
    });

    this.isShow.pipe(debounceTime(300)).subscribe(() => {
      this.showAnimation = true;
    });
  }

  public present() {
    this.isShow = this._isShow.asObservable();
    this._isShow.next(false);
  }

  public hide() {
    this.isShow.pipe(debounceTime(10000)).subscribe(() => {
      this.showAnimation = false;
    });
    this.isShow = null;
  }
}
