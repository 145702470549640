import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { PaginationResponseItemMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-item-mapper';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { Deeplink } from '../../domain/deeplink';
import { DeeplinkCreate } from '../../domain/deeplink-create';
import { DeeplinkCreateMapper } from '../../mappers/deeplink-create-mapper';
import { DeeplinkMapper } from '../../mappers/deeplink-mapper';
import { DeeplinkApiDto } from '../dto/deeplink-api-dto';

@Injectable({ providedIn: 'root' })
export class DeeplinkRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private deeplinkMapper: DeeplinkMapper,
    private deeplinkCreateMapper: DeeplinkCreateMapper,
    private paginationResponseItemMapper: PaginationResponseItemMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async getAll(
    per_page: number,
    page_number: number,
  ): Promise<DataStatus<PaginationResponse<Deeplink>>> {
    const payload = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [{ 'column': 'deleted_at', 'operator': 'eq', 'value': null }],
    };

    let response: DataStatus<PaginationResponseApiDto<DeeplinkApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks`,
        payload,
      );

    return response.map((t) => {
      return this.paginationResponseItemMapper.toDomain(t, (i) => {
        return this.deeplinkMapper.toDomain(i);
      });
    });
  }

  async findByTitle(
    per_page: number,
    title: string,
  ): Promise<DataStatus<PaginationResponse<Deeplink>>> {
    const payload = {
      'pagination': {
        'per_page': per_page,
        'page_number': 1,
      },
      'filters': [
        { 'column': 'deleted_at', 'operator': 'eq', 'value': null },
        { 'column': 'title', 'operator': 'like', 'value': `%${title}%` },
      ],
    };

    let response: DataStatus<PaginationResponseApiDto<DeeplinkApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks`,
        payload,
      );

    return response.map((t) => {
      return this.paginationResponseItemMapper.toDomain(t, (i) => {
        return this.deeplinkMapper.toDomain(i);
      });
    });
  }

  async getById(id: number) {
    let response: DataStatus<DeeplinkApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks/${id}`,
    );

    return response.map((t) => {
      return this.deeplinkMapper.toDomain(t);
    });
  }
  async create(request: DeeplinkCreate): Promise<DataStatus<Deeplink>> {
    let response: DataStatus<DeeplinkApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks`,
      this.deeplinkCreateMapper.toDto(request),
    );

    return response.map((t) => {
      return this.deeplinkMapper.toDomain(t);
    });
  }

  async update(request: DeeplinkCreate, id: number): Promise<DataStatus<Deeplink>> {
    let response: DataStatus<DeeplinkApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks/${id}`,
      this.deeplinkCreateMapper.toDto(request),
    );

    return response.map((it) => {
      return this.deeplinkMapper.toDomain(it);
    });
  }

  async delete(id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks/${id}`,
    );
  }
}
