import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { ActionButton } from '../../domain/action-button';
import { ActionButtonCreateRequest } from '../../domain/action-button-create-request';
import { ActionButtonDto } from '../dto/action-button-dto';
import { ActionButtonCreateRequestMapper } from '../mappers/action-button-create-request-mapper';
import { ActionButtonMapper } from '../mappers/action-button-mapper';

@Injectable({ providedIn: 'root' })
export class ActionButtonsRepository {
  constructor(
    private httpService: HttpClientWrapper,
    private actionButtonMapper: ActionButtonMapper,
    private actionButtonCreateRequestMapper: ActionButtonCreateRequestMapper,
    @Inject(APP_CONFIG) private applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(request: ActionButtonCreateRequest): Promise<DataStatus<ActionButton>> {
    let response: DataStatus<ActionButtonDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/action_buttons`,
      this.actionButtonCreateRequestMapper.toData(request),
    );

    return response.map((data) => {
      return this.actionButtonMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/action_buttons/${id}`,
    );
  }
}
