import { Injectable } from '@angular/core';
import { AppLinkCreate, AppLinkTypeEnum } from '../../domain/app-link-create';
import { AppLinkCreateApiDto } from '../dto/app-link-create-api-dto';

@Injectable({ providedIn: 'root' })
export class AppLinkCreateMapper {
  toData(entity: AppLinkCreate): AppLinkCreateApiDto {
    return new AppLinkCreateApiDto(
      entity.type.valueOf(),
      entity.type == AppLinkTypeEnum.DEEPLINK ? entity.deeplink?.id ?? null : null,
      entity.type == AppLinkTypeEnum.HTTP_LINK ? entity.httpLink : null,
    );
  }
}
