import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Task } from '../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { ActionButtonsRepository } from '../../../../../entity/action-button/data/repo/action-buttons-repository';
import { ActionButtonCreateRequest } from '../../../../../entity/action-button/domain/action-button-create-request';
import { AppLinkRepository } from '../../../../../entity/app-link/data/repo/app-link-repository';
import { AppLinkCreate } from '../../../../../entity/app-link/domain/app-link-create';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { AttachmentType } from '../../../../../entity/attachments/domain/attachment';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
// import { IDangerModalInitParams } from '../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { StoryRepository } from '../../data/repositories/story-repository';
import { StorySlideRepository } from '../../data/repositories/story-slide-repository';
import { StoriesCreateRequest } from '../../domain/stories-create-request';
import { Story } from '../../domain/story';
import { StorySlideCreateRequest } from '../../domain/story-slide-create-request';
import {
  StoryCreateForm,
  StoryCreateFormComponent,
  StoryCreateFormDelegate,
} from '../components/story-create-form/story-create-form.component';

@Component({
  selector: 'app-stories-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    RouterModule,
    ReactiveFormsModule,
    StoryCreateFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './stories-create.component.html',
  styleUrl: './stories-create.component.css',
})
export class StoriesCreateComponent implements OnInit, StoryCreateFormDelegate {
  pageTitle: string = 'Добавление истории';
  pageSubtitle: string = 'Истории';

  createStoryState: BehaviorSubject<Loadable<Story>> = new BehaviorSubject<Loadable<Story>>(
    Loadable.notRequested(),
  );

  constructor(
    private storyRepo: StoryRepository,
    private storySlideRepo: StorySlideRepository,
    private attachmentRepo: AttachmentRepository,
    private actionButtonsRepo: ActionButtonsRepository,
    private appLinkRepository: AppLinkRepository,
    public router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.createStoryState.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          new Task(async () => {
            await this.router.navigate(['stories']);
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: `История ${t.data.name} успешно создана.`,
            });
          });
          break;
        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
          break;
      }
    });
  }

  public didSubmit(form: FormGroup<StoryCreateForm>): void {
    if (form.valid) {
      new Task(async () => {
        try {
          await this.createStory(form);
        } catch (error) {
          if (error instanceof Error) {
            this.modalService.createModal({
              type: ModalType.DANGER,
              message: error.message!,
            });
          }
        }
      });
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля.',
      });
    }
  }

  private async createStory(form: FormGroup<StoryCreateForm>) {
    if (form.value.preview?.croppedData && form.value.title) {
      this.createStoryState.next(Loadable.loading());

      let attachment = Loadable.getFromDataStatus(
        await this.attachmentRepo.create(
          form.value.preview?.croppedData,
          AttachmentType.STORY_PREVIEW,
        ),
      ).throwErrorResult();

      let storyRequest = new StoriesCreateRequest(
        form.value.title,
        attachment.data.id,
        form.value.annotation ?? '',
      );

      let story = Loadable.getFromDataStatus(
        await this.storyRepo.create(storyRequest),
      ).throwErrorResult();

      let promiseAll: Promise<any>[] = [];

      form.value.slides?.forEach((t) => {
        if (t) {
          let slideCreate = new Task(async () => {
            let attachment = Loadable.getFromDataStatus(
              await this.attachmentRepo.create(
                t.attachment?.file as Blob,
                AttachmentType.STORY_SLIDE,
              ),
            ).throwErrorResult();

            let slideRequest = new StorySlideCreateRequest(
              t.title ?? '',
              t.description ?? '',
              story.data.id,
              attachment.data.id,
            );
            let slide = Loadable.getFromDataStatus(
              await this.storySlideRepo.create(slideRequest),
            ).throwErrorResult();

            await Promise.all(
              t.actionButtons.map((r) => {
                return new Task(async () => {
                  let appLink = Loadable.getFromDataStatus(
                    await this.appLinkRepository.create(
                      new AppLinkCreate(
                        r.appLink.appLinkType,
                        r.appLink.deepLink,
                        r.appLink.httpLink,
                      ),
                    ),
                  );
                  return this.actionButtonsRepo.create(
                    new ActionButtonCreateRequest(r.title, appLink.data.id, slide.data.id),
                  );
                }).toPromise();
              }),
            );
          }).toPromise();

          promiseAll.push(slideCreate);
        }
      });

      await Promise.all(promiseAll);

      this.createStoryState.next(story);
    }
  }
}
