import { Banner } from '../../domain/banner';
import { BannerApiDto } from '../banner-api-dto';
import { Injectable } from '@angular/core';
import { DeeplinkMapper } from '../../../../../entity/deeplinks/data/mappers/deeplink-mapper';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { ActionButtonMapper } from '../../../stories/data/mappers/action-button';

@Injectable({ providedIn: 'root' })
export class BannerMapper {
  constructor(
    private attachmentMapper: AttachmentMapper,
    private deeplinkMapper: DeeplinkMapper,
    private actionButtonMapper: ActionButtonMapper,
  ) {}

  public toDomain(dto: BannerApiDto): Banner {
    return new Banner(
      dto.id,
      dto.title,
      dto.description,
      dto.image_id,
      dto.deeplink_id,
      dto.action_button_id,
      dto.image !== null ? this.attachmentMapper.toDomain(dto.image) : null,
      dto.deeplink !== null ? this.deeplinkMapper.toDomain(dto.deeplink) : null,
      dto.action_button !== null ? this.actionButtonMapper.toDomain(dto.action_button) : null,
    );
  }
}
