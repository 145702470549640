import { Injectable } from '@angular/core';
import { OfficeContact } from '../../domain/orion-office-contact';
import { OfficeContactApiDto } from '../dto/orion-office-contact-dto';

@Injectable({ providedIn: 'root' })
export class OfficeContactMapper {
  constructor() {}

  public toDomain(dto: OfficeContactApiDto): OfficeContact {
    return new OfficeContact(dto.id, dto.office_id, dto.phone);
  }
}
