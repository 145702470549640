import { Injectable } from '@angular/core';
import { AvailableTariff } from '../../domain/available-tariff';
import { AvailableTariffApiDto } from '../dto/available-tariff-api-dto';

@Injectable({ providedIn: 'root' })
export class AvailableTariffMapper {
  constructor() {}

  public toDomain(dto: AvailableTariffApiDto): AvailableTariff {
    return new AvailableTariff(
      dto.id,
      dto.billing_tariff_id,
      dto.name,
      dto.included_services,
      dto.is_for_private_housing,
      dto.speed_day,
      dto.cost,
      dto.city,
    );
  }
}
