import { NgForOf, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import {
  AttachmentContent,
  AttachmentContentType,
} from '../../../../../entity/attachments/domain/attachment';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { DateOutputPipe } from '../../../../components/common/pipes/date-output-pipe';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { StoryRepository } from '../../data/repositories/story-repository';
import { Story } from '../../domain/story';

@Component({
  selector: 'app-stories-list',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    AddButtonComponent,
    PaginationViewComponent,
    DateOutputPipe,
  ],
  providers: [ModalService],
  templateUrl: './stories-list.component.html',
  styleUrl: './stories-list.component.css',
})
export class StoriesListComponent implements OnInit {
  public pageActiveStories: Loadable<PaginationResponse<Story>> = new Loadable<
    PaginationResponse<Story>
  >();
  public pageHiddenStories: Loadable<PaginationResponse<Story>> = new Loadable<
    PaginationResponse<Story>
  >();
  public pageTitle: string = 'Список историй';
  public pageSubtitle: string = 'Истории';

  public activeStoriesPerPage: number = 25;
  public activeStoriesPageNumber: number = 1;

  public hiddenStoriesPerPage: number = 25;
  public hiddenStoriesPageNumber: number = 1;

  constructor(
    private StoryRepo: StoryRepository,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async getAllActiveStories(
    per_page: number,
    page_number: number,
    search_name: string | null = null,
  ): Promise<void> {
    if (this.pageActiveStories.status != UIStateType.Loading) {
      this.pageActiveStories = Loadable.loading();
      await this.StoryRepo.findAllPost(per_page, page_number, true, search_name).then((data) => {
        this.pageActiveStories = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeActiveStoriesPerPage($event: any) {
    this.activeStoriesPerPage = $event.target.value;
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
  }

  changeActiveStoriesPage(pageNumber: number) {
    this.activeStoriesPageNumber = pageNumber;
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
  }

  async getAllHiddenStories(
    per_page: number,
    page_number: number,
    search_name: string | null = null,
  ): Promise<void> {
    if (this.pageHiddenStories.status != UIStateType.Loading) {
      this.pageHiddenStories = new Loadable<PaginationResponse<Story>>({
        kind: 'Loading',
      });
      await this.StoryRepo.findAllPost(per_page, page_number, false, search_name).then((data) => {
        this.pageHiddenStories = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeHiddenStoriesPerPage($event: any): void {
    this.hiddenStoriesPerPage = $event.target.value;
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  changeHiddenStoriesPage(pageNumber: number): void {
    this.hiddenStoriesPageNumber = pageNumber;
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  searchStory($event: any): void {
    let searchName = $event.target.value;

    if (searchName !== '') {
      this.getAllActiveStories(this.activeStoriesPerPage, 1, searchName).finally();
      this.getAllHiddenStories(this.hiddenStoriesPerPage, 1, searchName).finally();
    } else {
      this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
      this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
    }
  }

  async deactivateStory(storyId: number): Promise<void> {
    await this.StoryRepo.changeActiveState(false, storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async activateStory(storyId: number): Promise<void> {
    await this.StoryRepo.changeActiveState(true, storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async deleteStory(storyId: number): Promise<void> {
    await this.StoryRepo.delete(storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  protected readonly UIStateType = UIStateType;
  protected readonly AttachmentContent = AttachmentContent;
  protected readonly AttachmentContentType = AttachmentContentType;
}
