import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthClient } from '../../infrastructure/network/oauth-client';

@Component({
  selector: 'app-auth-redirect',
  standalone: true,
  imports: [],
  template: '<p></p>',
})
export class LogoutComponent {
  constructor(
    private oauthClient: OAuthClient,
    private router: Router,
  ) {}

  ngOnInit() {
    this.oauthClient.revokeToken().then((r) => this.redirectToMainPage());
  }

  private redirectToMainPage(): void {
    this.router.navigate(['']);
  }
}
