<app-form-component [title]="'Полная карточка сервиса'">
  <form [formGroup]="abonentServiceForm">
    <div class="card">
      <div class="card-body">
        <label class="form-label">Загрузите обложку сервиса на экран сервиса (опционально)</label>
        <drag-and-drop-file-upload
          *ngIf="imageChangedEvent == null"
          formControlName="image"
          (onFileSelect)="imageFileChangeEvent($event)"
        ></drag-and-drop-file-upload>
        <div class="image-cropper-container" *ngIf="imageChangedEvent !== null">
          <button class="btn btn-danger remove-icon-btn" (click)="removeImage()">
            <i class="ti ti-trash"></i>
          </button>
          <image-cropper
            class="image-cropper"
            *ngIf="imageChangedEvent !== null"
            [aspectRatio]="1080 / 672"
            [imageChangedEvent]="imageChangedEvent"
            (imageCropped)="imageCropped($event)"
            format="jpeg"
            [maintainAspectRatio]="true"
          ></image-cropper>
        </div>
      </div>

      <div class="mt-3 information_doc card-footer">
        <div class="row">
          <div class="col-md-12">
            <div class="file-req-header">Рекомендация к обложке</div>
            <div class="file-req-text">
              Рекомендуемый размер для обложки - 1080 х 900, где основное изображение находится в
              центральной области и помещаеся в зону - 1080 х 672
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 mt-3">
      <label class="form-label required">Тип сервиса</label>
      <select
        class="form-select"
        placeholder="Выберите тип сервиса"
        formControlName="type"
        (change)="checkChosenType($event)"
      >
        <option *ngFor="let enum of keys(abonentServiceType)" [value]="[enum]">
          {{ getAbonentServiceType(enum) }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <div *ngIf="chosenType == 'web_app'">
        <label class="form-label">Ссылка на сервис</label>
        <input
          class="form-control"
          placeholder="Введите ссылку на сервис"
          formControlName="service_link_web"
        />
      </div>
      <div *ngIf="chosenType == 'mobile_app'">
        <div class="mb-3">
          <label class="form-label">Ссылка на сервис в App Store (iOS)</label>
          <input
            class="form-control"
            placeholder="Введите ссылку на сервис"
            formControlName="service_link_ios"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Ссылка на сервис в Google Play (Android)</label>
          <input
            class="form-control"
            placeholder="Введите ссылку на сервис"
            formControlName="service_link_android"
          />
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label required">Описание сервиса</label>
      <div class="card">
        <div class="card-body">
          <div id="editor-js" (input)="onInput()"></div>
        </div>
      </div>
    </div>
  </form>
</app-form-component>
