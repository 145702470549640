import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { ModalService } from '../../modal-service';
import { ModalParamsInterface, ModalType } from '../domain/modal-params-interface';

@Component({
  selector: 'app-modal-view-component',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './modal-view-component.html',
  styleUrl: './modal-view-component.css',
  providers: [ModalService],
})
export class ModalViewComponent implements OnInit {
  @Input() modalConfig?: ModalParamsInterface;

  private _isShow: boolean = false;
  get isShow() {
    return this._isShow;
  }

  set isShow(newValue: boolean) {
    this._isShow = newValue;
  }

  showAnimation: boolean = false;

  defaultHeader!: string;

  constructor(@SkipSelf() private modalService: ModalService) {}

  get isSuccessAlert(): boolean {
    return this.modalConfig?.type == ModalType.SUCCESS;
  }

  get isDangerAlert(): boolean {
    return this.modalConfig?.type == ModalType.DANGER;
  }
  async close(action?: () => void) {
    if (action) {
      action();
    }

    this.showAnimation = false;
    await this.delay(300).then(() => {
      this.isShow = false;

      if (this.modalConfig) {
        this.modalService.removeModal(this.modalConfig);
      }
    });
  }

  ngOnInit(): void {
    this.present().finally();

    this.defaultHeader = (() => {
      switch (this.modalConfig?.type) {
        case ModalType.DANGER:
          return 'Ошибка';
        default:
          return 'Успех';
      }
    })();
  }

  public async present() {
    this.isShow = true;
    await this.delay(300).then(() => {
      this.showAnimation = true;
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
