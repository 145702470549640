import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { AppLinkCreate } from '../../domain/app-link-create';
import { AppLinkApiDto } from '../dto/app-link-api-dto';
import { AppLinkCreateMapper } from '../mapper/app-link-create-mapper';
import { AppLinkMapper } from '../mapper/app-link-mapper';

@Injectable({ providedIn: 'root' })
export class AppLinkRepository {
  constructor(
    private httpService: HttpClientWrapper,
    private appLinkCreateMapper: AppLinkCreateMapper,
    private appLinkMapper: AppLinkMapper,
    @Inject(APP_CONFIG) private applicationConfig: IEnvironment,
  ) {}

  async create(request: AppLinkCreate) {
    let response: DataStatus<AppLinkApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/app_links`,
      this.appLinkCreateMapper.toData(request),
    );
    return response.map((t) => {
      return this.appLinkMapper.toDomain(t);
    });
  }

  async update(request: AppLinkCreate, id: number) {
    let response: DataStatus<AppLinkApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/app_links/${id}`,
      this.appLinkCreateMapper.toData(request),
    );
    return response.map((t) => {
      return this.appLinkMapper.toDomain(t);
    });
  }
}
