import { OfficeBusinessHours } from '../../orion-offices-business-hours/domain/orion-office-business-hours';
import { OfficeContact } from '../../orion-offices-contacts/domain/orion-office-contact';

export enum OfficeCityType {
  krasnoyarsk = 'Красноярск',
  irkutsk = 'Иркутск',
  kansk = 'Канск',
  abakan = 'Абакан',
  bratsk = 'Братск',
  chernogorsk = 'Черногорск',
  minusinsk = 'Минусинск',
  zheleznogorsk = 'Железногорск',
  zelenogorsk = 'Зеленогорск',
  sayanogorsk = 'Саяногорск',
  kiselevsk = 'Киселевск',
  novokuznetsk = 'Новокузнетск',
  prokopyevsk = 'Прокопьевск',
}

export const OfficeCityCoordinatesType = {
  krasnoyarsk: [56.010543, 92.852581],
  irkutsk: [52.289588, 104.280606],
  kansk: [56.204179, 95.706654],
  abakan: [53.721152, 91.442396],
  bratsk: [56.151682, 101.633505],
  chernogorsk: [53.827013, 91.306005],
  minusinsk: [56.151682, 101.633505],
  zheleznogorsk: [56.250952, 93.532677],
  zelenogorsk: [56.113291, 94.588844],
  sayanogorsk: [53.100762, 91.412204],
  kiselevsk: [54.006072, 86.636742],
  novokuznetsk: [53.757553, 87.136053],
  prokopyevsk: [53.884493, 86.750055],
};

export class OrionOffice {
  constructor(
    public id: number,
    public title: string,
    public address: string,
    public latitude: number,
    public longitude: number,
    public city: string,
    public businessDays: Array<OfficeBusinessHours> | undefined,
    public contacts: Array<OfficeContact> | undefined,
  ) {}
}
