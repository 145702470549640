import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { AttachmentValidator } from '../../../../../../core/validators/AttachmentValidator';
import {
  AppLinkFieldComponent,
  AppLinkFieldOutput,
} from '../../../../../components/common/form-fields/app-link-field/app-link-field.component';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { MobileBannerPreviewComponent } from '../../mobile-banner-preview/mobile-banner-preview.component';

@Component({
  selector: 'app-banner-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormComponentComponent,
    NgIf,
    MobileBannerPreviewComponent,
    AttachmentCropperFieldComponent,
    AppLinkFieldComponent,
    TextareaFieldComponent,
    TextFieldComponent,
  ],
  templateUrl: './banner-form.html',
  styleUrl: './banner-form.css',
})
export class BannerFormComponent implements OnInit {
  @Input() delegate?: BannerFormDelegate;
  @Input() formState: UIStateType = UIStateType.NotRequested;

  protected bannerForm!: FormGroup<BannerForm>;

  constructor(
    public router: Router,
    public formBuilder: NonNullableFormBuilder,
  ) {}

  ngOnInit() {
    this.bannerForm = this.formBuilder.group({
      title: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      description: new FormControl(''),
      image: new FormControl<AttachmentCropperData>({}, [
        Validators.required,
        AttachmentValidator(),
      ]),
      buttonTitle: new FormControl(null),
      appLink: new FormControl(null),
    }) as FormGroup<BannerForm>;

    if (this.delegate?.didCreateForm) {
      this.delegate.didCreateForm(this.bannerForm);
    }
  }

  onSubmit() {
    if (this.delegate) {
      this.delegate.didSubmitForm(this.bannerForm);
    }
  }

  protected readonly UIStateType = UIStateType;
}

export interface BannerFormDelegate {
  didCreateForm?: (form: FormGroup<BannerForm>) => void;
  didSubmitForm: (form: FormGroup<BannerForm>) => void;
}

export interface BannerForm {
  title: FormControl<string>;
  description: FormControl<string | null>;
  image: FormControl<AttachmentCropperData>;
  buttonTitle: FormControl<string | null>;
  appLink: FormControl<AppLinkFieldOutput | null>;
}
