import { OutputData } from '@editorjs/editorjs';
import { AbonentServiceType } from './abonent-service';

export class AbonentServiceCreateRequest {
  constructor(
    public type: AbonentServiceType,
    public title: string,
    public short_description: string,
    public full_description: OutputData,
    public service_link_web: string | undefined | null,
    public service_link_android: string | undefined | null,
    public service_link_ios: string | undefined | null,
    public icon_id: number,
    public image_id: number,
  ) {}
}
