import { NgForOf } from '@angular/common';
import { Component, OnInit, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { DataStatus } from '../../../../../core/network/data.status';
import { Task } from '../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../components/pagination-view/pagination-view.component';
import { TariffExtRepository } from '../../data/repo/tariff-ext-repository';
import { TariffExt } from '../../domain/tariff-ext';
import { TariffExtListItemComponent } from './tariff-ext-list-item/tariff-ext-list-item.component';

@Component({
  selector: 'app-tariff-ext-list-page',
  standalone: true,
  imports: [
    AddButtonComponent,
    BreadcrumbsComponent,
    LoadableWrapperComponent,
    NgForOf,
    PageHeaderComponent,
    PageWrapperComponent,
    PaginationViewComponent,
    TariffExtListItemComponent,
  ],
  templateUrl: './tariff-ext-list-page.component.html',
  styleUrl: './tariff-ext-list-page.component.css',
})
export class TariffExtListPageComponent implements OnInit, PaginationViewDelegate {
  tariffListState: Loadable<PaginationResponse<TariffExt>> = Loadable.notRequested();

  pageTitle = 'Список расширений тарифов';
  pageSubtitle = 'Тарифы';

  currentPage: number = 1;
  perPage: number = 30;

  constructor(
    protected router: Router,
    @SkipSelf() private modalService: ModalService,
    private tariffExtRepository: TariffExtRepository,
  ) {}

  ngOnInit(): void {
    new Task(async () => {
      await this.fetchTariffList();
    });
  }

  protected async fetchTariffList() {
    if (this.tariffListState.status != UIStateType.Loading) {
      this.tariffListState = Loadable.loading();

      let response: DataStatus<PaginationResponse<TariffExt>> =
        await this.tariffExtRepository.getAll(this.perPage, this.currentPage);
      this.tariffListState = Loadable.getFromDataStatus(response);
    }
  }

  didDeleteItem(item: TariffExt) {
    new Task(async () => {
      let response = Loadable.getFromDataStatus(await this.tariffExtRepository.delete(item.id));

      if (response.isError) {
        this.modalService.createModal({
          type: ModalType.DANGER,
          message: response.message!,
        });
      }
      await this.fetchTariffList();
    });
  }

  didChangePage(newPage: number): void {
    new Task(async () => {
      this.currentPage = newPage;
      await this.fetchTariffList();
    });
  }
}
