import { Component, Input, OnInit } from '@angular/core';
import { BillingTariff } from '../../../../../../entity/billing-tariff/domain/billing-tariff';

@Component({
  selector: 'app-available-tariff-list-item',
  standalone: true,
  imports: [],
  templateUrl: './billing-tariff-list-item.component.html',
  styleUrl: './billing-tariff-list-item.component.css',
})
export class BillingTariffListItemComponent {
  @Input() entity!: BillingTariff;
}
