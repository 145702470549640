import { Injectable } from '@angular/core';
import { ActionButtonMapper } from '../../../../../entity/action-button/data/mappers/action-button-mapper';
import { AppLinkMapper } from '../../../../../entity/app-link/data/mapper/app-link-mapper';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { DeeplinkMapper } from '../../../deeplinks/mappers/deeplink-mapper';
import { Banner } from '../../domain/banner';
import { BannerApiDto } from '../dto/banner-api-dto';

@Injectable({ providedIn: 'root' })
export class BannerMapper {
  constructor(
    private attachmentMapper: AttachmentMapper,
    private deeplinkMapper: DeeplinkMapper,
    private appLinkMapper: AppLinkMapper,
    private actionButtonMapper: ActionButtonMapper,
  ) {}

  public toDomain(dto: BannerApiDto): Banner {
    return new Banner(
      dto.id,
      dto.title,
      dto.description,
      dto.image_id,
      dto.app_link_id,
      dto.action_button_id,
      dto.image ? this.attachmentMapper.toDomain(dto.image) : null,
      dto.app_link ? this.appLinkMapper.toDomain(dto.app_link) : null,
      dto.action_button ? this.actionButtonMapper.toDomain(dto.action_button) : null,
    );
  }
}
