import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom, retry } from 'rxjs';
import { ErrorResponseDto } from '../dashboard/data/dto/responce/error.response.dto';
import { DataStatus, DataStatusSuccess, DataStatusError } from './data.status';
import { OAuthAccessTokenLocal } from '../localData/oauth-access-token-local';

@Injectable({ providedIn: 'root' })
export class HttpClientWrapper {
  private httpClient: HttpClient;

  constructor(
    httpClient: HttpClient,
    private accessToken: OAuthAccessTokenLocal,
  ) {
    this.httpClient = httpClient;
  }

  private handleError<T>(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return new DataStatusError<T>(error.error);
  }

  private getDefaultHeaders() {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  async get<T>(urlPath: string): Promise<DataStatus<T>> {
    try {
      const baseHeaders = this.getDefaultHeaders();

      const response = firstValueFrom(
        this.httpClient.get<T>(urlPath, { headers: baseHeaders, observe: 'response' }),
      );

      let response_data: HttpResponse<T> = await response;
      console.log(response_data.headers.keys());
      if (response_data.body != null) {
        return new DataStatusSuccess<T>(response_data.body);
      }

      throw new Error('suck');
    } catch (error) {
      const httpErrorContent: ErrorResponseDto = (error as HttpErrorResponse).error;
      return new DataStatusError(httpErrorContent.errors) as DataStatus<T>;
    }
  }

  async post<T>(urlPath: string, body: any, headers?: HttpHeaders): Promise<DataStatus<T>> {
    try {
      const response = await firstValueFrom(
        this.httpClient.post<T>(urlPath, body, { headers: headers }),
      );
      let response_data = response;
      return new DataStatusSuccess<T>(response_data);
    } catch (error) {
      console.log(error);
      const httpErrorContent: ErrorResponseDto = (error as HttpErrorResponse).error;
      return new DataStatusError(httpErrorContent.errors);
    }
  }

  async put<T>(urlPath: string, body: any, headers?: HttpHeaders): Promise<DataStatus<T>> {
    console.log(body);
    try {
      const response = await firstValueFrom(
        this.httpClient.put<T>(urlPath, body, { headers: headers }),
      );
      let response_data = response;
      return new DataStatusSuccess<T>(response_data);
    } catch (error) {
      console.log(error);
      const httpErrorContent: ErrorResponseDto = (error as HttpErrorResponse).error;
      return new DataStatusError(httpErrorContent.errors);
    }
  }

  async delete<T>(urlPath: string): Promise<void> {
    const headers = new HttpHeaders();
    let options = { headers: headers, responseType: 'text' as 'json' };
    await firstValueFrom(this.httpClient.delete<string>(urlPath, options));
  }

  async patch<T>(urlPath: string, body: any, headers?: HttpHeaders): Promise<DataStatus<T>> {
    console.log(body);
    try {
      const response = await firstValueFrom(
        this.httpClient.patch<T>(urlPath, body, { headers: headers }),
      );
      let response_data = response;
      return new DataStatusSuccess<T>(response_data);
    } catch (error) {
      console.log(error);
      const httpErrorContent: ErrorResponseDto = (error as HttpErrorResponse).error;
      return new DataStatusError(httpErrorContent.errors);
    }
  }
}
