export class IDangerModalInitParams {
  constructor(
    public message: string,
    public acceptButtonAction: any,
    public closeButtonAction?: any,
    public header: string = 'Ошибка загрузки',
    public acceptButtonText?: string,
    public closeButtonText?: string,
  ) {}
}
