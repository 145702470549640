import { Injectable } from '@angular/core';
import { OfficeBusinessHours, WeekDaysEnum } from '../../domain/orion-office-business-hours';
import { OfficeBusinessHoursApiDto } from '../dto/orion-office-business-hours-dto';

@Injectable({ providedIn: 'root' })
export class OfficeBusinessHoursMapper {
  constructor() {}

  public toDomain(dto: OfficeBusinessHoursApiDto): OfficeBusinessHours {
    return new OfficeBusinessHours(
      dto.id,
      dto.office_id,
      this.weekdayFromString(dto.weekday),
      dto.open_time,
      dto.close_time,
      dto.is_day_off,
    );
  }

  weekdayFromString(str: string): WeekDaysEnum {
    let t = <WeekDaysEnum>WeekDaysEnum[str as keyof typeof WeekDaysEnum];
    console.log(`kase is ${t} from string ${str}`);
    return t;
  }
}
