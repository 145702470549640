import { Component } from '@angular/core';
import { OAuthClient } from '../../infrastructure/network/oauth-client';
import { delay } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-redirect',
  standalone: true,
  imports: [],
  templateUrl: './auth-redirect.component.html',
  styleUrl: './auth-redirect.component.css',
})
export class AuthRedirectComponent {
  constructor(
    private oauthClient: OAuthClient,
    private router: Router,
  ) {}

  ngOnInit() {
    this.oauthClient.getTokens().then((r) => this.redirectToMainPage());
  }

  private redirectToMainPage(): void {
    this.router.navigate(['']);
  }
}
