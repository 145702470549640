export class ISuccessModalInitParams {
  constructor(
    public message: string,
    public acceptButtonAction: any,
    public closeButtonAction?: any,
    public header: string = 'Успешно',
    public acceptButtonText?: string,
    public closeButtonText?: string,
  ) {}
}
