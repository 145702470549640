import { AdminUserApiDto } from '../admin-user-api-dto';
import { Injectable } from '@angular/core';
import { AdminUser } from '../../domain/admin-user';

@Injectable({ providedIn: 'root' })
export class AdminUserMapper {
  constructor() {}

  public toDomain(dto: AdminUserApiDto): AdminUser {
    return new AdminUser(dto.id, dto.erp_id, dto.name, dto.photo, dto.stuff);
  }
}
