import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { StoriesCreateRequest } from '../../domain/stories-create-request';
import { Story } from '../../domain/story';
import { StoryApiDto } from '../dto/stories-dto';
import { StoriesCreateRequestMapper } from '../mappers/stories-create-request-mapper';
import { StoryMapper } from '../mappers/story';

@Injectable({ providedIn: 'root' })
export class StoryRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private storyMapper: StoryMapper,
    private storiesCreateRequestMapper: StoriesCreateRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<StoryApiDto, Story> =
    new PaginationResponseMapper(this.storyMapper);

  async findAllPost(
    per_page: number,
    page_number: number,
    is_active: boolean,
    search_name: string | null = null,
  ): Promise<DataStatus<PaginationResponse<Story>>> {
    let payload: { [id: string]: any } = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [
        {
          'column': 'is_active',
          'operator': 'eq',
          'value': is_active,
        },
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
      'included': [
        {
          'entity_type': 'admin_creator',
        },
        {
          'entity_type': 'admin_editor',
        },
      ],
    };

    if (search_name) {
      payload['filters'].push({
        'column': 'name',
        'operator': 'ilike',
        'value': `${search_name}%`,
      });
    }

    let response: DataStatus<PaginationResponseApiDto<StoryApiDto>> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories`,
      payload,
    );
    return response.map((data) => {
      return this.paginationResponseMapper.toDomain(data);
    });
  }

  async getById(id: number): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${id}`,
    );

    return response.map((t) => {
      return this.storyMapper.toDomain(t);
    });
  }
  async create(request: StoriesCreateRequest): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories`,
      this.storiesCreateRequestMapper.toData(request),
    );

    return response.map((data) => {
      return this.storyMapper.toDomain(data);
    });
  }

  async changeActiveState(isActive: boolean, storyId: number): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${storyId}`,
      {
        'is_active': isActive,
      },
    );
    return response.map((data) => {
      return this.storyMapper.toDomain(data);
    });
  }
  async update(request: StoriesCreateRequest, storyId: number): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${storyId}`,
      this.storiesCreateRequestMapper.toData(request),
    );
    return response.map((data) => {
      return this.storyMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${id}`,
    );
  }
}
