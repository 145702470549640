import { Injectable, ViewContainerRef } from '@angular/core';
import { SuccessModalComponentComponent } from './modal-component/success-modal-component/success-modal-component.component';
import { DangerModalComponentComponent } from './modal-component/danger-modal-component/danger-modal-component.component';
import { IDangerModalInitParams } from './modal-component/danger-modal-component/idanger-modal-init-params';
import { ISuccessModalInitParams } from './modal-component/success-modal-component/isuccess-modal-init-params';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private viewContainer: ViewContainerRef) {}

  createSuccessModal(successModalParams: ISuccessModalInitParams) {
    let x = this.viewContainer.createComponent(SuccessModalComponentComponent);

    x.instance.acceptAction.subscribe(() => {
      successModalParams.acceptButtonAction();
      x.instance.hide();
      x.destroy();
    });
    x.instance.closeAction.subscribe(() => {
      if (successModalParams.closeButtonAction) {
        successModalParams.closeButtonAction();
      }
      x.instance.hide();
      x.destroy();
    });

    x.instance.message = successModalParams.message;
    x.instance.header = successModalParams.header;

    if (successModalParams.acceptButtonText) {
      x.instance.acceptButtonText = successModalParams.acceptButtonText;
    }

    if (successModalParams.closeButtonText) {
      x.instance.closeButtonText = successModalParams.closeButtonText;
    }

    x.instance.present();

    return x;
  }

  createDangerModal(dangerInitParams: IDangerModalInitParams) {
    let x = this.viewContainer.createComponent(DangerModalComponentComponent);

    x.instance.acceptAction.subscribe(() => {
      dangerInitParams.acceptButtonAction();
      x.instance.hide();
      x.destroy();
    });
    x.instance.closeAction.subscribe(() => {
      if (dangerInitParams.closeButtonAction) {
        dangerInitParams.closeButtonAction();
      }
      x.instance.hide();
      x.destroy();
    });

    x.instance.message = dangerInitParams.message;
    x.instance.header = dangerInitParams.header;

    if (dangerInitParams.acceptButtonText) {
      x.instance.acceptButtonText = dangerInitParams.acceptButtonText;
    }

    if (dangerInitParams.closeButtonText) {
      x.instance.closeButtonText = dangerInitParams.closeButtonText;
    }

    return x;
  }
}
