<form [formGroup]="abonentServiceForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <app-abonent-services-form-short-card
        [abonentServiceForm]="abonentServiceForm"
        [title]="title"
        (outputCroppedIcon)="onOutoutCroppedIcon($event)"
        (newTitle)="changeTitle($event)"
      >
      </app-abonent-services-form-short-card>

      <app-abonent-services-form-full-card
        [abonentServiceForm]="abonentServiceForm"
        (outputCroppedImage)="onOutputCroppedImage($event)"
        (fullDescriptionEmitter)="changeFullDescription($event)"
        (outputImagePreview)="onOutputImagePreview($event)"
      >
      </app-abonent-services-form-full-card>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр экрана сервиса в приложении</h3>
        </div>
        <div class="card-body">
          <app-mobile-abonent-services-detail-preview
            [title]="title"
            [image]="imagePreview"
            [description]="full_description"
          >
          </app-mobile-abonent-services-detail-preview>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/abonent-services'])" class="btn btn-link"> Отмена </a>
            <button type="submit" class="btn btn-primary" [disabled]="isSubmitted == true">
              <span
                *ngIf="isSubmitted == true"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
