import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Loadable, UIStateType } from '../../../../core/utils/wrappers/loadable';

@Component({
  selector: 'app-loadable-wrapper',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
  templateUrl: './loadable-wrapper.component.html',
})
export class LoadableWrapperComponent<T> {
  @Input() errorTemplate?: TemplateRef<{ message: string; repeatAction?: () => void }>;
  @Input() loadingTemplate?: TemplateRef<{ loadingTitle?: string }>;
  @Input() emptyDataTemplate?: TemplateRef<{ emptyDataTitle?: string }>;
  @ContentChild(TemplateRef) dataTemplate: TemplateRef<any> | null = null;
  @Input() loadable: Loadable<any> = new Loadable<any>({ kind: 'NotRequested' });

  @Input() errorRepeatAction?: () => void;
  @Input() loadingTitle?: string;
  @Input() emptyDataTitle?: string;

  checkIfDataExists(): boolean {
    try {
      if (this.loadable.data instanceof Array) {
        return this.loadable.data.length != 0;
      }
      if (this.loadable.data.data) {
        return this.loadable.data.data.length != 0;
      }
      this.loadable.data;
      return true;
    } catch {
      return false;
    }
  }

  protected readonly UIStateType = UIStateType;
}
