import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { StorySlideFormOutput } from '../story-slide-form/story-slide-form.component';

@Component({
  selector: 'app-mobile-stories-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent],
  templateUrl: './mobile-stories-preview.component.html',
  styleUrl: './mobile-stories-preview.component.css',
})
export class MobileStoriesPreviewComponent {
  @Input() slide!: StorySlideFormOutput | null;
}
