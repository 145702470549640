import { Injectable } from '@angular/core';
import { AvailableService } from '../../domain/available-service';
import { AvailableServiceApiDto } from '../dto/available-service-api-dto';

@Injectable({ providedIn: 'root' })
export class AvailableServiceMapper {
  constructor() {}

  public toDomain(dto: AvailableServiceApiDto): AvailableService {
    return new AvailableService(
      dto.id,
      dto.service_id,
      dto.manual_deactivation,
      dto.service_ext_id,
      dto.name,
      dto.description,
      dto.service_type,
    );
  }
}
