<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-banner-form
    [bannerForm]="bannerForm"
    [submitButtonText]="'Добавить'"
    [isSubmitted]="isSubmitted"
    (outputCroppedImage)="onOutputCroppedImage($event)"
    (bannerFormData)="onBannerFormData($event)"
    (onSubmittedEvent)="onSubmittedEvent($event)"
  ></app-banner-form>
</app-page-wrapper>
