import { Component, OnInit } from '@angular/core';
import { AvailableTariff, AvailableTariffTypeEnum } from '../../domain/available-tariff';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AvailableTariffRepository } from '../../data/repositories/AvailableTariffRepository';
import { NgForOf, NgIf, NgFor } from '@angular/common';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { RouterModule } from '@angular/router';
import { PaginationTableViewComponent } from '../../../../components/pagination-table-view/pagination-table-view.component';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationResponse as PR } from '../../../../../core/dashboard/domain/pagination-response';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { ICreateAvailableTariff } from '../../data/interfaces/icreate-available-tariff';
import { AutocompleteSearchInputComponent } from '../../../../components/common/inputs/autocomplete-search-input/autocomplete-search-input.component';
import { BillingTariff } from '../../../../../entity/billing-tariff/domain/billing-tariff';
import { DataStatus } from '../../../../../core/network/data.status';
import { BillingTariffRepository } from '../../../../../entity/billing-tariff/data/repositories/BillingTariffRepository';
import { BillingTariffListItemComponent } from '../../../../components/common/inputs/list-item-components/billing-tariff-list-item/billing-tariff-list-item.component';

@Component({
  selector: 'app-available-tariffs',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    PaginationTableViewComponent,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    AddButtonComponent,
    BtnListComponent,
    RouterModule,
    PaginationViewComponent,
    AutocompleteSearchInputComponent,
  ],
  templateUrl: './available-tariffs.component.html',
  styleUrl: './available-tariffs.component.css',
})
export class AvailableTariffComponent implements OnInit {
  public pageAvailableTariffs: Loadable<PaginationResponse<AvailableTariff>> = new Loadable<
    PaginationResponse<AvailableTariff>
  >();
  pageTitle: string = 'Список доступных тарифов';
  pageSubtitle: string = 'Тарифы и услуги';
  public perPage: number = 5;
  public pageNumber: number = 1;
  public currentPageNumber: number = 1;
  public availableTariffType = AvailableTariffTypeEnum;
  public keys = Object.keys;
  private newTariff!: ICreateAvailableTariff;
  private searchName!: string;
  public privateHousingFlag: boolean = false;
  public tariffs: Loadable<PR<BillingTariff>> | null = null;
  public currentFoundTariffName: string = '';
  public listItem = BillingTariffListItemComponent;

  constructor(
    private AvailableTariffRepo: AvailableTariffRepository,
    private tariffInfoRepository: BillingTariffRepository,
  ) {}

  ngOnInit(): void {
    this.getAllAvailableTariffsPost(this.perPage, this.pageNumber).finally();
  }

  async getAllAvailableTariffsPost(
    per_page: number,
    page_number: number,
    name: string | null = null,
  ): Promise<void> {
    if (this.pageAvailableTariffs.status != UIStateType.Loading) {
      this.pageAvailableTariffs = new Loadable<PaginationResponse<AvailableTariff>>({
        kind: 'Loading',
      });
      await this.AvailableTariffRepo.findAllPost(per_page, page_number, name ? name : null).then(
        (data) => {
          this.pageAvailableTariffs = Loadable.getFromDataStatus(data);
        },
      );
    }
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.currentPageNumber = pageNumber;
    if (this.searchName) {
      this.getAllAvailableTariffsPost(this.perPage, this.pageNumber, this.searchName).finally();
    } else {
      this.getAllAvailableTariffsPost(this.perPage, this.pageNumber).finally();
    }
  }

  getTariffType(type: string) {
    return AvailableTariffTypeEnum[type as keyof typeof AvailableTariffTypeEnum];
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.AvailableTariffRepo.delete(id);
      this.getAllAvailableTariffsPost(this.perPage, this.pageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  onFoundTariffDef($event: BillingTariff) {
    this.currentFoundTariffName = $event.tariffInfo.name;

    let includedServices = $event.macroServices.map((data) => data.macroServiceInfo.type);

    this.newTariff = {
      billing_tariff_id: $event.tariffInfo.tariffId,
      name: $event.tariffInfo.name,
      description: $event.tariffInfo.description,
      cost: $event.tariffInfo.cost,
      speed_day: $event.tariffInfo.speedDayIn,
      speed_night: $event.tariffInfo.speedNightIn,
      is_for_private_housing: this.privateHousingFlag,
      included_services: includedServices,
    };
  }

  async createTariff() {
    this.newTariff.is_for_private_housing = this.privateHousingFlag;
    await this.AvailableTariffRepo.create(this.newTariff);
    this.privateHousingFlag = false;
    this.getAllAvailableTariffsPost(this.perPage, this.pageNumber).finally();
  }

  async searchAvailableTariff($event: any) {
    this.searchName = $event.target.value;

    if (this.searchName !== '') {
      await this.getAllAvailableTariffsPost(this.perPage, 1, this.searchName);
    } else {
      await this.getAllAvailableTariffsPost(this.perPage, this.pageNumber);
    }
  }

  changePrivateHousingFlag() {
    this.privateHousingFlag = !this.privateHousingFlag;
  }

  parseIncludedServices(services: string[]) {
    let result: string[] = [];

    for (let service of services) {
      if (service == '24htv') {
        result.push('24часаТВ');
      } else {
        result.push(AvailableTariffTypeEnum[service as keyof typeof AvailableTariffTypeEnum]);
      }
    }

    return result.join(', ');
  }

  async onQueryString($event: string) {
    let query = $event;

    this.tariffs = new Loadable({ kind: 'Loading' });
    try {
      let result: DataStatus<PR<BillingTariff>> = await this.tariffInfoRepository.findAll(
        'name',
        query,
        1,
        15,
      );
      this.tariffs = Loadable.getFromDataStatus(result);
    } catch (error: any) {
      console.log(error);
    }
  }

  protected readonly UIStateType = UIStateType;
}
