import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DataStatus, DataStatusSuccess } from '../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { Attachment, AttachmentType } from '../../domain/attachment';
import { AttachmentApiDto } from '../attachment-api-dto';
import { AttachmentMapper } from '../mappers/attachment-mapper';

@Injectable({ providedIn: 'root' })
export class AttachmentRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private attachmentMapper: AttachmentMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(attachment: Blob, type?: AttachmentType): Promise<DataStatus<Attachment>> {
    let headers: HttpHeaders = new HttpHeaders();
    let formData = new FormData();
    formData.append('files', attachment);

    headers = headers.append('enctype', 'multipart/form-data');

    let response: DataStatus<AttachmentApiDto[]> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/attachments?attachment_type=${type?.valueOf()}`,
      formData,
      headers,
    );

    return response
      .map((data) => {
        return data[0];
      })
      .map((t) => {
        return this.attachmentMapper.toDomain(t);
      });
  }

  async createOnlyResult(attachment: Blob, type?: AttachmentType): Promise<Attachment> {
    let headers: HttpHeaders = new HttpHeaders();
    let formData = new FormData();
    formData.append('files', attachment);

    headers = headers.append('enctype', 'multipart/form-data');

    let response: DataStatus<AttachmentApiDto[]> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/attachments?attachment_type=${type?.valueOf()}`,
      formData,
      headers,
    );

    if (response instanceof DataStatusSuccess) {
      let successResponse = response as DataStatusSuccess<AttachmentApiDto[]>;

      return successResponse.data.map((data) => {
        return this.attachmentMapper.toDomain(data);
      })[0];
    }
    throw Error('attachment create error');
  }
}
