import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { CommonModule } from '@angular/common';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import { Router } from '@angular/router';

import { ICreateServiceExtensionRequest } from '../../../data/dto/icreate-service-ext-request';
import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../../entity/attachments/domain/attachment';
import { ServiceExtensionFormComponent } from '../../components/service-extension-form/service-extension-form.component';
import { IDangerModalInitParams } from '../../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-service-ext-add-page',
  standalone: true,
  templateUrl: './service-ext-add-page.component.html',
  styleUrl: './service-ext-add-page.component.css',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    ServiceExtensionFormComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtAddPageComponent {
  croppedIcon?: Blob | null;
  croppedCover?: Blob | null;
  croppedBanner?: Blob | null;
  isSubmitted: boolean = false;

  form: FormGroup;

  private banner?: Loadable<Attachment[]>;
  private cover?: Loadable<Attachment[]>;
  private icon?: Loadable<Attachment[]>;

  pageTitle: string = 'Расширение услуг';

  pageSubtitle: string = 'Тарифы и услуги';

  constructor(
    private serviceExtRepository: ServicesExtRepository,
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private AttachmentRepo: AttachmentRepository,
    private modalService: ModalService,
  ) {
    this.form = this.formBuilder.group({
      title: new FormControl(undefined, [Validators.required]),
      description: new FormControl(undefined, [Validators.required]),
      service: new FormControl(undefined, [Validators.required]),
      service_type: new FormControl('', [Validators.required]),
    });
  }

  onBannerCropped($event: Blob | null): void {
    this.croppedBanner = $event;
  }

  onCoverCropped($event: Blob | null): void {
    this.croppedCover = $event;
  }

  onIconCropped($event: Blob | null): void {
    this.croppedIcon = $event;
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.croppedBanner) {
        await this.AttachmentRepo.create(this.croppedBanner).then((data) => {
          this.banner = Loadable.getFromDataStatus(data);
        });
      }

      if (this.croppedCover) {
        await this.AttachmentRepo.create(this.croppedCover).then((data) => {
          this.cover = Loadable.getFromDataStatus(data);
        });
      }

      if (this.croppedIcon) {
        await this.AttachmentRepo.create(this.croppedIcon).then((data) => {
          this.icon = Loadable.getFromDataStatus(data);
        });
      }

      const request: ICreateServiceExtensionRequest = {
        banner_id: this.banner ? this.banner.data[0].id : null,
        icon_id: this.icon ? this.icon.data[0].id : null,
        cover_id: this.cover ? this.cover.data[0].id : null,
        title: this.form.value['title'],
        description: this.form.value['description'],
        service_id: this.form.value['service'].serviceId,
        service_type: this.form.value['service_type'],
        attributes: this.form.value['attributes'],
      };

      await this.serviceExtRepository.create(request);
      this.router.navigate(['/service-ext-list'], { state: { serviceExtCreated: true } });
    } catch (error) {
      this.isSubmitted = false;
      const dangerInitParams = new IDangerModalInitParams(
        'Пожалуйста, повторите попытку',
        () => {},
        () => {},
        'Ошибка при создании расширения услуги',
      );
      this.modalService.createDangerModal(dangerInitParams);
    }
  }

  onSubmittedEvent($event: boolean) {
    this.isSubmitted = $event;
  }

  onFormSubmit(form: FormGroup): void {
    this.form = form;
    this.onSubmit().finally();
  }
}
