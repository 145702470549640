<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Создать баннер'" [routerLink]="['create-banner']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="row mt-3">
    <div class="col-md-6">
      <app-loadable-wrapper [loadable]="pageActiveBanners" loadingTitle="Загрузка данных...">
        <ng-template>
          <app-pagination-view
            [data]="pageActiveBanners"
            [perPage]="activeBannersPerPage"
            [pageNumber]="activeBannersPageNumber"
            [currentPageNumber]="activeBannersCurrentPageNumber"
            (changePageEvent)="changeActiveBannersPage($event)"
          >
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Активные баннеры</h3>
              </div>
              <div class="card-body">
                <div class="list-group">
                  <div
                    *ngFor="let item of pageActiveBanners.data.data"
                    class="list-group-item"
                    style="margin-bottom: 12px; padding: 16px, 20px, 16px, 20px"
                  >
                    <div class="row">
                      <div class="col-auto">
                        <span class="banner-image" [style]="item.getBackgroundImage()"></span>
                      </div>
                      <div class="col text-truncate">
                        <div class="text-body d-block title">{{ item.title }}</div>
                        <div class="link">
                          {{ item.deeplink?.link }}
                        </div>
                        <div class="button-text">
                          {{ item.actionButton?.body }}
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="btn-list flex-nowrap">
                          <div class="dropdown">
                            <button
                              class="btn btn-link"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="ti ti-dots-vertical"></i>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-demo"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                class="dropdown-item"
                                (click)="deactivateBanner(item.id)"
                              >
                                Сделать неактивным
                              </button>
                              <a
                                class="dropdown-item"
                                [routerLink]="['update-banner']"
                                [state]="item"
                                >Редактировать</a
                              >
                              <button
                                type="button"
                                class="dropdown-item"
                                (click)="onDelete(item.id).finally()"
                              >
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 8px">
                      <div class="description">{{ item.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-pagination-view>
        </ng-template>
      </app-loadable-wrapper>
    </div>

    <div class="col-md-6">
      <app-loadable-wrapper [loadable]="pageHiddenBanners" loadingTitle="Загрузка данных...">
        <ng-template>
          <app-pagination-view
            [data]="pageHiddenBanners"
            [perPage]="hiddenBannersPerPage"
            [pageNumber]="hiddenBannersPageNumber"
            [currentPageNumber]="hiddenBannersCurrentPageNumber"
            (changePageEvent)="changeHiddenBannersPage($event)"
          >
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Неактивные баннеры</h3>
              </div>
              <div class="card-body">
                <div class="list-group">
                  <div
                    *ngFor="let item of pageHiddenBanners.data.data"
                    class="list-group-item"
                    style="margin-bottom: 12px; padding: 16px, 20px, 16px, 20px"
                  >
                    <div class="row">
                      <div class="col-auto">
                        <span class="banner-image" [style]="item.getBackgroundImage()"></span>
                      </div>
                      <div class="col text-truncate">
                        <div class="text-body d-block title">{{ item.title }}</div>
                        <div class="link">
                          {{ item.deeplink?.link }}
                        </div>
                        <div class="button-text">
                          {{ item.actionButton?.body }}
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="btn-list flex-nowrap">
                          <div class="dropdown">
                            <button
                              class="btn btn-link"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="ti ti-dots-vertical"></i>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-demo"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                class="dropdown-item"
                                (click)="activateBanner(item.id)"
                              >
                                Сделать активным
                              </button>
                              <a
                                class="dropdown-item"
                                [routerLink]="['update-banner']"
                                [state]="item"
                                >Редактировать</a
                              >
                              <button
                                type="button"
                                class="dropdown-item"
                                (click)="onDelete(item.id).finally()"
                              >
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 8px">
                      <div class="description">{{ item.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-pagination-view>
        </ng-template>
      </app-loadable-wrapper>
    </div>
  </div>
</app-page-wrapper>
