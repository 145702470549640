import { Injectable } from '@angular/core';
import { OAuthAccessToken } from '../../../domain/entities/oauth-access-token';
import { OAuthTokenApiDto } from './oauth-token-api-dto';

@Injectable({ providedIn: 'root' })
export class OAuthTokenMapper {
  public toDomain(dto: OAuthTokenApiDto): OAuthAccessToken {
    return new OAuthAccessToken(
      dto.access_token,
      dto.refresh_token,
      dto.expires_in,
      new Date(dto.expires_at),
      dto.scope,
      dto.token_type,
    );
  }
}
