import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import EditorJS, { OutputData, ToolConstructable } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { ExpandableData } from './custom-blocks/collapsed-content';

@Component({
  selector: 'app-editor-js-field',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './editor-js-field.component.html',
  styleUrl: './editor-js-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditorJsFieldComponent,
    },
  ],
})
export class EditorJsFieldComponent implements ControlValueAccessor, OnInit {
  editor!: EditorJS;

  private static id: number = 0;
  componentId = `editor-js-field-${EditorJsFieldComponent.id++}`;

  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';

  constructor(private attachmentRepo: AttachmentRepository) {
    this.doUpload = this.doUpload.bind(this);
  }

  private _data: OutputData | undefined;
  private onChange?: (value: OutputData | undefined) => void;

  public get data(): OutputData | undefined {
    return this._data;
  }

  public set data(newValue: OutputData | undefined) {
    this._data = newValue;
    if (this.onChange) {
      this.onChange(newValue);
    }
  }

  ngOnInit() {
    let changeEvent = () => {
      this.editor.save().then((data) => {
        console.log('save');
        this.data = data;
        console.log(data);
      });
    };

    this.editor = new EditorJS({
      holder: this.componentId,
      hideToolbar: false,
      placeholder: this.placeholder,
      minHeight: 50,
      tools: {
        header: {
          class: Header as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
        list: {
          class: List as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
        image: {
          class: ImageTool as unknown as ToolConstructable,
          config: {
            uploader: {
              uploadByFile: this.doUpload,
            },
          },
          inlineToolbar: ['link', 'bold'],
        },
        expandableData: {
          class: ExpandableData as unknown as ToolConstructable,
        },
      },

      onChange(api, event) {
        changeEvent();
      },
    });
  }
  registerOnChange(fn: (value: OutputData | undefined) => void): void {
    this.onChange = fn;
  }
  async doUpload(file: File) {
    console.log('attachmentValue!');

    let attachmentValue: Loadable<Attachment>;

    await this.attachmentRepo
      .create(file)
      .then((data) => {
        attachmentValue = Loadable.getFromDataStatus(data);
      })
      .catch(() => {
        attachmentValue = new Loadable<Attachment>({ kind: 'Error', message: 'Ошибка загрузки' });
      });
    console.log('attachmentValue!');
    console.log(attachmentValue!);
    return {
      success: attachmentValue!.status == UIStateType.Success ? 1 : 0,
      file: {
        url: attachmentValue!.data.content.attributes.contentPath,
      },
    };
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: OutputData | undefined): void {
    if (obj) {
      this.editor.render(obj).finally();
    }
    this.data = obj;
  }
}
