import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';

@Component({
  selector: 'app-mobile-stories-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent],
  templateUrl: './mobile-stories-preview.component.html',
  styleUrl: './mobile-stories-preview.component.css',
})
export class MobileStoriesPreviewComponent {
  @Input() public header: string = '';
  @Input() public body: string = '';
  @Input() public actionButtons: Array<{ [id: string]: string }> = [];
  @Input() public slide: string = '';
}
