import { NgForOf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { keys } from 'lodash';
import { Task } from '../../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../../components/common/buttons/add-button/add-button.component';
import { LoadableWrapperComponent } from '../../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../../components/pagination-view/pagination-view.component';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import { ServiceExtension } from '../../../domain/entities/service-extension';
import { ServiceExtensionType } from '../../../domain/entities/service-type';

@Component({
  selector: 'app-service-ext-list',
  standalone: true,
  templateUrl: './service-ext-list-page.component.html',
  styleUrl: './service-ext-list-page.component.css',
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    AddButtonComponent,
    RouterLink,
    LoadableWrapperComponent,
    PaginationViewComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtListPageComponent implements OnInit, PaginationViewDelegate {
  public pageServiceExtensions: Loadable<PaginationResponse<ServiceExtension>> = new Loadable<
    PaginationResponse<ServiceExtension>
  >();
  public countServiceExtensions: Loadable<PaginationResponse<ServiceExtension>> = new Loadable<
    PaginationResponse<ServiceExtension>
  >();
  pageTitle: string = 'Список расширений услуг';
  pageSubtitle: string = 'Услуги';
  countDict: { [id: string]: number } = {};
  activeType: string = 'all';

  pageNumber: number = 1;
  perPage: number = 5;

  constructor(
    private serviceExtensionsRepo: ServicesExtRepository,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.getAllServiceExtensions(this.perPage, this.pageNumber).finally();
    this.getServiceExtCountByType().finally();
  }

  async getAllServiceExtensions(
    perPage: number,
    pageNumber: number,
    activeType?: string,
  ): Promise<void> {
    if (this.pageServiceExtensions.status != UIStateType.Loading) {
      let body: { [id: string]: any } = {
        'filters': [
          {
            'column': 'deleted_at',
            'operator': 'eq',
            'value': null,
          },
        ],
      };

      if (!activeType) {
        await this.serviceExtensionsRepo.findAllPost(body, pageNumber, perPage).then((data) => {
          this.pageServiceExtensions = Loadable.getFromDataStatus(data);
        });
      } else {
        body['filters'].push({
          'column': 'service_type',
          'operator': 'eq',
          'value': activeType,
        });

        await this.serviceExtensionsRepo.findAllPost(body, pageNumber, perPage).then((data) => {
          this.pageServiceExtensions = Loadable.getFromDataStatus(data);
        });
      }
    }
  }

  didChangePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    if (this.activeType && this.activeType == 'all') {
      this.getAllServiceExtensions(this.perPage, this.pageNumber).finally();
    } else {
      this.getAllServiceExtensions(this.perPage, this.pageNumber, this.activeType).finally();
    }
  }

  getServiceExtType(type: string): string {
    let typeEnum = <ServiceExtensionType>(
      ServiceExtensionType[type.toUpperCase() as keyof typeof ServiceExtensionType]
    );

    switch (typeEnum) {
      case ServiceExtensionType.CATV_PACKET:
        return 'Пакет КТВ';
      case ServiceExtensionType.GENERAL:
        return 'Прочие услуги';
      case ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA:
        return 'Онлайн-кинотеатр ИТВ';
      case ServiceExtensionType.SMOTRESHKA_TV_PACKET:
        return 'Пакет каналов ИТВ';
    }
  }

  async getServiceExtCountByType(): Promise<void> {
    let body: { [id: string]: any } = {
      'filters': [
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
    };

    let promises: Promise<any>[] = [];

    for (let type of Object.keys(ServiceExtensionType)) {
      let body: { [id: string]: any } = {
        'filters': [
          {
            'column': 'deleted_at',
            'operator': 'eq',
            'value': null,
          },
          {
            'column': 'service_type',
            'operator': 'eq',
            'value': type,
          },
        ],
      };

      promises.push(
        new Task(async () => {
          await this.serviceExtensionsRepo.findAllPost(body, 1, 1).then((data) => {
            this.countServiceExtensions = Loadable.getFromDataStatus(data);
          });

          this.countDict[type] = this.countServiceExtensions.data.count;
        }).toPromise(),
      );
    }

    promises.push(
      new Task(async () => {
        await this.serviceExtensionsRepo.findAllPost(body, 1, 1).then((data) => {
          this.countServiceExtensions = Loadable.getFromDataStatus(data);
        });
      }).toPromise(),
    );

    await Promise.all(promises);

    this.countDict['all'] = this.countServiceExtensions.data.count;
  }

  async showServiceExtByType(type?: string): Promise<void> {
    if (type) {
      this.activeType = type;
    } else {
      this.activeType = 'all';
    }

    this.pageNumber = 1;

    this.getAllServiceExtensions(this.perPage, this.pageNumber, type).finally();
  }

  async deleteServiceExt(serviceId: number) {
    await this.serviceExtensionsRepo.delete(serviceId);
    await this.getAllServiceExtensions(this.perPage, this.pageNumber);
  }

  async searchServiceExt($event: any) {
    let searchName = $event.target.value;

    if (searchName !== '') {
      // await this.serviceExtensionsRepo
      //   .findAllByLikeSearchCriteria('title', searchName, 1, this.perPage)
      //   .then((data) => {
      //     this.pageServiceExtensions = Loadable.getFromDataStatus(data);
      //   });
    } else {
      await this.getAllServiceExtensions(this.perPage, this.pageNumber);
    }
  }

  protected readonly ServiceExtensionType = ServiceExtensionType;
  protected readonly keys = keys;
}
