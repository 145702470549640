import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobilePreviewComponent } from '../../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { CATVPocketFormOutput } from '../../service-extension-attributes/service-extension-attributes.component';
import { ServiceExtForm } from '../../service-extension-form/service-extension-form.component';

@Component({
  selector: 'app-mobile-catv-packet-preview',
  standalone: true,
  imports: [MobilePreviewComponent, PreviewBoxComponent, NgIf],
  templateUrl: './mobile-catv-packet-preview.component.html',
  styleUrl: './mobile-catv-packet-preview.component.css',
})
export class MobileCatvPacketPreviewComponent {
  @Input() form?: FormGroup<ServiceExtForm>;

  get title(): string | undefined {
    return this.form?.getRawValue().title.length == 0
      ? this.form?.getRawValue().service?.name
      : this.form?.getRawValue().title;
  }

  get description(): string | undefined {
    return this.form?.getRawValue().description.length == 0
      ? this.form?.getRawValue().service?.description
      : this.form?.getRawValue().description;
  }

  get cost(): string | undefined {
    return this.form?.getRawValue().service?.cost.toFixed(2);
  }

  get numbersOfChannel(): number | undefined {
    return this.catvAttributes?.numberOfChannels;
  }

  private get catvAttributes(): CATVPocketFormOutput | undefined {
    if (this.form?.getRawValue().attributes instanceof CATVPocketFormOutput) {
      return this.form?.getRawValue().attributes as CATVPocketFormOutput;
    }
    return undefined;
  }
}
