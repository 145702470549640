import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';

@Component({
  selector: 'app-mobile-banner-preview',
  standalone: true,
  imports: [
    NgFor,
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    ImagePreviewWithButtonComponent,
  ],
  templateUrl: './mobile-banner-preview.component.html',
  styleUrl: './mobile-banner-preview.component.css',
})
export class MobileBannerPreviewComponent {
  @Input() public actionButtonText: string = '';
  @Input() public banner: string = '';
}
