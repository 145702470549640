import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { CommonModule } from '@angular/common';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import { Router } from '@angular/router';

import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../../entity/attachments/domain/attachment';
import { ServiceExtensionFormComponent } from '../../components/service-extension-form/service-extension-form.component';
import { ServiceDefRepository } from '../../../data/repositories/service-def-repository';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';
import { IDangerModalInitParams } from '../../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';

@Component({
  selector: 'app-service-ext-add-page',
  standalone: true,
  templateUrl: './service-ext-update-page.component.html',
  styleUrl: './service-ext-update-page.component.css',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    ServiceExtensionFormComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtUpdatePageComponent implements OnInit {
  croppedIcon?: Blob | null;
  croppedCover?: Blob | null;
  croppedBanner?: Blob | null;

  form: FormGroup;

  private banner?: Loadable<Attachment[]>;
  private cover?: Loadable<Attachment[]>;
  private icon?: Loadable<Attachment[]>;

  currentServiceExt!: any;
  additionalFullDesc: FormArray<any> = new FormArray(new Array<any>());
  currentServiceDef!: any;
  currentBillingServiceName: string = '';
  iconPreview!: string;
  bannerPreview!: string;
  coverPreview!: string;
  isSubmitted: boolean = false;

  pageTitle: string = 'Расширение услуг';

  pageSubtitle: string = 'Тарифы и услуги';

  constructor(
    private serviceExtRepository: ServicesExtRepository,
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private AttachmentRepo: AttachmentRepository,
    private serviceDefRepo: ServiceDefRepository,
    private modalService: ModalService,
  ) {
    this.form = this.formBuilder.group({
      title: new FormControl(undefined),
      description: new FormControl(undefined),
      service: new FormControl(undefined),
      service_type: new FormControl(undefined),
    });
  }

  async ngOnInit(): Promise<void> {
    this.currentServiceExt = history.state;

    this.currentServiceDef = await this.serviceDefRepo.getByServiceId(
      this.currentServiceExt.serviceId,
    );
    this.currentBillingServiceName = this.currentServiceDef.data.name;

    this.iconPreview = this.currentServiceExt.icon?.content.attributes.content_path;
    this.bannerPreview = this.currentServiceExt.banner?.content.attributes.content_path;
    this.coverPreview = this.currentServiceExt.cover?.content.attributes.content_path;

    this.form.patchValue(this.currentServiceExt);
  }

  onBannerCropped($event: Blob | null): void {
    this.croppedBanner = $event;
  }

  onCoverCropped($event: Blob | null): void {
    this.croppedCover = $event;
  }

  onIconCropped($event: Blob | null): void {
    this.croppedIcon = $event;
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.croppedBanner) {
        await this.AttachmentRepo.create(this.croppedBanner).then((data) => {
          this.banner = Loadable.getFromDataStatus(data);
        });
      }

      if (this.croppedCover) {
        await this.AttachmentRepo.create(this.croppedCover).then((data) => {
          this.cover = Loadable.getFromDataStatus(data);
        });
      }

      if (this.croppedIcon) {
        await this.AttachmentRepo.create(this.croppedIcon).then((data) => {
          this.icon = Loadable.getFromDataStatus(data);
        });
      }

      let request: { [id: string]: any } = {
        banner_id: this.banner ? this.banner.data[0].id : null,
        icon_id: this.icon ? this.icon.data[0].id : null,
        cover_id: this.cover ? this.cover.data[0].id : null,
        title: this.form.value['title'] ? this.form.value['title'] : null,
        description: this.form.value['description'] ? this.form.value['description'] : null,
        service_id: this.form.value['service'] ? this.form.value['service'].serviceId : null,
        service_type: this.form.value['service_type'] ? this.form.value['service_type'] : null,
        attributes: this.form.value['attributes'] ? this.form.value['attributes'] : null,
      };

      Object.keys(request).forEach((key) => request[key] == null && delete request[key]);

      await this.serviceExtRepository.update(this.currentServiceExt.id, request);
      this.router.navigate(['/service-ext-list'], { state: { serviceExtUpdated: true } });
    } catch (error) {
      this.isSubmitted = false;
      const dangerInitParams = new IDangerModalInitParams(
        'Пожалуйста, повторите попытку',
        () => {},
        () => {},
        'Ошибка при создании расширения услуги',
      );
      this.modalService.createDangerModal(dangerInitParams);
    }
  }

  onSubmittedEvent($event: boolean) {
    this.isSubmitted = $event;
  }

  onFormSubmit(form: FormGroup): void {
    this.form = form;
    this.onSubmit().finally();
  }
}
