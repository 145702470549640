import { Component, OnInit } from '@angular/core';
import { NavItemComponent } from '../components/common/nav-item/nav-item.component';
import { DropdownItemComponent } from '../components/common/dropdown-item/dropdown-item.component';
import { DropdownMenuComponent } from '../components/common/dropdown-menu/dropdown-menu.component';
import { FooterComponent } from '../components/common/footer/footer.component';
import { Router, RouterOutlet } from '@angular/router';
import { PageWrapperComponent } from '../components/common/page-wrapper/page-wrapper.component';
import { PageHeaderComponent } from '../components/common/page-header/page-header.component';
import { HomePageComponent } from './home/home-page.component';
import { AdminUserRepository } from '../../entity/admin-user/data/repositories/AdminUserRepository';
import { Loadable, UIStateType } from '../../core/utils/wrappers/loadable';
import { AdminUser } from '../../entity/admin-user/domain/admin-user';
import { LoadableWrapperComponent } from '../components/common/loadable-wrapper/loadable-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    NavItemComponent,
    DropdownItemComponent,
    DropdownMenuComponent,
    FooterComponent,
    RouterOutlet,
    PageWrapperComponent,
    PageHeaderComponent,
    HomePageComponent,
    NgIf,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css',
})
export class MainComponent implements OnInit {
  constructor(
    private adminUserRepo: AdminUserRepository,
    private router: Router,
  ) {}
  public adminUser: Loadable<AdminUser> = new Loadable<AdminUser>();

  async getAdminUser() {
    this.adminUser = new Loadable<AdminUser>({ kind: 'Loading' });
    await this.adminUserRepo
      .get()
      .then((data) => (this.adminUser = Loadable.getFromDataStatus(data)));
  }

  ngOnInit(): void {
    this.getAdminUser().finally();
  }

  logout() {
    console.log('asdas');
    this.router.navigate(['auth/logout']);
  }

  protected readonly UIStateType = UIStateType;
}
