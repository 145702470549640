import { CommonModule } from '@angular/common';
import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';

import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/attachment-repository';
import { ModalType } from '../../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import {
  ServiceExtension,
  ServiceExtensionCreateRequest,
} from '../../../domain/entities/service-extension';
import {
  CATVPacketCreateAttributes,
  GeneralCreateAttributes,
  ServiceExtensionCreateAttributes,
  SmotreshkaCinemaCreateAttributes,
  SmotreshkaPacketCreateAttributes,
} from '../../../domain/entities/service-extension-create';
import { ServiceExtensionType } from '../../../domain/entities/service-type';
import {
  CATVPocketFormOutput,
  GeneralAttributesFormOutput,
  SmotreshkaCinemaAttributesFormOutput,
  SmotreshkaPocketFormOutput,
} from '../../components/service-extension-attributes/service-extension-attributes.component';
import {
  ServiceExtensionFormComponent,
  ServiceExtensionFormComponentDelegate,
  ServiceExtForm,
} from '../../components/service-extension-form/service-extension-form.component';

@Component({
  selector: 'app-service-ext-add-page',
  standalone: true,
  templateUrl: './service-ext-add-page.component.html',
  styleUrl: './service-ext-add-page.component.css',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    ServiceExtensionFormComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtAddPageComponent implements ServiceExtensionFormComponentDelegate, OnInit {
  public createStatus$: BehaviorSubject<Loadable<ServiceExtension>> = new BehaviorSubject(
    Loadable.notRequested(),
  );

  pageTitle: string = 'Расширение услуг';
  pageSubtitle: string = 'Услуги';

  constructor(
    private serviceExtRepository: ServicesExtRepository,
    private readonly router: Router,
    private attachmentRepo: AttachmentRepository,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.createStatus$.subscribe((value) => {
      switch (value.status) {
        case UIStateType.Success:
          this.router.navigate(['abonent-services']).then(async () => {
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: `Расширение для услуги ${value.data.title} успешно созданно.`,
              acceptButtonAction: () => {},
            });
          });
          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: value.message!,
            acceptButtonAction: () => {},
          });
          break;
      }
    });
  }

  didSubmitForm(form: FormGroup<ServiceExtForm>) {
    if (form.valid) {
      this.createStatus$.next(Loadable.loading());

      this.mapToRequest(form).then((value) => {
        this.serviceExtRepository.create(value).then((value) => {
          console.log(value);
          this.createStatus$.next(Loadable.getFromDataStatus(value));
        });
      });
    }
  }

  private async mapToRequest(
    formValue: FormGroup<ServiceExtForm>,
  ): Promise<ServiceExtensionCreateRequest> {
    let attributes!: ServiceExtensionCreateAttributes;

    switch (formValue.getRawValue().serviceType) {
      case ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA:
        let cinemaAttributes = formValue.getRawValue()
          .attributes as SmotreshkaCinemaAttributesFormOutput;

        let attachments = await Promise.all([
          this.attachmentRepo.createOnlyResult(cinemaAttributes.icon.croppedData!),
          this.attachmentRepo.createOnlyResult(cinemaAttributes.banner.croppedData!),
        ]).then(([icon, banner]) => {
          return { icon: icon, banner: banner };
        });

        attributes = new SmotreshkaCinemaCreateAttributes(
          attachments.icon.id,
          attachments.banner.id,
        );
        break;
      case ServiceExtensionType.SMOTRESHKA_TV_PACKET:
        let pocketAttributesForm = formValue.getRawValue().attributes as SmotreshkaPocketFormOutput;

        let attachment = await this.attachmentRepo.createOnlyResult(
          pocketAttributesForm.cover.croppedData!,
        );

        attributes = new SmotreshkaPacketCreateAttributes(null, null, attachment.id);

        break;

      case ServiceExtensionType.CATV_PACKET:
        let catvPocketFormOutput = formValue.getRawValue().attributes as CATVPocketFormOutput;
        attributes = new CATVPacketCreateAttributes(catvPocketFormOutput.numberOfChannels);

        break;

      case ServiceExtensionType.GENERAL:
        let generalAttributesFormOutput = formValue.getRawValue()
          .attributes as GeneralAttributesFormOutput;
        attributes = new GeneralCreateAttributes(generalAttributesFormOutput.text);

        break;
    }

    return new ServiceExtensionCreateRequest(
      formValue.getRawValue().title,
      formValue.getRawValue().description,
      formValue.getRawValue().service!.serviceId,
      formValue.getRawValue().serviceType!,
      attributes,
    );
  }
}
