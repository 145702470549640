import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  firstValueFrom,
  from,
  lastValueFrom,
  Observable,
  retry,
  throwError,
  timer,
} from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/ienvironment';
import { OAuthAccessTokenLocal } from '../../localData/oauth-access-token-local';
import { OAuthClient } from '../../../features/auth/infrastructure/network/oauth-client';

@Injectable()
export class OAuthApplyTokenInterceptor implements HttpInterceptor {
  private config: IEnvironment;

  constructor(
    @Inject(APP_CONFIG) config: IEnvironment,
    private accessToken: OAuthAccessTokenLocal,
    private oauthClient: OAuthClient,
  ) {
    this.config = config;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.accessToken.accessToken;
    req = req.clone({
      headers: token ? req.headers.set('Authorization', `Bearer ${token}`) : req.headers,
    });
    return next.handle(req).pipe(
      retry({
        count: 3,
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if (error.status === 500) {
            return timer(1000);
          }
          throw error;
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return from(this.handleNotAuthError(req, next));
        }
        return throwError(() => new Error(error.message));
      }),
    );
  }

  async handleNotAuthError(req: HttpRequest<any>, next: HttpHandler) {
    try {
      await this.oauthClient.refreshToken();
    } catch (error) {
      this.oauthClient.redirectToAuthPage();
    }

    const token: string | null = this.accessToken.accessToken;
    req = req.clone({
      headers: token ? req.headers.set('Authorization', `Bearer ${token}`) : req.headers,
    });
    return await lastValueFrom(next.handle(req));
  }
}
