import { HttpClientWrapper } from '../../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { DataStatus, DataStatusSuccess } from '../../../../../../core/network/data.status';
import { APP_CONFIG } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/ienvironment';
import { OfficeBusinessHoursMapper } from '../mappers/orion-office-business-hours';
import { OfficeBusinessHours } from '../../domain/orion-office-business-hours';
import { OfficeBusinessHoursApiDto } from '../orion-office-business-hours-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficeBusinessHoursRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private officeBusinessHoursMapper: OfficeBusinessHoursMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async update(
    updated_hours: any,
    office_id: number | null,
  ): Promise<DataStatus<OfficeBusinessHours>> {
    let response: DataStatus<OfficeBusinessHoursApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_business_hours/${office_id}`,
      updated_hours,
    );
    let successResponse = response as DataStatusSuccess<OfficeBusinessHoursApiDto>;
    return successResponse.map((data) => {
      return this.officeBusinessHoursMapper.toDomain(successResponse.data);
    });
  }

  async create(new_hours: any): Promise<DataStatus<OfficeBusinessHours>> {
    let response: DataStatus<OfficeBusinessHoursApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_business_hours`,
      new_hours,
    );
    let successResponse = response as DataStatusSuccess<OfficeBusinessHoursApiDto>;
    return successResponse.map((data) => {
      return this.officeBusinessHoursMapper.toDomain(successResponse.data);
    });
  }
}
