import { Component } from '@angular/core';
import { IModalComponentComponent } from '../imodal-component/imodal-component.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-danger-modal-component',
  standalone: true,
  imports: [NgIf],
  templateUrl: './danger-modal-component.component.html',
  styleUrl: './danger-modal-component.component.css',
})
export class DangerModalComponentComponent extends IModalComponentComponent {}
