import { AsyncPipe, NgForOf } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [AsyncPipe, NgForOf],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.css',
})
export class BreadcrumbsComponent {
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbService) {
    //
    // router.config.forEach(value => {
    //   console.log(value)
    // })
    //
    // console.log(router.routerState.root)
    // activatedRoute.data.subscribe(value => {
    //   console.log((value as { breadcrumb: {alias: string}}).breadcrumb.alias)
    // })
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;

    console.log(this.breadcrumbs$);
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    // this.router.events.pipe(
    //   // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
    //   filter((event) => event instanceof NavigationEnd)
    // ).subscribe(event => {
    //   // Construct the breadcrumb hierarchy
    //   console.log(this.router.routerState.root.snapshot);
    //   const root = this.router.routerState.root.snapshot;
    //   const breadcrumbs: Breadcrumb[] = [];
    //   this.addBreadcrumb(root, [], breadcrumbs).then();
    //
    //   // Emit the new hierarchy
    //   this._breadcrumbs$.next(breadcrumbs);
    // });
  }

  private async addBreadcrumb(
    route: ActivatedRoute | null,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[],
  ) {
    if (route) {
      console.log(route);
      // Construct the route URL
      const routeUrl = parentUrl.concat((await firstValueFrom(route.url)).map((url) => url.path));

      let value = await firstValueFrom(route.data);

      const breadcrumb = {
        label: this.getLabel(value),
        url: '/' + routeUrl.join('/'),
      };
      breadcrumbs.push(breadcrumb);

      // Add another element for the next route part
      await this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return data['breadcrumb']['alias'];
  }
}
