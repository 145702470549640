import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { StoryRepository } from '../../data/repositories/StoryRepository';
import { Story, StorySlide } from '../../domain/story';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { StorySlideRepository } from '../../data/repositories/StorySlideRepository';
import { Deeplink } from '../../../../../entity/deeplinks/domain/entities/deeplink';
import { DeeplinkRepository } from '../../../../../entity/deeplinks/data/repositories/DeeplinkRepository';
import { ActionButtonRepository } from '../../data/repositories/ActionButtonRepository';
import { MobileStoriesPreviewComponent } from '../mobile-stories-preview/mobile-stories-preview.component';
import { MobileStoryPreviewPreviewComponent } from '../mobile-preview-preview/mobile-story-preview-preview.component';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { IDangerModalInitParams } from '../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';
import { ModalService } from '../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-stories-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    ReactiveFormsModule,
    ImageCropperComponent,
    MobilePreviewComponent,
    DragAndDropFileUploadComponent,
    MobileStoriesPreviewComponent,
    MobileStoryPreviewPreviewComponent,
  ],
  providers: [ModalService],
  templateUrl: './stories-create.component.html',
  styleUrl: './stories-create.component.css',
})
export class StoriesCreateComponent implements OnInit {
  pageTitle: string = 'Добавление истории';
  pageSubtitle: string = 'Истории';
  private story: Loadable<Story> = new Loadable<Story>();
  private preview: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  private slide: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  private storySlide: Loadable<StorySlide> = new Loadable<StorySlide>();
  private deeplink: Loadable<Deeplink> = new Loadable<Deeplink>();
  imageChangedEvent: Event | null = null;
  slideChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  croppedSlide?: Blob | null;
  uploadedVideo: any;
  public header: string = '';
  public body: string = '';
  public annotation: string = '';
  public slidePreview: string = '';
  public imagePreview: string = '';
  public storyForm!: FormGroup;
  public actionButtons: FormArray<any> = new FormArray(new Array<any>());
  public actionButtonText: string = 'Название кнопки';
  isSubmitted: boolean = false;

  constructor(
    private StoryRepo: StoryRepository,
    private StorySlideRepo: StorySlideRepository,
    private AttachmentRepo: AttachmentRepository,
    private DeeplinkRepo: DeeplinkRepository,
    private ActionButtonsRepo: ActionButtonRepository,
    private formBuilder: FormBuilder,
    public router: Router,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.storyForm = this.formBuilder.group({
      name: undefined,
      slide: undefined,
      annotation: undefined,
      header: undefined,
      body: undefined,
      preview: undefined,
      actionButtons: new FormArray([]),
    });
  }

  async onCreateSubmit(): Promise<void> {
    this.isSubmitted = true;
    try {
      await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
        this.preview = Loadable.getFromDataStatus(data);
      });

      if (this.uploadedVideo) {
        await this.AttachmentRepo.create(this.uploadedVideo).then((data) => {
          this.slide = Loadable.getFromDataStatus(data);
        });
      } else {
        await this.AttachmentRepo.create(this.croppedSlide as Blob).then((data) => {
          this.slide = Loadable.getFromDataStatus(data);
        });
      }

      let storyData: { [id: string]: any } = {
        'name': this.storyForm.value['name'],
        'attachment_id': this.preview.data[0].id,
      };

      if (this.storyForm.value['annotation']) {
        storyData['annotation'] = this.storyForm.value['annotation'];
      }

      await this.StoryRepo.create(storyData).then((data) => {
        this.story = Loadable.getFromDataStatus(data);
      });

      let storySlideData: { [id: string]: any } = {
        'attachment_id': this.slide.data[0].id,
        'story_id': this.story.data.id,
      };

      if (this.storyForm.value['header']) {
        storySlideData['header'] = this.storyForm.value['header'];
      }

      if (this.storyForm.value['body']) {
        storySlideData['body'] = this.storyForm.value['body'];
      }

      await this.StorySlideRepo.create(storySlideData).then((data) => {
        this.storySlide = Loadable.getFromDataStatus(data);
      });

      if (this.actionButtons) {
        for (let actionButton of this.actionButtons.value) {
          let deeplinkCreateData = {
            'title': actionButton['buttonName'],
            'link': actionButton['buttonLink'],
            'description': '',
          };

          await this.DeeplinkRepo.create(deeplinkCreateData).then((data) => {
            this.deeplink = Loadable.getFromDataStatus(data);
          });

          let actionButtonCreateData = {
            'deeplink_id': this.deeplink.data.id,
            'slide_id': this.storySlide.data.id,
            'body': actionButton['buttonName'],
          };

          await this.ActionButtonsRepo.create(actionButtonCreateData);
        }
      }

      this.router.navigate(['/stories'], { state: { storyCreated: true } });
    } catch (error: any) {
      this.isSubmitted = false;
      const dangerInitParams = new IDangerModalInitParams(
        'Пожалуйста, повторите попытку',
        () => {},
        () => {},
        'Ошибка при создании истории',
      );
      this.modalService.createDangerModal(dangerInitParams);
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      buttonName: undefined,
      buttonLink: undefined,
    });
  }

  addItem(): void {
    this.actionButtons = this.actionButtonsData;
    this.actionButtons.push(this.createItem());
  }

  get actionButtonsData() {
    return <FormArray>this.storyForm.get('actionButtons');
  }

  slideFileChangeEvent($event: any): void {
    if ($event.target.files[0].type !== 'video/mp4') {
      this.slideChangedEvent = $event;
    } else {
      this.uploadedVideo = $event.target.files[0];
    }
  }

  imageFileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
    };

    if (this.croppedImage) {
      reader.readAsDataURL(this.croppedImage);
    }
  }

  slideCropped(event: ImageCroppedEvent): void {
    this.croppedSlide = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.slidePreview = e.target.result;
    };

    if (this.croppedSlide) {
      reader.readAsDataURL(this.croppedSlide);
    }
  }

  removeSlide(): void {
    this.slideChangedEvent = null;
  }

  removeImage(): void {
    this.imageChangedEvent = null;
  }

  changeHeader($event: any): void {
    this.header = $event.target.value;
  }

  changeBody($event: any): void {
    this.body = $event.target.value;
  }

  changeButtonText($event: any): void {
    this.actionButtonText = $event.target.value;
  }

  removeItem(index: number) {
    this.actionButtons.removeAt(index);
  }

  changeAnnotation($event: any): void {
    this.annotation = $event.target.value;
  }

  protected readonly UIStateType = UIStateType;
}
