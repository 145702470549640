import { Injectable } from '@angular/core';
import { AvailableTariffCreate } from '../../domain/available-tariff-create';
import { AvailableTariffCreateApiDto } from '../dto/available-tariff-create-aip-dto';

@Injectable({ providedIn: 'root' })
export class AvailableTariffCreateMapper {
  constructor() {}

  toData(domain: AvailableTariffCreate): AvailableTariffCreateApiDto {
    return new AvailableTariffCreateApiDto(domain.billingTariffId, domain.isForPrivateHousing);
  }
}
