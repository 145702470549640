import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { StorySlide } from '../../domain/story';
import { StorySlideCreateRequest } from '../../domain/story-slide-create-request';
import { StorySlideApiDto } from '../dto/stories-dto';
import { StorySlideMapper } from '../mappers/story-slide';
import { StorySlideCreateRequestMapper } from '../mappers/story-slide-create-reqeust-mapper';

@Injectable({ providedIn: 'root' })
export class StorySlideRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private storySlideMapper: StorySlideMapper,
    private storySlideCreateRequestMapper: StorySlideCreateRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(request: StorySlideCreateRequest): Promise<DataStatus<StorySlide>> {
    let response: DataStatus<StorySlideApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/story_slides`,
      this.storySlideCreateRequestMapper.toData(request),
    );

    return response.map((data) => {
      return this.storySlideMapper.toDomain(data);
    });
  }

  async update(request: StorySlideCreateRequest, slideId: number): Promise<DataStatus<StorySlide>> {
    let response: DataStatus<StorySlideApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/story_slides/${slideId}`,
      this.storySlideCreateRequestMapper.toData(request),
    );
    return response.map((data) => {
      return this.storySlideMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<DataStatus<StorySlide>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/story_slides/${id}`,
    );
  }
}
