import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobilePreviewComponent } from '../../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { SmotreshkaCinemaAttributesFormOutput } from '../../service-extension-attributes/service-extension-attributes.component';
import { ServiceExtForm } from '../../service-extension-form/service-extension-form.component';

@Component({
  selector: 'app-mobile-smotreshka-online-cinema-preview',
  standalone: true,
  imports: [MobilePreviewComponent, PreviewBoxComponent, NgIf],
  templateUrl: './mobile-smotreshka-online-cinema-preview.component.html',
  styleUrl: './mobile-smotreshka-online-cinema-preview.component.css',
})
export class MobileSmotreshkaOnlineCinemaPreviewComponent implements OnInit {
  @Input() form?: FormGroup<ServiceExtForm>;

  bannerPreview?: string;
  iconPreview?: string;

  ngOnInit() {
    this.form?.valueChanges.subscribe(() => {
      const banner = this.cinemaAttributes?.banner.croppedData;
      const icon = this.cinemaAttributes?.icon.croppedData;

      if (banner) {
        const reader = new FileReader();
        reader.readAsDataURL(banner);
        reader.onloadend = () => {
          this.bannerPreview = reader.result as string;
        };
      }

      if (icon) {
        const reader = new FileReader();
        reader.readAsDataURL(icon);
        reader.onloadend = () => {
          this.iconPreview = reader.result as string;
        };
      }
    });
  }

  get title(): string | undefined {
    return this.form?.getRawValue().title.length == 0
      ? this.form?.getRawValue().service?.name
      : this.form?.getRawValue().title;
  }

  get description(): string | undefined {
    return this.form?.getRawValue().description.length == 0
      ? this.form?.getRawValue().service?.description
      : this.form?.getRawValue().description;
  }

  get cost(): string | undefined {
    return this.form?.getRawValue().service?.cost.toFixed(2);
  }

  private get cinemaAttributes(): SmotreshkaCinemaAttributesFormOutput | undefined {
    if (this.form?.getRawValue().attributes instanceof SmotreshkaCinemaAttributesFormOutput) {
      return this.form?.getRawValue().attributes as SmotreshkaCinemaAttributesFormOutput;
    }
    return undefined;
  }
}
