<hr />

<app-attachment-cropper-field
  class="mb-3"
  label=""
  [formControl]="attachment"
  [aspectRatio]="1.2"
  [recommendationInfo]="[
    {
      title: 'Рекомендация к обложке',
      message:
        'Рекомендуемый размер для обложки - 1080 х 900, где основное изображение находится в центральной области и помещаеся в зону - 1080 х 672',
    },
  ]"
>
</app-attachment-cropper-field>
<app-editor-js-field
  label="Подробное описание услуги"
  class="mb-3"
  [formControl]="text"
  placeholder="Описание услуги..."
></app-editor-js-field>
