import { Injectable } from '@angular/core';
import { LocalStorage } from '../storage/local-storage';

const EXPIRED_AT_KEY = 'access_token.expired_at';
const ACCESS_TOKEN_KEY = 'access_token.access_token';
const REFRESH_TOKEN_KEY = 'access_token.refresh_token';

@Injectable({
  providedIn: 'root',
})
export class OAuthAccessTokenLocal {
  constructor(private localStorage: LocalStorage) {}

  isExpired(): boolean {
    const expiredAtStr: string | null = this.expiresAt;

    if (expiredAtStr == null) {
      return true;
    }

    const currentDate = new Date();

    return currentDate.getUTCDate() > new Date(expiredAtStr).getTime();
  }

  removeToken() {
    this.localStorage.removeItem(ACCESS_TOKEN_KEY);
    this.localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.localStorage.removeItem(EXPIRED_AT_KEY);
  }

  get accessToken(): string | null {
    return this.localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  set accessToken(value: string) {
    this.localStorage.setItem(ACCESS_TOKEN_KEY, value);
  }

  get refreshToken(): string | null {
    return this.localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  set refreshToken(value: string) {
    this.localStorage.setItem(REFRESH_TOKEN_KEY, value);
  }

  get expiresAt(): string | null {
    return this.localStorage.getItem(EXPIRED_AT_KEY);
  }

  set expiresAt(value: Date) {
    this.localStorage.setItem(EXPIRED_AT_KEY, value.toLocaleString());
  }
}
