import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BannerRepository } from '../../data/repositories/BannerRepository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { DeeplinkRepository } from '../../../../../entity/deeplinks/data/repositories/DeeplinkRepository';
import { Router } from '@angular/router';
import { Deeplink } from '../../../../../entity/deeplinks/domain/entities/deeplink';
import { FileInputComponent } from '../../../../../entity/file-input/file-input.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { BannerFormComponent } from '../../components/forms/banner-form/banner-form';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { ActionButtonRepository } from '../../../stories/data/repositories/ActionButtonRepository';
import { ActionButton } from '../../../stories/domain/story';
import { IDangerModalInitParams } from '../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';
import { ModalService } from '../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-update-banner',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    FileInputComponent,
    ImagePreviewWithButtonComponent,
    ImageCropperComponent,
    BannerFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners-update.component.html',
  styleUrl: './banners-update.component.css',
})
export class BannerUpdateComponent implements OnInit {
  public banners: Loadable<Banner[]> = new Loadable<Banner[]>();
  pageTitle: string = 'Редактирование баннера';
  pageSubtitle: string = 'Баннеры';
  private deeplink: Loadable<Deeplink> = new Loadable<Deeplink>();
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  private actionButton: Loadable<ActionButton> = new Loadable<ActionButton>();
  public preview: string = '';
  private deeplink_id: number = 0;
  public currentBanner: any = undefined;
  public fakeBtnText: string = '';
  public isSubmitDisabled: boolean = true;
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  public bannerForm!: FormGroup;
  currentActionButton!: string;
  isSubmitted: boolean = false;

  constructor(
    private BannersRepo: BannerRepository,
    private formBuilder: FormBuilder,
    private DeeplinkRepo: DeeplinkRepository,
    private AttachmentRepo: AttachmentRepository,
    private ActionButtonRepo: ActionButtonRepository,
    public router: Router,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.currentBanner = history.state;

    this.bannerForm = this.formBuilder.group({
      title: new FormControl(this.currentBanner.title),
      description: new FormControl(this.currentBanner.description),
      link: new FormControl(this.currentBanner.deeplink.link),
      image: new FormControl(undefined),
      actionButton: new FormControl(
        this.currentBanner.actionButton ? this.currentBanner.actionButton.body : undefined,
      ),
    });

    this.preview = this.currentBanner.image.content.attributes.content_path;
    this.currentActionButton = this.currentBanner.actionButton
      ? this.currentBanner.actionButton.body
      : undefined;
  }

  async onUpdateSubmit(): Promise<void> {
    try {
      this.deeplink = new Loadable<Deeplink>({ kind: 'Loading' });
      this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });
      this.deeplink_id = this.currentBanner.deeplink.id;

      for (var i in this.bannerForm.controls) {
        if (this.bannerForm.controls[i].value == undefined) {
          delete this.bannerForm.value[i];
        }
      }

      if (this.bannerForm.value['image']) {
        await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
          this.attachment = Loadable.getFromDataStatus(data);
        });
      }

      if (this.currentBanner.deeplink.id) {
        const deeplinkPayload = {
          title: this.bannerForm.value['title'],
          description: this.bannerForm.value['description'],
          link: this.bannerForm.value['link'],
        };

        await this.DeeplinkRepo.update(deeplinkPayload, this.deeplink_id).then((data) => {
          this.deeplink = Loadable.getFromDataStatus(data);
        });
      }

      if (this.bannerForm.value['actionButton'] != this.currentActionButton) {
        this.actionButton = new Loadable<ActionButton>({ kind: 'Loading' });

        const actionButtonPayload = {
          body: this.bannerForm.value['actionButton'],
          deeplink_id: this.deeplink.data.id,
        };

        if (this.currentActionButton != null) {
          await this.ActionButtonRepo.delete(this.currentBanner.actionButton.id);
        }

        if (this.bannerForm.value['actionButton'] != undefined) {
          await this.ActionButtonRepo.create(actionButtonPayload).then(
            (data) => (this.actionButton = Loadable.getFromDataStatus(data)),
          );
        }
      }

      let actionButtonId: number | null = null;

      try {
        actionButtonId = this.actionButton.data.id;
      } catch {
        actionButtonId = null;
      }

      let attachmentId: number | null = null;

      try {
        attachmentId = this.attachment.data[0].id;
      } catch {
        attachmentId = null;
      }

      const bannerPayload = {
        title: this.bannerForm.value['title'],
        description: this.bannerForm.value['description'],
        image_id: attachmentId,
        deeplink_id: this.deeplink.data.id,
        action_button_id: actionButtonId,
      };

      await this.BannersRepo.update(bannerPayload, this.currentBanner.id);

      this.router.navigate(['/banners'], { state: { bannerUpdated: true } });
    } catch {
      this.isSubmitted = false;
      const dangerInitParams = new IDangerModalInitParams(
        'Пожалуйста, повторите попытку',
        () => {},
        () => {},
        'Ошибка при обновлении баннера',
      );
      this.modalService.createDangerModal(dangerInitParams);
    }
  }

  onSubmittedEvent($event: boolean) {
    this.isSubmitted = $event;
  }

  onOutputCroppedImage($event: Blob) {
    this.croppedImage = $event;
  }

  onBannerFormData($event: FormGroup) {
    this.bannerForm = $event;
    this.onUpdateSubmit().finally();
  }

  protected readonly UIStateType = UIStateType;
}
