import { Injectable } from '@angular/core';
import { WeekDaysEnum } from '../../domain/orion-office-business-hours';
import { OrionOfficeBusinessHoursCreate } from '../../domain/orion-office-business-hours-create';
import { OrionOfficeBusinessHoursCreateDto } from '../dto/orion-office-business-hours-create-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficeBusinessHoursCreateMapper {
  toData(entity: OrionOfficeBusinessHoursCreate): OrionOfficeBusinessHoursCreateDto {
    return new OrionOfficeBusinessHoursCreateDto(
      entity.officeId,
      this.mapWeekdayToEnum(entity.weekday),
      entity.openTime,
      entity.closeTime,
      entity.isDayOff,
    );
  }

  private mapWeekdayToEnum(weekday: WeekDaysEnum): string {
    for (let enumMember in WeekDaysEnum) {
      if (WeekDaysEnum[enumMember as keyof typeof WeekDaysEnum] == weekday) {
        return enumMember;
      }
    }
    return weekday.valueOf();
  }
}
