import { HttpClientWrapper } from '../../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { DataStatus, DataStatusSuccess } from '../../../../../../core/network/data.status';
import { APP_CONFIG } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/ienvironment';
import { OfficeContactMapper } from '../mappers/orion-office-contact';
import { OfficeContact } from '../../domain/orion-office-contact';
import { OfficeContactApiDto } from '../orion-office-contact-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficesContactsRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private officeContactMapper: OfficeContactMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async getAllByOffice(office_id: number): Promise<DataStatus<OfficeContact[]>> {
    var payload = {
      'office_id': office_id,
    };

    let response: DataStatus<OfficeContactApiDto[]> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts`,
      payload,
    );
    if (response instanceof DataStatusSuccess) {
      let successResponse = response as DataStatusSuccess<OfficeContactApiDto[]>;
      return successResponse.map((data) => {
        return data.map((item) => this.officeContactMapper.toDomain(item));
      });
    } else {
      throw new Error('error receive data');
    }
  }

  async update(
    updated_contact: any,
    contact_id: number | null,
  ): Promise<DataStatus<OfficeContact>> {
    let response: DataStatus<OfficeContactApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts/${contact_id}`,
      updated_contact,
    );
    let successResponse = response as DataStatusSuccess<OfficeContactApiDto>;
    return successResponse.map((data) => {
      return this.officeContactMapper.toDomain(successResponse.data);
    });
  }

  async create(new_contact: any): Promise<DataStatus<OfficeContact>> {
    let response: DataStatus<OfficeContactApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts`,
      new_contact,
    );
    let successResponse = response as DataStatusSuccess<OfficeContactApiDto>;
    return successResponse.map((data) => {
      return this.officeContactMapper.toDomain(successResponse.data);
    });
  }

  async delete(contact_id: number | null): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts/${contact_id}`,
    );
  }
}
