import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { NgIf } from '@angular/common';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';

@Component({
  selector: 'app-service-ext-general-form-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    FormComponentComponent,
  ],
  templateUrl: './service-ext-general-form-component.component.html',
  styleUrl: './service-ext-general-form-component.component.css',
})
export class ServiceExtGeneralFormComponentComponent {
  bannerChangedEvent: Event | null = null;
  croppedBanner?: Blob | null;
  bannerPreview!: string;

  @Output() outputCroppedBanner: EventEmitter<Blob> = new EventEmitter();
  @Output() outputBannerPreview: EventEmitter<string> = new EventEmitter();

  @Input() form!: FormGroup;

  bannerFileChangeEvent($event: any): void {
    this.bannerChangedEvent = $event;
  }

  bannerCropped(event: ImageCroppedEvent): void {
    this.croppedBanner = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.bannerPreview = e.target.result;
      this.outputBannerPreview.emit(this.bannerPreview);
    };

    if (this.croppedBanner) {
      reader.readAsDataURL(this.croppedBanner);
      this.outputCroppedBanner.emit(this.croppedBanner);
    }
  }

  removeBanner(): void {
    this.bannerChangedEvent = null;
  }
}
