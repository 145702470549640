import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Task } from '../../../../../core/utils/task';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { ActionButtonsRepository } from '../../../../../entity/action-button/data/repo/action-buttons-repository';
import { ActionButtonCreateRequest } from '../../../../../entity/action-button/domain/action-button-create-request';
import { AppLinkRepository } from '../../../../../entity/app-link/data/repo/app-link-repository';
import { AppLinkCreate } from '../../../../../entity/app-link/domain/app-link-create';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { AttachmentType } from '../../../../../entity/attachments/domain/attachment';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ActionButtonFormItemOutput } from '../../../../components/common/form-fields/action-button-field/action-button-field.component';
import { AttachmentOutput } from '../../../../components/common/form-fields/attachment-field/attachment-field.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { StoryRepository } from '../../data/repositories/story-repository';
import { StorySlideRepository } from '../../data/repositories/story-slide-repository';
import { StoriesCreateRequest } from '../../domain/stories-create-request';
import { Story } from '../../domain/story';
import { StorySlideCreateRequest } from '../../domain/story-slide-create-request';
import {
  StoryCreateForm,
  StoryCreateFormComponent,
  StoryCreateFormDelegate,
} from '../components/story-create-form/story-create-form.component';
import { StorySlideFormOutput } from '../components/story-slide-form/story-slide-form.component';

@Component({
  selector: 'app-stories-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    RouterModule,
    ReactiveFormsModule,
    StoryCreateFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './stories-update.component.html',
  styleUrl: './stories-update.component.css',
})
export class StoriesUpdateComponent implements OnInit, StoryCreateFormDelegate {
  pageTitle: string = 'Редактирование истории';
  pageSubtitle: string = 'Истории';

  storyUpdateState: BehaviorSubject<Loadable<Story>> = new BehaviorSubject(Loadable.notRequested());

  private storyId!: number;
  private oldStory: Loadable<Story> = Loadable.notRequested();

  constructor(
    private storyRepo: StoryRepository,
    private storySlideRepo: StorySlideRepository,
    private attachmentRepo: AttachmentRepository,
    private appLinkRepo: AppLinkRepository,
    private actionButtonsRepo: ActionButtonsRepository,
    public activeRoute: ActivatedRoute,
    public router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.storyId = Number(this.activeRoute.snapshot.paramMap.get('id'));

    this.storyUpdateState.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          new Task(async () => {
            await this.router.navigate(['stories']);
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: `История ${t.data.name} успешно отредактирована.`,
            });
          });
          break;
        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
          break;
      }
    });
  }

  private async bindValues(form: FormGroup<StoryCreateForm>) {
    let story = Loadable.getFromDataStatus(await this.storyRepo.getById(this.storyId));

    console.log(story);
    this.oldStory = story;

    if (story.safeData) {
      let data = story.safeData;

      let slides = data.slides.map((t) => {
        return new StorySlideFormOutput(
          t.header,
          t.body,
          new AttachmentOutput(null, null, t.attachment),
          t.actionButtons.map((e) => {
            return new ActionButtonFormItemOutput(e.body, {
              appLinkType: e.appLink.type,
              httpLink: e.appLink.httpLink,
              deepLink: e.appLink.deeplink,
            });
          }),
        );
      });

      form.patchValue({
        title: data.name,
        preview: { initialUrl: data.preview.content.attributes.contentPath },
        annotation: data.annotation,
      });

      let formControlSlides = slides.map((t) => {
        return new FormControl(t);
      });

      form.controls.slides = new FormArray(formControlSlides);

      console.log(form);
    } else {
      await this.router.navigate(['stories']);

      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Не удалось получить базовую сущность истории.',
      });
    }
  }

  public didCreate(form: FormGroup<StoryCreateForm>): void {
    new Task(async () => {
      await this.bindValues(form);
    });
  }

  public didSubmit(form: FormGroup<StoryCreateForm>): void {
    if (form.valid && this.storyUpdateState.value.status != UIStateType.Loading) {
      new Task(async () => {
        if (form.value.preview?.croppedData && form.value.title) {
          this.storyUpdateState.next(Loadable.loading());

          let attachment = await this.attachmentRepo.createOnlyResult(
            form.value.preview?.croppedData,
            AttachmentType.STORY_PREVIEW,
          );

          let storyRequest = new StoriesCreateRequest(
            form.value.title,
            attachment.id,
            form.value.annotation ?? '',
          );

          let story = Loadable.getFromDataStatus(
            await this.storyRepo.update(storyRequest, this.storyId),
          );

          let promiseAll: Promise<any>[] = [];

          this.oldStory.safeData?.slides.forEach((e) => {
            promiseAll.push(this.storySlideRepo.delete(e.id));
          });

          form.value.slides?.forEach((t) => {
            if (t) {
              let slideCreate = new Task<void>(async () => {
                let attachment = t.attachment?.attachment
                  ? t.attachment.attachment
                  : await this.attachmentRepo.createOnlyResult(
                      t.attachment?.file as Blob,
                      AttachmentType.STORY_SLIDE,
                    );

                let slideRequest = new StorySlideCreateRequest(
                  t.title ?? '',
                  t.description ?? '',
                  story.data.id,
                  attachment.id,
                );

                let slide = Loadable.getFromDataStatus(
                  await this.storySlideRepo.create(slideRequest),
                );

                let appLinksPromises = t.actionButtons.map((r) => {
                  return new Task(async () => {
                    let appLink = Loadable.getFromDataStatus(
                      await this.appLinkRepo.create(
                        new AppLinkCreate(
                          r.appLink.appLinkType,
                          r.appLink.deepLink,
                          r.appLink.httpLink,
                        ),
                      ),
                    );
                    await this.actionButtonsRepo.create(
                      new ActionButtonCreateRequest(r.title, appLink.data.id, slide.data.id),
                    );
                  }).toPromise();
                });

                await Promise.all(appLinksPromises);
              }).toPromise();

              promiseAll.push(slideCreate);
            }
          });

          await Promise.all(promiseAll);

          this.storyUpdateState.next(story);
        }
      });
    }
  }
}
