import EditorJS, { ToolConstructable } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import { Loadable } from '../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EditorJSInput {
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();

  constructor(private AttachmentRepo: AttachmentRepository) {
    this.doUpload = this.doUpload.bind(this);
  }

  init(holder: string, placeholder: string | undefined, data: any) {
    return new EditorJS({
      data: data,
      hideToolbar: false,
      holder: holder,
      placeholder: placeholder,
      tools: {
        header: {
          class: Header as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
        list: {
          class: List as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
        quote: {
          class: Quote as unknown as ToolConstructable,
          inlineToolbar: true,
          config: {
            quotePlaceholder: 'Введите цитату',
            captionPlaceholder: 'Автор цитаты',
          },
        },
        image: {
          class: ImageTool as unknown as ToolConstructable,
          config: {
            uploader: {
              uploadByFile: this.doUpload,
            },
          },
          inlineToolbar: ['link', 'bold'],
        },
      },
    });
  }

  async doUpload(file: File) {
    this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });
    await this.AttachmentRepo.create(file).then((data) => {
      this.attachment = Loadable.getFromDataStatus(data);
    });

    return {
      success: 1,
      file: {
        url: this.attachment.data[0].content.attributes.content_path,
      },
    };
  }
}
