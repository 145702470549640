import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { AbonentServiceType } from '../../domain/abonent-service';

import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';
import { EditorJSInput } from '../../../../components/common/inputs/editor-js-input/editor-js-input';
import edjsParser from 'editorjs-html';

@Component({
  selector: 'app-abonent-services-form-full-card',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    ImageCropperComponent,
    DragAndDropFileUploadComponent,
    FormComponentComponent,
  ],
  templateUrl: './abonent-services-form-full-card.html',
  styleUrl: './abonent-services-form-full-card.css',
})
export class AbonentServicesFormFullCardComponent implements OnInit {
  public abonentServiceType = AbonentServiceType;
  public keys = Object.keys;
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  editorjs: any;

  @Input() chosenType: string = 'web_app';
  @Input() imagePreview: string = '';

  @Output() outputCroppedImage: EventEmitter<Blob> = new EventEmitter();
  @Output() outputImagePreview: EventEmitter<string> = new EventEmitter();
  @Output() abonentServiceFormData: EventEmitter<FormGroup> = new EventEmitter();
  @Output() fullDescriptionEmitter: EventEmitter<string[]> = new EventEmitter();

  @Input() abonentServiceForm!: FormGroup;

  constructor(private editorJSInput: EditorJSInput) {}

  ngOnInit(): void {
    this.editorjs = this.editorJSInput.init(
      'editor-js',
      'Описание сервиса',
      this.abonentServiceForm.value['full_description'],
    );
    if (this.abonentServiceForm.value['full_description']) {
      this.fullDescriptionEmitter.emit(
        edjsParser().parse(this.abonentServiceForm.value['full_description']),
      );
    }
  }

  onInput() {
    this.editorjs
      .save()
      .then((outputData: any) => {
        this.fullDescriptionEmitter.emit(edjsParser().parse(outputData));
        this.abonentServiceForm.patchValue({ full_description: outputData });
      })
      .catch((error: Error) => {
        console.log('Saving failed: ', error);
      });
  }

  getAbonentServiceType(serviceType: string): string {
    return AbonentServiceType[serviceType as keyof typeof AbonentServiceType];
  }

  imageFileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
      this.outputImagePreview.emit(this.imagePreview);
    };

    if (this.croppedImage) {
      reader.readAsDataURL(this.croppedImage);
      this.outputCroppedImage.emit(this.croppedImage);
    }
  }

  checkChosenType($event: any): void {
    this.chosenType = $event.target.value;
  }

  removeImage(): void {
    this.imageChangedEvent = null;
  }

  protected readonly UIStateType = UIStateType;
}
