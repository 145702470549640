import { NgForOf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AttachmentValidator } from '../../../../../../core/validators/AttachmentValidator';
import {
  ActionButtonFieldComponent,
  ActionButtonFormItemOutput,
} from '../../../../../components/common/form-fields/action-button-field/action-button-field.component';
import {
  AttachmentFieldComponent,
  AttachmentOutput,
} from '../../../../../components/common/form-fields/attachment-field/attachment-field.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';

@Component({
  selector: 'app-story-slide-form',
  standalone: true,
  imports: [
    TextFieldComponent,
    TextareaFieldComponent,
    ReactiveFormsModule,
    ActionButtonFieldComponent,
    NgForOf,
    AttachmentFieldComponent,
  ],
  templateUrl: './story-slide-form.component.html',
  styleUrl: './story-slide-form.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StorySlideFormComponent,
    },
  ],
})
export class StorySlideFormComponent implements OnInit, ControlValueAccessor {
  form!: FormGroup<StorySlideFormItem>;

  private onChange?: (newValue: StorySlideFormOutput) => void;

  constructor(private formBuilder: NonNullableFormBuilder) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: new FormControl(null),
      description: new FormControl(null),
      attachment: new FormControl(null, [Validators.required, AttachmentValidator()]),
      actionButtons: this.formBuilder.array<FormControl<ActionButtonFormItemOutput>>([]),
    }) as FormGroup<StorySlideFormItem>;

    this.form.valueChanges.subscribe((t) => {
      if (this.onChange) {
        let slideOutput = new StorySlideFormOutput(
          t.title ?? null,
          t.description ?? null,
          t.attachment ?? null,
          t.actionButtons?.map((e) => {
            return new ActionButtonFormItemOutput(e?.title, e?.appLink);
          }) ?? [],
        );
        this.onChange(slideOutput);
      }
    });
  }

  addButton() {
    let newControl = new FormControl<ActionButtonFormItemOutput>(
      ActionButtonFormItemOutput.defaultValue,
      { nonNullable: true },
    );
    this.form.controls.actionButtons.push(newControl);
  }

  removeButtonAtIndex(index: number): void {
    this.form.controls.actionButtons.removeAt(index);
  }

  public registerOnChange(fn: (newValue: StorySlideFormOutput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public writeValue(obj: StorySlideFormOutput | null): void {
    this.form.patchValue({
      title: obj?.title,
      description: obj?.description,
      attachment: obj?.attachment,
      actionButtons: obj?.actionButtons,
    });

    this.form.controls.actionButtons.clear();

    obj?.actionButtons.forEach((t) => {
      console.log(t);
      this.form.controls.actionButtons.push(
        new FormControl<ActionButtonFormItemOutput>(t, { nonNullable: true }),
      );
    });

    // obj?.actionButtons.forEach(t => {
    //   this.form.controls.actionButtons.push()
    // })
    //
    // this.form.controls.actionButtons.patchValue(obj?.actionButtons ?? [])
  }
}

export class StorySlideFormOutput {
  static get defaultValue(): StorySlideFormOutput {
    return new StorySlideFormOutput(null, null, null, []);
  }
  constructor(
    public title: string | null,
    public description: string | null,
    public attachment: AttachmentOutput | null,
    public actionButtons: ActionButtonFormItemOutput[],
  ) {}
}

export interface StorySlideFormItem {
  title: FormControl<string | null>;
  description: FormControl<string | null>;
  attachment: FormControl<AttachmentOutput | null>;
  actionButtons: FormArray<FormControl<ActionButtonFormItemOutput>>;
}
