import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditorJSInput } from '../../../../../../components/common/inputs/editor-js-input/editor-js-input';

@Component({
  selector: 'app-add-full-desc-component',
  standalone: true,
  imports: [],
  templateUrl: './add-full-desc-component.component.html',
})
export class TestComponent implements OnInit {
  @Input() i!: number;
  @Input() addFullDescValue: any;

  @Output() titleEmitter: EventEmitter<string> = new EventEmitter();
  @Output() textEmitter: EventEmitter<string[]> = new EventEmitter();

  editor: any;
  editorId!: string;
  title!: string;

  constructor(private editorJSInput: EditorJSInput) {}

  ngOnInit(): void {
    this.editorId = `add-full-desc-editor-js-${this.i}`;
    let text = undefined;

    if (this.addFullDescValue) {
      text = this.addFullDescValue.value['text'];
      this.title = this.addFullDescValue.value['title'];
      this.titleEmitter.emit(this.title);
      this.textEmitter.emit(text);
    }
    this.editor = this.editorJSInput.init(this.editorId, 'Дополнительный пункт описания', text);
  }

  changeAddFullDescTitle($event: any) {
    const title = $event.target.value;
    this.titleEmitter.emit(title);
  }

  changeAddFullDescText() {
    this.editor
      .save()
      .then((outputData: any) => {
        this.textEmitter.emit(outputData);
      })
      .catch((error: Error) => {
        console.log('Saving failed: ', error);
      });
  }
}
