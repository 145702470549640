import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { ImagePreviewWithButtonComponent } from '../../../../../components/image-preview-with-button/image-preview-with-button.component';
import { FormArray } from '@angular/forms';
import { WeekDaysEnum } from '../../../orion-offices-business-hours/domain/orion-office-business-hours';
import { PhoneOutputPipe } from '../../../../../components/common/pipes/phone-output-pipe';

@Component({
  selector: 'app-mobile-orion-office-preview',
  standalone: true,
  imports: [
    NgFor,
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    ImagePreviewWithButtonComponent,
    PhoneOutputPipe,
  ],
  templateUrl: './mobile-orion-office-preview.component.html',
  styleUrl: './mobile-orion-office-preview.component.css',
})
export class MobileOrionOfficePreviewComponent {
  @Input() address: string = '';
  @Input() BusinessHoursData!: FormArray<any>;
  @Input() ContactsData!: FormArray<any>;

  getWeekDayEnum(index: number): string {
    return Object.values(WeekDaysEnum)[index];
  }
}
