<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить услугу'"
    data-bs-toggle="modal"
    data-bs-target="#modal-create-service"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <div class="row mb-3">
    <div class="col-12">
      <div class="input-icon">
        <input
          type="text"
          value=""
          class="form-control"
          placeholder="Поиск по доступным услугам..."
          (input)="searchAvailableService($event).finally()"
        />
        <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
      </div>
    </div>
  </div>

  <app-loadable-wrapper [loadable]="pageAvailableServices" loadingTitle="Загрузка данных">
    <ng-template>
      <app-pagination-view
        [data]="pageAvailableServices"
        [perPage]="perPage"
        [pageNumber]="pageNumber"
        [currentPageNumber]="currentPageNumber"
        (changePageEvent)="changePage($event)"
      >
        <table class="table table-vcenter card-table">
          <thead>
            <tr>
              <th class="text-center">ID услуги</th>
              <th class="text-center">Тип услуги</th>
              <th class="text-center">Название</th>
              <th class="text-center">Описание</th>
              <th class="w-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pageAvailableServices.data.data">
              <td class="text-center" style="color: #6c7a91">{{ item.billingServiceId }}</td>
              <td class="text-center">{{ getServiceType(item.serviceType) }}</td>
              <td class="text-center">{{ item.name }}</td>
              <td class="block">{{ item.description }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="btn btn-link"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ti ti-dots-vertical"></i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-demo"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      class="dropdown-item"
                      (click)="onDelete(item.id).finally()"
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </app-pagination-view>
    </ng-template>
  </app-loadable-wrapper>
</app-page-wrapper>

<div
  class="modal modal-blur fade"
  id="modal-create-service"
  tabindex="-1"
  style="display: none"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">Добавление доступной услуги в список</div>
      <div class="modal-body">
        <label class="form-label">Выберите услугу</label>
        <app-autocomplete-search-input
          #serviceDefInput
          (foundEntityEmitter)="onFoundServiceDef($event)"
          (queryString)="onQueryString($event).finally()"
          [currentFoundEntityName]="currentFoundServiceDefName"
          [entities]="services"
          [listItemTemplate]="listItem"
        ></app-autocomplete-search-input>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">
          Отменить
        </button>
        <button
          (click)="createService(); serviceDefInput.currentFoundEntityName = ''"
          type="submit"
          class="btn btn-primary"
          data-bs-dismiss="modal"
        >
          Добавить
        </button>
      </div>
    </div>
  </div>
</div>
