import { Component, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../components/common/page-wrapper/page-wrapper.component';
import { StatisticsRepository } from '../repository/statistics-repository';
import { PushStatsChartBarComponent } from './components/push-stats-chart-bar/push-stats-chart-bar.component';
import { SmsSendStatsChartBarComponent } from './components/sms-send-stats-chart-bar/sms-send-stats-chart-bar.component';
import { UsersStatsChartLineComponent } from './components/users-stats-chart-line/users-stats-chart-line.component';

@Component({
  selector: 'app-statistics-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    PageHeaderComponent,
    SmsSendStatsChartBarComponent,
    PushStatsChartBarComponent,
    UsersStatsChartLineComponent,
  ],
  templateUrl: './statistics-page.component.html',
  styleUrl: './statistics-page.component.css',
})
export class StatisticsPageComponent implements OnInit {
  pageTitle: string = 'Главная';
  pageSubtitle: string = 'Статистика по модулям приложения';

  dateFrom: Date = new Date();
  dateUntil: Date = new Date();

  constructor(private statisticsRepository: StatisticsRepository) {}

  ngOnInit() {
    // let all = Promise.all([
    //     this.statisticsRepository.getStatsByNewUsers(this.dateFrom, this.dateUntil),
    //     this.statisticsRepository.getStatsBySentPush(this.dateFrom, this.dateUntil),
    //     this.statisticsRepository.getStatusBySentSms(this.dateFrom, this.dateUntil)
    // ])
    // all.then(([t1, t2, t3]) => {
    //   //TODO: Implement
    // })
  }
}
