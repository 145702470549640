import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PhoneOutputPipe } from '../../../../../components/common/pipes/phone-output-pipe';
import { WeekdayLocalized } from '../../orion-offices/orion-offices.component';
import { OrionOfficesCreateForm } from '../orion-offices-form/orion-offices-form.component';

@Component({
  selector: 'app-mobile-orion-office-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent, NgIf, PhoneOutputPipe],
  templateUrl: './mobile-orion-office-preview.component.html',
  styleUrl: './mobile-orion-office-preview.component.css',
})
export class MobileOrionOfficePreviewComponent {
  @Input() form!: FormGroup<OrionOfficesCreateForm>;

  protected readonly WeekdayLocalized = WeekdayLocalized;
}
