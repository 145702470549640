<app-mobile-preview>
  <div class="full-desc-container">
    <div class="preview-body">
      <img class="bottomsheet-controls" src="assets/dist/img/bottomsheet_controls.png" />

      <div class="cover" style="background-image: url(&quot;{{ coverPreview }}&quot;)">
        <div *ngIf="coverPreview == undefined" class="cover-preview"></div>
      </div>

      <div class="full-desc-text">
        <div *ngIf="title == undefined" class="title-placeholder">Название услуги</div>
        <div *ngIf="title != undefined" class="title">{{ title }}</div>

        <div *ngIf="cost == undefined" class="cost-placeholder">Стоимость</div>
        <div *ngIf="cost != undefined" class="cost">
          {{ cost }}.<span class="frac-part">{{ fracPart }}</span> ₽/мес.
        </div>

        <div *ngIf="fullDesc == undefined" class="desc-placeholder">Подробное описание услуги</div>
        <div class="desc">
          <div [innerHTML]="fullDesc ? edjsParser().parse(fullDesc).join('') : null"></div>
        </div>

        <hr />

        <div *ngIf="addFullDesc.length != 0">
          <div *ngFor="let item of addFullDesc" style="margin-bottom: 12px">
            <div class="add-desc-title-container">
              <div class="add-desc-title">{{ item['title'] }}</div>
              <i class="ti ti-chevron-up"></i>
            </div>
            <div class="add-desc-text">
              <div
                [innerHTML]="item['text'] ? edjsParser().parse(item['text']).join('') : null"
              ></div>
            </div>
          </div>
        </div>

        <div *ngIf="addFullDesc.length == 0">
          <div class="add-desc-title-container">
            <div class="add-desc-title-placeholder">Дополнительный пункт описания</div>
            <i class="ti ti-chevron-up"></i>
          </div>
          <div class="add-desc-text-placeholder">Текст дополнительного описания услуги</div>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>
