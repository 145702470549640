import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormGroup, ɵFormGroupRawValue, ɵTypedOrUntyped } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { TariffExtRepository } from '../../data/repo/tariff-ext-repository';
import { TariffExt } from '../../domain/tariff-ext';
import { TariffExtCreateRequest } from '../../domain/tariff-ext-create-request';
import {
  TariffExtForm,
  TariffExtFormComponent,
  TariffExtFormDelegate,
} from '../components/tariff-ext-form/tariff-ext-form.component';

@Component({
  selector: 'app-tariff-ext-create-page',
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    PageHeaderComponent,
    PageWrapperComponent,
    TariffExtFormComponent,
  ],
  templateUrl: './tariff-ext-create-page.component.html',
  styleUrl: './tariff-ext-create-page.component.css',
})
export class TariffExtCreatePageComponent implements TariffExtFormDelegate, OnInit {
  createStatus = new BehaviorSubject<Loadable<TariffExt>>(new Loadable({ kind: 'NotRequested' }));

  pageTitle = 'Создание расширения тарифа';
  pageSubtitle = 'Тарифы и услуги';

  constructor(
    private tariffExtRepository: TariffExtRepository,
    private router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.createStatus.subscribe((newValue) => {
      switch (newValue.status) {
        case UIStateType.Success:
          this.router.navigate(['tariff-ext-list']).then(async () => {
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: 'Расширение тарифа успешно созданно.',
              acceptButtonAction: () => {},
            });
          });

          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: newValue.message!,
            acceptButtonAction: () => {},
          });
          break;
      }
    });
  }

  didSubmitForm(form: FormGroup<TariffExtForm>) {
    if (form.valid) {
      this.createExtension(form.getRawValue()).finally();
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля.',
      });
    }
  }

  private async createExtension(
    form: ɵTypedOrUntyped<TariffExtForm, ɵFormGroupRawValue<TariffExtForm>, any>,
  ) {
    let request = new TariffExtCreateRequest(
      form.name,
      form.tariff!.tariffInfo.id,
      form.description,
    );

    let response = await this.tariffExtRepository.create(request);

    this.createStatus.next(Loadable.getFromDataStatus(response));
  }
}
