import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { IModalComponentComponent } from '../imodal-component/imodal-component.component';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-success-modal-component',
  standalone: true,
  imports: [NgIf],
  templateUrl: './success-modal-component.component.html',
  styleUrl: './success-modal-component.component.css',
})
export class SuccessModalComponentComponent extends IModalComponentComponent {}
