import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [NgIf],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.css',
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle?: string;

  constructor(private titleService: Title) {}
  ngOnInit() {
    this.titleService.setTitle(`${this.title} | ${this.subtitle}`);
  }
}
