import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  from,
  Observable,
  retry,
  switchMap,
  take,
  throwError,
  timer,
} from 'rxjs';
import { APP_CONFIG } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/ienvironment';
import { OAuthClient } from '../../../features/auth/infrastructure/network/oauth-client';
import { OAuthAccessTokenLocal } from '../../localData/oauth-access-token-local';

@Injectable()
export class OAuthApplyTokenInterceptor implements HttpInterceptor {
  private config: IEnvironment;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    @Inject(APP_CONFIG) config: IEnvironment,
    private accessToken: OAuthAccessTokenLocal,
    private oauthClient: OAuthClient,
  ) {
    this.config = config;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.accessToken.accessToken;

    if (token) {
      req = this.cloneWithToken(req, token);
    }

    return next.handle(req).pipe(
      retry({
        count: 3,
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if (error.status === 500) {
            return timer(1000);
          }
          throw error;
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return from(this.handleNotAuthError(req, next));
        }
        return throwError(() => error);
      }),
    );
  }

  handleNotAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.oauthClient.refreshToken()).pipe(
        switchMap((newToken: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.cloneWithToken(req, newToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.oauthClient.redirectToAuthPage();
          return throwError(() => err);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((newToken) => {
          return next.handle(this.cloneWithToken(req, newToken));
        }),
      );
    }
  }

  private cloneWithToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
