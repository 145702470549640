import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BannerRepository } from '../../data/repositories/BannerRepository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { DeeplinkRepository } from '../../../../../entity/deeplinks/data/repositories/DeeplinkRepository';
import { Router } from '@angular/router';
import { Deeplink } from '../../../../../entity/deeplinks/domain/entities/deeplink';
import { FileInputComponent } from '../../../../../entity/file-input/file-input.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { BannerFormComponent } from '../../components/forms/banner-form/banner-form';
import { ActionButtonRepository } from '../../../stories/data/repositories/ActionButtonRepository';
import { ActionButton } from '../../../stories/domain/story';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { IDangerModalInitParams } from '../../../../components/common/modal-service/modal-component/danger-modal-component/idanger-modal-init-params';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-banner',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    FileInputComponent,
    ImagePreviewWithButtonComponent,
    ImageCropperComponent,
    BannerFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners-create.component.html',
  styleUrl: './banners-create.component.css',
})
export class BannerCreateComponent implements OnInit {
  public banners: Loadable<Banner[]> = new Loadable<Banner[]>();
  pageTitle: string = 'Добавление баннера';
  pageSubtitle: string = 'Баннеры';
  private deeplink: Loadable<Deeplink> = new Loadable<Deeplink>();
  private actionButton: Loadable<ActionButton> = new Loadable<ActionButton>();
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  croppedImage?: Blob | null;
  public bannerForm!: FormGroup;
  public isSubmitted: boolean = false;

  constructor(
    private BannersRepo: BannerRepository,
    private formBuilder: FormBuilder,
    private DeeplinkRepo: DeeplinkRepository,
    private AttachmentRepo: AttachmentRepository,
    private ActionButtonRepo: ActionButtonRepository,
    public router: Router,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.bannerForm = this.formBuilder.group({
      title: new FormControl(undefined, [Validators.required]),
      description: new FormControl(undefined, [Validators.required]),
      link: new FormControl(undefined, [Validators.required]),
      image: new FormControl(undefined, [Validators.required]),
      actionButton: new FormControl(undefined),
    });
  }

  async onCreateSubmit(): Promise<void> {
    try {
      this.deeplink = new Loadable<Deeplink>({ kind: 'Loading' });
      this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });

      if (this.bannerForm.value['image']) {
        await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
          this.attachment = Loadable.getFromDataStatus(data);
        });
      }

      const deeplinkPayload = {
        title: this.bannerForm.value['title'],
        description: this.bannerForm.value['description']
          ? this.bannerForm.value['description']
          : '',
        link: this.bannerForm.value['link'],
      };

      await this.DeeplinkRepo.create(deeplinkPayload).then((data) => {
        this.deeplink = Loadable.getFromDataStatus(data);
      });

      if (this.bannerForm.value['actionButton']) {
        this.actionButton = new Loadable<ActionButton>({ kind: 'Loading' });

        const actionButtonPayload = {
          body: this.bannerForm.value['actionButton'],
          deeplink_id: this.deeplink.data.id,
        };

        await this.ActionButtonRepo.create(actionButtonPayload).then((data) => {
          this.actionButton = Loadable.getFromDataStatus(data);
        });
      }

      let actionButtonId: number | null = null;

      try {
        actionButtonId = this.actionButton.data.id;
      } catch {
        actionButtonId = null;
      }

      const bannerPayload = {
        title: this.bannerForm.value['title'],
        description: this.bannerForm.value['description'],
        image_id: this.attachment.data[0].id,
        deeplink_id: this.deeplink.data.id,
        action_button_id: actionButtonId,
      };

      await this.BannersRepo.create(bannerPayload);

      this.router.navigate(['/banners'], { state: { bannerCreated: true } });
    } catch (error: any) {
      this.isSubmitted = false;
      const dangerInitParams = new IDangerModalInitParams(
        'Пожалуйста, повторите попытку',
        () => {},
        () => {},
        'Ошибка при создании баннера',
      );
      this.modalService.createDangerModal(dangerInitParams);
    }
  }

  onSubmittedEvent($event: boolean) {
    this.isSubmitted = $event;
  }

  onOutputCroppedImage($event: Blob) {
    this.croppedImage = $event;
  }

  onBannerFormData($event: FormGroup) {
    this.bannerForm = $event;
    this.onCreateSubmit().finally();
  }

  protected readonly UIStateType = UIStateType;
}
