<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Создать баннер'" [routerLink]="['create-banner']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="row mt-3">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Активные баннеры</h3>
        </div>
        <div class="card-body">
          <app-loadable-wrapper [loadable]="activeBanners" loadingTitle="Загрузка данных...">
            <app-pagination-view
              [data]="activeBanners"
              [perPage]="perPage"
              [pageNumber]="activeBannersCurrentPageNumber"
              [delegate]="this"
            >
              <div class="list-group list-group-flush">
                <div *ngFor="let item of activeBanners.safeData?.data" class="list-group-item">
                  <div class="row">
                    <div class="col-auto">
                      <span class="banner-image" [style]="item.getBackgroundImage()"></span>
                    </div>
                    <div class="col text-truncate">
                      <div class="text-body d-block title">{{ item.title }}</div>
                      <div class="link">
                        {{ item.appLink?.getLink() }}
                      </div>
                      <div class="button-text">
                        {{ item.actionButton?.body }}
                      </div>
                      <div class="description mt-2">{{ item.description }}</div>
                    </div>
                    <div class="col-auto">
                      <div class="btn-list flex-nowrap">
                        <div class="dropdown">
                          <button
                            class="btn btn-link"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="ti ti-dots-vertical"></i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-demo"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <button type="button" class="dropdown-item">Сделать неактивным</button>
                            <a
                              class="dropdown-item"
                              routerLink="update-banner/{{ item.id }}"
                              [state]="item"
                              >Редактировать</a
                            >

                            <button
                              type="button"
                              class="dropdown-item"
                              (click)="onDelete(item.id).finally()"
                            >
                              Удалить
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-pagination-view>
          </app-loadable-wrapper>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Неактивные баннеры</h3>
        </div>

        <app-loadable-wrapper [loadable]="hiddenBanners" loadingTitle="Загрузка данных...">
          <div class="card-body">
            <app-pagination-view
              [data]="hiddenBanners"
              [perPage]="perPage"
              [pageNumber]="hiddenBannersCurrentPageNumber"
            >
              <div class="list-group">
                <div
                  *ngFor="let item of hiddenBanners.safeData?.data"
                  class="list-group-item"
                  style="margin-bottom: 12px; padding: 16px 20px 16px 20px"
                >
                  <div class="row">
                    <div class="col-auto">
                      <span class="banner-image" [style]="item.getBackgroundImage()"></span>
                    </div>
                    <div class="col text-truncate">
                      <div class="text-body d-block title">{{ item.title }}</div>
                      <div class="link">
                        {{ item.appLink?.getLink() }}
                      </div>
                      <div class="button-text">
                        {{ item.actionButton?.body }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="btn-list flex-nowrap">
                        <div class="dropdown">
                          <button
                            class="btn btn-link"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="ti ti-dots-vertical"></i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-demo"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <button type="button" class="dropdown-item">Сделать активным</button>
                            <a
                              class="dropdown-item"
                              routerLink="update-banner/{{ item.id }}"
                              [state]="item"
                              >Редактировать</a
                            >
                            <button
                              type="button"
                              class="dropdown-item"
                              (click)="onDelete(item.id).finally()"
                            >
                              Удалить
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 8px">
                    <div class="description">{{ item.description }}</div>
                  </div>
                </div>
              </div>
            </app-pagination-view>
          </div>
        </app-loadable-wrapper>
      </div>
    </div>
  </div>
</app-page-wrapper>
