import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { OutputData } from '@editorjs/editorjs';
import { UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AttachmentValidator } from '../../../../../core/validators/AttachmentValidator';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { EditorJsFieldComponent } from '../../../../components/common/form-fields/editor-js-field/editor-js-field.component';
import {
  SelectFieldComponent,
  SelectFieldValue,
} from '../../../../components/common/form-fields/select-field/select-field.component';
import { TextFieldComponent } from '../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';
import { AbonentServiceType } from '../../domain/abonent-service';
import { MobileAbonentServicesDetailPreviewComponent } from '../mobile-abonent-services-detail-preview/mobile-abonent-services-detail-preview.component';

@Component({
  selector: 'app-abonent-service-form',
  standalone: true,
  imports: [
    AttachmentCropperFieldComponent,
    FormComponentComponent,
    TextareaFieldComponent,
    TextFieldComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    EditorJsFieldComponent,
    MobileAbonentServicesDetailPreviewComponent,
    NgIf,
  ],
  templateUrl: './abonent-service-form.component.html',
  styleUrl: './abonent-service-form.component.css',
})
export class AbonentServiceFormComponent implements OnInit {
  @Input() sendFormState: UIStateType = UIStateType.NotRequested;
  @Input() delegate?: AbonentServiceFormComponentDelegate;

  protected abonentServiceForm!: FormGroup<AbonentServiceForm>;

  protected availableServiceTypeSelectables: SelectFieldValue<AbonentServiceType>[] = [
    { value: AbonentServiceType.mobile_app, selectTitle: 'Мобильное приложение' },
    { value: AbonentServiceType.web_app, selectTitle: 'Веб-приложение' },
  ];
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
  ) {}

  didClickSubmit() {
    if (this.delegate?.didSubmitForm) {
      this.delegate.didSubmitForm(this.abonentServiceForm);
    }
  }

  ngOnInit() {
    this.abonentServiceForm = this.formBuilder.group<AbonentServiceForm>({
      type: new FormControl(AbonentServiceType.mobile_app, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      title: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      full_description: new FormControl<OutputData | null>(null, [Validators.required]),
      short_description: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      service_link_web: new FormControl<string | null>(null),
      service_link_android: new FormControl<string | null>(null),
      service_link_ios: new FormControl<string | null>(null),
      icon: new FormControl({}, [Validators.required, AttachmentValidator()]),
      image: new FormControl({}, [Validators.required, AttachmentValidator()]),
    });

    if (this.delegate?.didCreateForm) {
      this.delegate.didCreateForm(this.abonentServiceForm);
    }
  }

  protected readonly AbonentServiceType = AbonentServiceType;
  protected readonly UIStateType = UIStateType;
}

export interface AbonentServiceFormComponentDelegate {
  didCreateForm?: (form: FormGroup<AbonentServiceForm>) => void;
  didSubmitForm?: (form: FormGroup<AbonentServiceForm>) => void;
}
export interface AbonentServiceForm {
  type: FormControl<AbonentServiceType>;
  title: FormControl<string>;
  short_description: FormControl<string>;
  full_description: FormControl<OutputData | null>;
  service_link_web?: FormControl<string | null>;
  service_link_android?: FormControl<string | null>;
  service_link_ios?: FormControl<string | null>;
  icon: FormControl<AttachmentCropperData | null>;
  image: FormControl<AttachmentCropperData | null>;
}
