import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { NgForOf, NgIf } from '@angular/common';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../../entity/attachments/domain/attachment';
import { EditorJSInput } from '../../../../../components/common/inputs/editor-js-input/editor-js-input';
import { TestComponent } from './add-full-desc-form-component/add-full-desc-component.component';

@Component({
  selector: 'app-service-ext-full-desc-form-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    NgForOf,
    FormComponentComponent,
    TestComponent,
  ],
  templateUrl: './service-ext-full-desc-form-component.component.html',
  styleUrl: './service-ext-full-desc-form-component.component.css',
})
export class ServiceExtFullDescFormComponentComponent implements OnInit {
  coverChangedEvent: Event | null = null;
  croppedCover?: Blob | null;
  addFullDesc: FormArray<any> = new FormArray<any>([]);
  coverPreview!: string;
  addFullDescTitle!: string;
  addFullDescText!: string;
  fullDescEditorJS: any;
  addFullDescEditorJS: any[] = [];

  @Output() outputCroppedCover: EventEmitter<Blob> = new EventEmitter();
  @Output() outputCoverPreview: EventEmitter<string> = new EventEmitter();
  @Output() outputFullDesc: EventEmitter<string> = new EventEmitter();
  @Output() outputAddFullDesc: EventEmitter<{ [id: string]: any }> = new EventEmitter();

  @Input() form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private editorJSInput: EditorJSInput,
  ) {}

  ngOnInit(): void {
    this.fullDescEditorJS = this.editorJSInput.init(
      'full-desc-editor-js',
      'Подробное описание услуги',
      this.form.value['fullDesc'],
    );
    if (this.form.value['fullDesc']) {
      this.outputFullDesc.emit(this.form.value['fullDesc']);
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      title: new FormControl(undefined),
      text: new FormControl(undefined),
    });
  }

  addItem(): void {
    this.addFullDesc = this.addFullDescData;
    this.addFullDesc.push(this.createItem());
  }

  get addFullDescData() {
    return <FormArray>this.form.get('addFullDesc');
  }

  coverFileChangeEvent($event: any): void {
    this.coverChangedEvent = $event;
  }

  coverCropped(event: ImageCroppedEvent): void {
    this.croppedCover = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.coverPreview = e.target.result;
      this.outputCoverPreview.emit(this.coverPreview);
    };

    if (this.croppedCover) {
      reader.readAsDataURL(this.croppedCover);
      this.outputCroppedCover.emit(this.croppedCover);
    }
  }

  removeCover(): void {
    this.coverChangedEvent = null;
  }

  changeFullDesc() {
    this.fullDescEditorJS
      .save()
      .then((outputData: any) => {
        this.form.patchValue({ fullDesc: outputData });
        this.outputFullDesc.emit(this.form.value['fullDesc']);
      })
      .catch((error: Error) => {
        console.log('Saving failed: ', error);
      });
  }

  changeAddFullDesc() {
    this.outputAddFullDesc.emit(this.addFullDescData.value);
  }

  onTextEmitter($event: any, i: number) {
    this.addFullDescData.controls[i].patchValue({ text: $event });
    this.changeAddFullDesc();
  }

  onTitleEmitter($event: string, i: number) {
    this.addFullDescData.controls[i].patchValue({ title: $event });
    this.changeAddFullDesc();
  }
}
