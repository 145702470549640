import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Loadable } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { DeeplinkRepository } from '../../../../dashboard/deeplinks/data/repo/deeplink-repository';
import { Deeplink } from '../../../../dashboard/deeplinks/domain/deeplink';
import { LoadableWrapperComponent } from '../../loadable-wrapper/loadable-wrapper.component';

@Component({
  selector: 'app-deeplink-field',
  standalone: true,
  imports: [LoadableWrapperComponent, NgForOf, NgIf, NgClass],
  templateUrl: './deeplink-field.component.html',
  styleUrl: './deeplink-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeeplinkFieldComponent,
    },
  ],
})
export class DeeplinkFieldComponent implements OnInit, ControlValueAccessor {
  private static id: number = 0;
  protected componentId = `deeplink-field-${DeeplinkFieldComponent.id++}`;

  private _value: Deeplink | null = null;
  private onChange?: (deeplink: Deeplink | null) => void;

  get value(): Deeplink | null {
    return this._value;
  }

  set value(newValue) {
    this._value = newValue;

    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  deeplinkSearchState: Loadable<PaginationResponse<Deeplink>> = Loadable.notRequested();
  deeplinkLoadingState: Loadable<PaginationResponse<Deeplink>> = Loadable.notRequested();
  deeplinkModalSelected: Deeplink | null = null;

  searchQuery: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private perPage = 20;

  constructor(private deeplinkRepository: DeeplinkRepository) {}

  writeValue(obj: Deeplink | null): void {
    this.value = obj;
  }

  registerOnChange(fn: (deeplink: Deeplink | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  ngOnInit() {
    this.getDeeplink().finally();

    this.searchQuery.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value.length >= 0) {
        this.searchDeeplinksByName(value).finally();
      } else {
        this.deeplinkSearchState = Loadable.notRequested();
      }
    });
  }

  setSelectedItem() {
    this.value = this.deeplinkModalSelected;
  }

  setQuery(event: any) {
    this.searchQuery.next(event.target.value);
  }

  didClickRemoveSelectedDeeplink() {
    this.value = null;
    this.deeplinkModalSelected = null;
  }

  selectModalDeeplink(deeplink: Deeplink) {
    this.deeplinkModalSelected = this.deeplinkModalSelected?.id == deeplink.id ? null : deeplink;
  }

  async getDeeplink() {
    this.deeplinkLoadingState = Loadable.loading();
    let response = await this.deeplinkRepository.getAll(this.perPage, 1);
    this.deeplinkLoadingState = Loadable.getFromDataStatus(response);
  }

  private async searchDeeplinksByName(name: string) {
    this.deeplinkSearchState = Loadable.loading();
    let response = await this.deeplinkRepository.findByTitle(this.perPage, name);
    this.deeplinkSearchState = Loadable.getFromDataStatus(response);
  }
}
