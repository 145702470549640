import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { PaginationResponseItemMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-item-mapper';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { AvailableTariff } from '../../domain/available-tariff';
import { AvailableTariffCreate } from '../../domain/available-tariff-create';
import { AvailableTariffApiDto } from '../dto/available-tariff-api-dto';
import { AvailableTariffCreateMapper } from '../mappers/available-tariff-create-mapper';
import { AvailableTariffMapper } from '../mappers/available-tariff-mapper';

@Injectable({ providedIn: 'root' })
export class AvailableTariffRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpTariff: HttpClientWrapper,
    private availableTariffMapper: AvailableTariffMapper,
    private availableTariffCreateMapper: AvailableTariffCreateMapper,
    private paginationResponseItemMapper: PaginationResponseItemMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<
    AvailableTariffApiDto,
    AvailableTariff
  > = new PaginationResponseMapper(this.availableTariffMapper);

  async findAllByName(per_page: number, page_number: number, name: string) {
    let payload = {
      pagination: {
        per_page: per_page,
        page_number: page_number,
      },
      filters: [
        { 'column': 'deleted_at', 'operator': 'eq', 'value': null },
        { 'column': 'name', 'operator': 'ilike', 'value': `%${name}%` },
      ],
    };

    let response: DataStatus<PaginationResponseApiDto<AvailableTariffApiDto>> =
      await this.httpTariff.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_tariffs`,
        payload,
      );

    return response.map((data) => {
      return this.paginationResponseItemMapper.toDomain(data, (item) => {
        return this.availableTariffMapper.toDomain(item);
      });
    });
  }

  async findAllPost(
    per_page: number,
    page_number: number,
    search_query: string | null = null,
  ): Promise<DataStatus<PaginationResponse<AvailableTariff>>> {
    let filters: { column: string; operator: string; value: string | null }[] = [
      { column: 'deleted_at', operator: 'eq', value: null },
    ];
    if (search_query != null && search_query.length > 0) {
      filters.push({ column: 'name', operator: 'ilike', value: `%${search_query}%` });
    }

    let payload = {
      pagination: {
        per_page: per_page,
        page_number: page_number,
      },
      filters: filters,
    };

    let response: DataStatus<PaginationResponseApiDto<AvailableTariffApiDto>> =
      await this.httpTariff.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_tariffs`,
        payload,
      );

    return response.map((data) => {
      return this.paginationResponseItemMapper.toDomain(data, (item) => {
        return this.availableTariffMapper.toDomain(item);
      });
    });
  }

  async create(new_tariff: AvailableTariffCreate): Promise<DataStatus<AvailableTariff>> {
    let response: DataStatus<AvailableTariffApiDto> = await this.httpTariff.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_tariffs`,
      this.availableTariffCreateMapper.toData(new_tariff),
    );

    return response.map((data) => {
      return this.availableTariffMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpTariff.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_tariffs/${id}`,
    );
  }
}
