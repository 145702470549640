import { NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dimensions, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-attachment-cropper-field',
  standalone: true,
  imports: [ImageCropperComponent, NgIf],
  templateUrl: './attachment-cropper-field.component.html',
  styleUrl: './attachment-cropper-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AttachmentCropperFieldComponent,
    },
  ],
})
export class AttachmentCropperFieldComponent implements ControlValueAccessor, OnInit {
  private static id: number = 0;

  componentId = `attachment-cropper=field-${AttachmentCropperFieldComponent.id++}`;

  private _attachmentCropperData: AttachmentCropperData = {};

  get attachmentCropperData() {
    return this._attachmentCropperData;
  }

  set attachmentCropperData(newValue: AttachmentCropperData) {
    this._attachmentCropperData = newValue;

    if (newValue.initialUrl) {
      this.loadImage(newValue.initialUrl).finally();
    }

    if (this.onChange) {
      this.onChange(this._attachmentCropperData);
    }
  }

  @Input() aspectRatio: number = 1;
  @Input() label: string = '';
  @Input() recommendationInfo: RecommendationInfo[] = [];

  @ViewChild('cropperRef') cropper!: ImageCropperComponent;

  protected containerSize: Dimensions = { width: 0, height: 0 };

  private onChange?: (value: AttachmentCropperData) => void;
  private onTouched!: () => void;

  async loadImage(imageUrl: string) {
    console.log(`initUrl ${imageUrl}`);

    const res = await fetch(imageUrl);
    const blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false,
      );
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });

    this.attachmentCropperData.base64Origin = String(result);
  }

  ngOnInit() {
    console.log(`initUrl ${this.attachmentCropperData.initialUrl}`);
  }

  imageCropped(event: ImageCroppedEvent): void {
    if (this.attachmentCropperData) {
      this.attachmentCropperData = {
        initialUrl: this.attachmentCropperData.initialUrl,
        croppedData: event.blob,
        base64Origin: this.attachmentCropperData.base64Origin,
        origin: this.attachmentCropperData.origin,
      };
    } else {
      this.attachmentCropperData = { croppedData: event.blob };
    }
    console.log('cropped');
  }

  fileBrowseHandler(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      this.attachmentCropperData.origin = file;
    }
  }

  registerOnChange(fn: (value: AttachmentCropperData) => void): void {
    this.onChange = fn;
  }

  startCropImage() {
    this.containerSize = this.cropper.maxSize;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clearOriginImage(): void {
    delete this.attachmentCropperData.initialUrl;
    delete this.attachmentCropperData.origin;
    delete this.attachmentCropperData.base64Origin;
  }

  writeValue(value: AttachmentCropperData): void {
    this.attachmentCropperData = value;
  }

  convert(str: string): string {
    return str.replace(/\n/g, '<br>');
  }
}

export type AttachmentCropperData = {
  initialUrl?: string;
  origin?: File;
  base64Origin?: string | undefined;
  croppedData?: Blob | null;
};

export type RecommendationInfo = {
  title: string;
  message: string;
};
