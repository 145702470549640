import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TextFieldComponent } from '../../../../../../components/common/form-fields/text-field/text-field.component';
import {
  CATVPocketFormOutput,
  ServiceExtensionAttributesDelegate,
} from '../service-extension-attributes.component';

@Component({
  selector: 'app-ctv-pocket-ext-form',
  standalone: true,
  imports: [TextFieldComponent, ReactiveFormsModule],
  templateUrl: './catv-pocket-ext-form.component.html',
  styleUrl: './catv-pocket-ext-form.component.css',
})
export class CatvPocketExtFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionAttributesDelegate;

  attributesForm!: FormGroup<CatvPocketAttributesForm>;

  constructor(public formBuilder: NonNullableFormBuilder) {}

  ngOnInit(): void {
    this.attributesForm = this.formBuilder.group({
      numberOfChannels: new FormControl(null, [Validators.required, Validators.min(1)]),
    }) as FormGroup<CatvPocketAttributesForm>;

    this.attributesForm.valueChanges.subscribe((newValue) => {
      if (this.attributesForm.valid) {
        const newOutput = new CATVPocketFormOutput(newValue.numberOfChannels!);
        this.delegate?.onChangeValue(newOutput);
      }
    });
  }
}

export interface CatvPocketAttributesForm {
  numberOfChannels: FormControl<number | null>;
}
