<app-mobile-preview>
  <div class="service-image" style="background-image: url('assets/dist/img/map-preview.png')"></div>
  <div class="service-desc">
    <div class="title">Офис ГК «Орион»</div>
    <div class="row address align-items-center">
      <div class="col-lg-10">
        <div class="address-header">
          <i class="ti ti-map-pin" style="margin-right: 4px"></i>Адрес:
        </div>
        <div *ngIf="address == undefined; else addressTemplate" class="address-placeholder">
          Введите адрес
        </div>
        <ng-template #addressTemplate>
          <div class="address-output">
            {{ address }}
          </div>
        </ng-template>
      </div>
      <div class="col-lg-2">
        <div class="d-flex justify-content-end">
          <i class="ti ti-copy"></i>
        </div>
      </div>
    </div>
    <div class="business-hours">
      <div class="business-hours-header">
        <i class="ti ti-clock" style="margin-right: 4px"></i>Часы работы<i
          class="ti ti-chevron-up"
        ></i>
      </div>
      <div class="business-hours-container">
        <div *ngFor="let item of BusinessHoursData.controls; let i = index">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="weekday">
                {{ getWeekDayEnum(i) }}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="d-flex justify-content-end">
                <div
                  *ngIf="
                    BusinessHoursData.controls[i].value['open_time'] == undefined &&
                      BusinessHoursData.controls[i].value['close_time'] == undefined &&
                      BusinessHoursData.controls[i].value['is_day_off'] == false;
                    else hours
                  "
                  class="hours-placeholder"
                >
                  Введите время работы
                </div>
                <ng-template #hours>
                  <div
                    *ngIf="BusinessHoursData.controls[i].value['is_day_off'] == false; else dayOff"
                    class="hours"
                  >
                    {{
                      BusinessHoursData.controls[i].value['open_time'].length > 5
                        ? BusinessHoursData.controls[i].value['open_time'].substring(
                            0,
                            BusinessHoursData.controls[i].value['open_time'].length - 3
                          )
                        : BusinessHoursData.controls[i].value['open_time']
                    }}
                    —
                    {{
                      BusinessHoursData.controls[i].value['close_time'].length > 5
                        ? BusinessHoursData.controls[i].value['close_time'].substring(
                            0,
                            BusinessHoursData.controls[i].value['close_time'].length - 3
                          )
                        : BusinessHoursData.controls[i].value['close_time']
                    }}
                  </div>
                  <ng-template #dayOff>
                    <div class="dayOff">Выходной</div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts">
      <div class="contacts-header">
        <i class="ti ti-phone" style="margin-right: 4px"></i>Контакты
      </div>
      <div *ngIf="ContactsData != undefined" class="contact-container">
        <div *ngFor="let contact of ContactsData.controls">
          <div class="row align-items-center d-flex">
            <div class="col-lg-8">
              {{ contact.value['phone'] | phoneOutput }}
            </div>
            <div class="col-lg-4">
              <div
                *ngIf="contact.value['phone'] != undefined"
                class="contact-icons d-flex justify-content-end"
              >
                <i class="ti ti-copy"></i><i class="ti ti-phone"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>
