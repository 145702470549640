import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OutputData } from '@editorjs/editorjs';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { EditorJsFieldComponent } from '../../../../../../components/common/form-fields/editor-js-field/editor-js-field.component';
import {
  GeneralAttributesFormOutput,
  ServiceExtensionAttributesDelegate,
} from '../service-extension-attributes.component';

@Component({
  selector: 'app-general-service-ext-form',
  standalone: true,
  imports: [EditorJsFieldComponent, ReactiveFormsModule, AttachmentCropperFieldComponent],
  templateUrl: './general-service-ext-form.component.html',
  styleUrl: './general-service-ext-form.component.css',
})
export class GeneralServiceExtFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionAttributesDelegate;

  text: FormControl<OutputData | null> = new FormControl<OutputData | null>(null);
  attachment: FormControl<AttachmentCropperData | null> =
    new FormControl<AttachmentCropperData | null>(null);
  cover: FormControl<AttachmentCropperData | null> = new FormControl<AttachmentCropperData | null>(
    null,
  );

  ngOnInit() {
    this.attachment.valueChanges.subscribe((newValue) => {
      const newAttributes = new GeneralAttributesFormOutput(newValue ?? {}, this.text.value);
      this.delegate?.onChangeValue(newAttributes);
    });

    this.text.valueChanges.subscribe((newValue) => {
      const newAttributes = new GeneralAttributesFormOutput(this.attachment.value ?? {}, newValue);
      this.delegate?.onChangeValue(newAttributes);
    });
  }
}
