<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <form [formGroup]="createOfficeForm" (ngSubmit)="onUpdateSubmit().finally()">
    <div class="row">
      <div class="col-lg-6">
        <app-form-component [title]="'Редактирование офиса'">
          <div class="mb-3">
            <label class="form-label required">Выберите город</label>
            <select
              class="form-select"
              formControlName="city"
              (change)="getOfficeCityCoordinates($event)"
            >
              <option *ngFor="let enum of keys(officeCityType)" [value]="[enum]">
                {{ getOfficeCityType(enum) }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label required">Название офиса</label>
            <input
              type="text"
              class="form-control"
              formControlName="title"
              placeholder="Введите название офиса, оно будет отображаться в списке в дашборде"
            />
          </div>
          <div class="mb-3">
            <label class="form-label required">Укажите адрес офиса</label>
            <input
              type="text"
              class="form-control"
              formControlName="address"
              placeholder="Введите название улицы, номер строения и номер офиса"
            />
          </div>
          <div class="mb-3">
            <label class="form-label required">Укажите точку на карте</label>
            <div class="mb-3 map-container align-items-center justify-content-center px-0">
              <ya-map
                (yaclick)="onMapClick($event)"
                [center]="placemarkCoords"
                [zoom]="17"
                [state]="mapState"
              >
                <ya-control type="SearchControl" [parameters]="parameters"></ya-control>
                <ya-placemark [geometry]="placemarkCoords"></ya-placemark>
              </ya-map>
            </div>
          </div>

          <hr class="divider" />

          <div class="mb-3">
            <label class="form-label required">Заполните график работы офиса</label>
            <div
              formArrayName="businessHours"
              *ngFor="let item of BusinessHoursData.controls; let i = index"
            >
              <div [formGroupName]="i">
                <div class="row align-items-center time-row">
                  <div class="col-lg-3">
                    {{ getWeekDayEnum(i) }}
                  </div>
                  <div class="col-lg-3">
                    <input
                      formControlName="open_time"
                      type="time"
                      class="form-control"
                      placeholder="Начало работы"
                    />
                  </div>
                  <div class="col-lg-3">
                    <input
                      formControlName="close_time"
                      type="time"
                      class="form-control"
                      placeholder="Окончание работы"
                    />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        formControlName="is_day_off"
                        (change)="changeDayOff(i)"
                      />
                      <span class="form-check-label">Выходной</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label required">Номера телефонов</label>
            <div
              formArrayName="contacts"
              *ngFor="let item of OfficeContactsData.controls; let i = index"
            >
              <div [formGroupName]="i">
                <div class="row phone-row">
                  <div class="col-md-10">
                    <div class="input-group input-group-flat">
                      <span class="input-group-text">+7</span>
                      <input
                        class="form-control"
                        formControlName="phone"
                        placeholder="Введите контактный номер телефона"
                        mask="(000)-000-00-00"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button type="button" class="btn btn-link preview-btn" (click)="removeItem(i)">
                      <i class="ti ti-x"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-link" (click)="addItem()">
              <i class="ti ti-plus"></i> Добавить еще один номер
            </button>
          </div>
        </app-form-component>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Предпросмотр офиса в приложении</div>
          </div>
          <div class="card-body">
            <app-mobile-orion-office-preview
              [address]="this.createOfficeForm.value['address']"
              [BusinessHoursData]="BusinessHoursData"
              [ContactsData]="OfficeContactsData"
            >
            </app-mobile-orion-office-preview>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-around">
              <a (click)="router.navigate(['/orion-offices'])" class="btn btn-link"> Отмена </a>
              <button type="submit" class="btn btn-primary" [disabled]="isSubmitted == true">
                <span
                  *ngIf="isSubmitted == true"
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-page-wrapper>
